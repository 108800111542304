import React from 'react'
import PriceAndPlanSection from './sub_components/PriceAndPlanSection'
import SectionTwo from './sub_components/organisation/SectionTwo'
import SectionThree from './sub_components/WorkplaceCalculateSection'
import Testimonial from './sub_components/Testimonial'
import CompanyLogoSection from './sub_components/CompanyLogoSection'
import GetAccessForm from './sub_components/organisation/GetAccessForm'
import SectionOne from './sub_components/organisation/SectionOne'
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'

const OrganisationComponent = () => {
    //this is contain overall componenets of Organisation page
    return (
        <>
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <Testimonial
                testimonialSubheading="This is why organisations all over Asia are trusting MindPeers with their employees’ well-being"
                testimonialCardText={[`Got some tools which will be useful especially now that we are wfh. As HR, it can become 
                overwhelming. The tools will help us structure our approach.`, ` Love MindPeers for what they are giving 
                back to the society. Truly grateful for the entire team.`, `The content was simple yet effective. It was very 
                informative and eye opening.`, `This platform made me bend my mind and think things from a different 
                perspective and dig deeper on certain things. `]}
                teamCareTestimonail={true}
                points={4}
                designations={[`HR Team`, `Employee`, `HR Team`, `Employee`]}
                imageLogos={[`company_logos/nestle.jpg`, `company_logos/myntra.jpg`, `company_logos/marico.png`, `company_logos/asianpaints.png`]}
                imageAdjust={true} />
            <CompanyLogoSection />
            <PriceAndPlanSection />
            <GetAccessForm />
            <BackToTop />
            <Footer />
        </>
    )
}

export default OrganisationComponent;
