import React from 'react';
// import ReportFooter from '../components/sub_components/ReportFooter';

const PrivacyPolicy = () => {
  return (
    <main>
      <div className="container">
        <article id="privacy">
          <h1 className="hr-below">MINDPEERS PRIVACY POLICY</h1>
          {/* <h1 class="hr-below">MINDPEERS PRIVACY POLICY</h1> */}
          {/* <hr class="light" /> */}
          <p align="justify">
            <span>
              <span>
                <span>
                  <span lang="en-US">
                    <strong>
                      Mindpeers India Private Limited [CIN:
                      U85100DL2020FTC363235]
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span> (“</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Us</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>”, “</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>We</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>”, or “</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Mindpeers</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  ”, which also includes its affiliates) is the author and
                  publisher of the internet resource{' '}
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <em>www.mindpeers.co</em>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span> (“</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Website</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  ”) on the world wide web as well as the software and
                  applications provided by Mindpeers, including but not limited
                  to the mobile application(s), Mobile Website,{' '}
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  the other associated/ancillary applications, products,
                  websites and services managed by Mindpeers{' '}
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>(together with the Website, referred to as the “</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Mindpeers Platform</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>”).</span>
              </span>
            </span>
          </p>
          <p align="justify">
            <span>
              <span>
                <span>This privacy policy ("</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Privacy Policy</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  ") explains how we collect, use, share, disclose and protect
                  Personal Information about the Users of the Mindpeers
                  Platform, including the Practitioners (as defined in the Terms
                  of Use, which may be accessed via the following weblink{' '}
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <em>https://mindpeers.co/terms-and-conditions</em>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span> (the “</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Terms of Use</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  ”)), the End-Users (as defined in the Terms of Use), and the
                  visitors of Website (jointly and severally referred to as “
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>You</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>” or “</span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <strong>Users</strong>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  ” in this Privacy Policy). We created this Privacy Policy to
                  demonstrate our commitment to the protection of your privacy
                  and your personal information. Your use of and access to the
                  Mindpeers Platform is subject to this Privacy Policy and our
                  Terms of Use. Any capitalized term used but not defined in
                  this Privacy Policy shall have the meaning attributed to it in
                  our Terms of Use.
                </span>
              </span>
            </span>
          </p>
          <p align="justify">
            <span>
              <span>
                <span>
                  <u>
                    BY USING THE MINDPEERS PLATFORM OR BY OTHERWISE GIVING US
                    YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ,
                    UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED
                    IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY
                    POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND
                    SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS
                    PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD
                    OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT
                    OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH
                    THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE
                    MINDPEERS PLATFORM OR GIVE US ANY OF YOUR INFORMATION. IF
                    YOU USE THE MINDPEERS PLATFORM ON BEHALF OF SOMEONE ELSE{' '}
                  </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <em>
                    <u>(SUCH AS YOUR CHILD, MINOR, WARD OR EMPLOYEE)</u>
                  </em>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u> OR AN ENTITY </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <em>
                    <u>(SUCH AS YOUR EMPLOYER)</u>
                  </em>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u>
                    , YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL
                    OR ENTITY TO{' '}
                  </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u>
                    <strong>(I)</strong>
                  </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u>
                    {' '}
                    ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S
                    BEHALF, AND{' '}
                  </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u>
                    <strong>(II)</strong>
                  </u>
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>
                  <u>
                    {' '}
                    CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR
                    COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S OR
                    ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
                  </u>
                </span>
              </span>
            </span>
          </p>
          <ol>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>WHY THIS PRIVACY POLICY?</strong>
                    </u>
                  </span>
                </span>
              </p>
            </li>
          </ol>
          <ol>
            <ol>
              <li>
                <p align="justify">
                  <span>
                    <span>
                      <span>
                        <u>
                          This Privacy Policy is published in compliance with,{' '}
                        </u>
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>
                      <span>
                        <em>
                          <u>interalia</u>
                        </em>
                      </span>
                    </span>
                  </span>
                  <span>
                    <span>
                      <span>
                        <u>:</u>
                      </span>
                    </span>
                  </span>
                </p>
              </li>
            </ol>
          </ol>
          <ol>
            <ol>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Section 43A of the Information Technology Act, 2000.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Regulation 4 of the Information Technology (Reasonable
                          Security Practices and Procedures and Sensitive
                          Personal Information) Rules, 2011 (the “
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <strong>SPI Rules</strong>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>”).</span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Regulation 3(1) of the Information Technology
                          (Intermediaries Guidelines) Rules, 2011.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
              <li>
                <p align="justify">
                  <span>
                    <span>
                      <u>This Privacy Policy states the following:</u>
                    </span>
                  </span>
                </p>
                <ol>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>
                            The type of information collected from the Users,
                            including Personal Information (as defined in
                            paragraph 2.2.1 below) and Sensitive Personal Data
                            or Information (as defined in paragraph 2.2.2 below)
                            relating to an individual.
                          </span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>
                            The purpose, means and modes of collection, usage,
                            processing, retention, and destruction of such
                            information; and
                          </span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          How and to whom Mindpeers will disclose such
                          information.
                        </span>
                      </span>
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <u>
                        <strong>COLLECTION OF PERSONAL INFORMATION</strong>
                      </u>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Generally, some of the functions on the Mindpeers
                          Platform require us to know who you are so that we can
                          best meet your needs. When you access the Mindpeers
                          Platform, or through any interaction with us via
                          emails, telephone calls or other correspondence, we
                          may ask you to voluntarily provide us with certain
                          information that personally identifies you or could be
                          used to personally identify you. You hereby consent to
                          the collection of such information by Mindpeers.
                          Without prejudice to the generality of the above,
                          information collected by us from you may include{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <em>(but is not limited to)</em>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span> the following:</span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              contact data (such as your email address and phone
                              number).
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              demographic data (such as your gender, your date
                              of birth and your pin code).
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              data regarding your usage of the Mindpeers
                              Platform and history of the appointments made by
                              or with you through the use of Mindpeers Platform.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>insurance data </span>
                          </span>
                        </span>
                        <span>
                          <span>
                            <span>
                              <em>
                                (such as your insurance carrier and insurance
                                plan)
                              </em>
                            </span>
                          </span>
                        </span>
                        <span>
                          <span>
                            <span>.</span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              other information that you voluntarily choose to
                              provide to us (such as information shared by you
                              with us through emails or letters or uploaded on
                              the platform in connection with the Mindpeers
                              Platform).
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          The information collected from you by Mindpeers may
                          constitute ‘personal information’ or ‘Sensitive
                          Personal Data or Information’ under the SPI Rules.
                        </span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          “
                          <span>
                            <span>
                              <strong>Personal Information”</strong>
                            </span>
                          </span>
                        </span>
                        <span>
                          <span>
                            <span>
                              &nbsp;is defined under the SPI Rules to mean any
                              information that relates to a natural person,
                              which, either directly or indirectly, in
                              combination with other information available or
                              likely to be available to a body corporate, is
                              capable of identifying such person.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          “
                          <span>
                            <span>
                              <strong>
                                Sensitive Personal Data or Information”
                              </strong>
                            </span>
                          </span>
                        </span>
                        <span>
                          <span>
                            <span>
                              {' '}
                              is defined under the SPI Rules to mean personal
                              information about that person relating to:
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <ol>
            <ol>
              <ol>
                <ol type="a">
                  <li>
                    <p align="justify">
                      <span>
                        <span>passwords.</span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>
                            financial information such as bank accounts, credit
                            and debit card details or other payment instrument
                            details.
                          </span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>
                            physical, physiological, and mental health
                            condition.
                          </span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>sexual orientation.</span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>medical records and history.</span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>biometric information.</span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          <span>
                            information received by body corporate under lawful
                            contract or otherwise.
                          </span>
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>
                          visitor details as provided at the time of
                          registration or thereafter; and
                        </span>
                      </span>
                    </p>
                  </li>
                  <li>
                    <p align="justify">
                      <span>
                        <span>call data records.</span>
                      </span>
                    </p>
                  </li>
                </ol>
              </ol>
            </ol>
          </ol>
          <ol>
            <ol start="3">
              <li>
                <p align="justify">
                  <span>
                    <span>
                      Mindpeers will be free to use, collect and disclose
                      information that is freely available in the public domain
                      without your consent.
                    </span>
                  </span>
                </p>
              </li>
            </ol>
          </ol>
          <p align="justify">
            <span>
              <span>
                <span>
                  <u>
                    <strong>PRIVACY STATEMENTS</strong>
                  </u>
                </span>
              </span>
            </span>
          </p>
          <ol start="3">
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <u>
                        <strong>
                          USERS NOTE: THIS SECTION APPLIES TO ALL USERS.
                        </strong>
                      </u>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          A condition of each User’s use of and access to the
                          Mindpeers Platform is their acceptance of the Terms of
                          Use, which also involves acceptance of the terms of
                          this Privacy Policy. Any User that does not agree with
                          any provisions of the same has the option to
                          discontinue the use of the Mindpeers Platform provided
                          by Mindpeers immediately.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          An indicative list of information that Mindpeers may
                          require you to provide to enable your use of the
                          Mindpeers Platform is provided in the Schedule annexed
                          to this Privacy Policy.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          All the information provided to Mindpeers by a User,
                          including Personal Information or any Sensitive
                          Personal Data or Information, is voluntary. You
                          understand that Mindpeers may use certain information
                          of yours, which has been designated as Personal
                          Information or ‘Sensitive Personal Data or
                          Information’ under the SPI Rules,{' '}
                        </span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              for the purpose of providing you the access of
                              Mindpeers Platform,{' '}
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              for commercial purposes and in an aggregated or
                              non-personally identifiable form for research,
                              statistical analysis and business intelligence
                              purposes,{' '}
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              for sale or transfer of such research, statistical
                              or intelligence data in an aggregated or
                              non-personally identifiable form to third parties
                              and affiliates{' '}
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            for communication purpose so as to provide You a
                            better way of booking appointments and for obtaining
                            feedback in relation to the Practitioners and their
                            practice,{' '}
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              debugging customer support related issues{' '}
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              for the purpose of contacting you to complete any
                              transaction if you do not complete a transaction
                              after having provided us with your contact
                              information in the course of completing such steps
                              that are designed for completion of the
                              transaction.{' '}
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          <u>
                            Mindpeers also reserves the right to use the
                            information provided by or about the End-User for
                            the following purposes:
                          </u>
                        </span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            Publishing such information on the Website.
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Contacting End-Users for offering new products or
                              features on the Mindpeers Platform.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Contacting End-Users for taking product and
                              Service feedback.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing software usage patterns for improving
                              product design and utility.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing anonymized practice information for
                              commercial use.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Processing payment instructions including those
                              through independent third-party service providers
                              such as payment gateways, banking and financial
                              institutions, pre-paid instrument, and wallet
                              providers for the processing of payment
                              transaction or deferral of payment facilities.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If you have voluntarily provided your Personal
                          Information to Mindpeers for any of the purposes
                          stated above, you hereby consent to such collection
                          and use of such information by Mindpeers. Furthermore,
                          by providing us with the Personal Information you
                          authorize Mindpeers (including its business partners
                          and affiliates) contact You on Your telephone
                          number(s) and email for any purpose including those
                          mentioned in this sub-section 3.4 above, even if you
                          have registered yourself under DND or DNC or NCPR
                          services. Your authorization shall be valid as long as
                          your account is not deactivated in the manner as
                          provided in clause 3.9 below.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Collection use and disclosure of information which has
                          been designated as Personal Information or Sensitive
                          Personal Data or Information’ under the SPI Rules
                          require your express consent. By affirming your assent
                          to this Privacy Policy, you provide your consent to
                          such use, collection and disclosure as required under
                          applicable law.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          We do not control or endorse the content, messages or
                          information found on the Mindpeers Platform and,
                          therefore, Mindpeers specifically disclaims any
                          liability with regard to the services and any actions
                          resulting from your participation in any services, and
                          you agree that you waive any claims against Mindpeers
                          relating to same, and to the extent, such waiver may
                          be ineffective, you agree to release any claims
                          against Mindpeers relating to the same.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          You are responsible for maintaining the accuracy of
                          the information you submit to us, such as your contact
                          information provided as part of account registration.
                          If your personal information changes, you may correct,
                          delete inaccuracies, or amend information by making
                          the change on our member information page or by
                          contacting us through&nbsp;
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>hello@Mindpeers.co</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          . We will make good faith efforts to make requested
                          changes in our then-active databases as soon as
                          reasonably practicable. If you provide any information
                          that is untrue, inaccurate, out of date or incomplete
                          (or becomes untrue, inaccurate, out of date or
                          incomplete), or Mindpeers has reasonable grounds to
                          suspect that the information provided by you is
                          untrue, inaccurate, out of date or incomplete,
                          Mindpeers may, at its sole discretion, discontinue the
                          access of the Mindpeers Platform to you. There may be
                          circumstances where Mindpeers will not correct, delete
                          or update your Personal Data, including (a) where the
                          Personal Data is opinion data that is kept solely for
                          an evaluative purpose; and (b) the Personal Data is in
                          documents related to a prosecution if all proceedings
                          relating to the prosecution have not been completed.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If you wish to cancel your account on the Mindpeers
                          Platform or request that we no longer use your
                          information, contact us through{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>hello@Mindpeers.co</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          . We will retain your information for as long as your
                          account on the Mindpeers Platform is active and as
                          needed to provide you with the access to the Mindpeers
                          Platform. We shall not retain such information for
                          longer than is required for the purposes for which the
                          information may lawfully be used or is otherwise
                          required under any other law for the time being in
                          force. After a period of time, your data may be
                          anonymized and aggregated, and then maybe held by us
                          as long as necessary for us to provide our services
                          effectively, but our use of the anonymized data will
                          be solely for analytic purposes. Please note that your
                          withdrawal of consent or cancellation of account may
                          result in Mindpeers being unable to provide you with
                          the access to the Mindpeers Platform or to terminate
                          any existing relationship Mindpeers may have with you.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If you wish to opt-out of receiving non-essential
                          communications such as promotional and
                          marketing-related information regarding the Mindpeers
                          Platform, please send us an email at{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>hello@Mindpeers.co</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>.</span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers may require the User to pay with a credit
                          card, wire transfer, debit card or cheque for
                          Mindpeers Platform for which subscription amount(s)
                          is/are payable. Mindpeers will collect such User’s
                          credit card number and/or other financial institution
                          information such as bank account numbers and will use
                          that information for the billing and payment
                          processes, including but not limited to the use and
                          disclosure of such credit card number and information
                          to third parties as necessary to complete such billing
                          operation. Verification of credit information,
                          however, is accomplished solely by the User through
                          the authentication process. User’s credit-card/debit
                          card details are transacted upon secure sites of
                          approved payment gateways which are digitally under
                          encryption, thereby providing the highest possible
                          degree of care as per current technology.{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          However, Mindpeers provides you with an option not to
                          save your payment details
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          . User is advised, however, that internet technology
                          is not full proof safe, and User should exercise
                          discretion on using the same.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Due to the communications standards on the Internet,
                          when a User or the End-User or anyone who visits the
                          Website, Mindpeers automatically receives the URL of
                          the site from which anyone visits. Mindpeers also
                          receives the Internet Protocol (IP) address of each
                          User’s computer (or the proxy server a User used to
                          access the World Wide Web), User’s computer operating
                          system and type of web browser the User is using,
                          email patterns, as well as the name of User’s ISP.
                          This information is used to analyse overall trends to
                          help Mindpeers improve its Service. The linkage
                          between User’s IP address and User’s personally
                          identifiable information is not shared with or
                          disclosed to third parties. Notwithstanding the above,
                          Mindpeers may share and/or disclose some of the
                          aggregate findings (not the specific data) in
                          anonymized form (i.e., non-personally identifiable)
                          with advertisers, sponsors, investors, strategic
                          partners, and others in order to help grow its
                          business.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          The Website uses temporary cookies to store certain
                          (that is not sensitive personal data or information)
                          that is used by Mindpeers and its service providers
                          for the technical administration of the Website,
                          research, and development, and User administration. In
                          the course of serving advertisements or optimizing
                          services to its Users, Mindpeers may allow authorized
                          third parties to place or recognize a unique cookie on
                          the User’s browser. The cookies, however, do not store
                          any Personal Information of the User. You may adjust
                          your Internet browser to disable cookies. If cookies
                          are disabled you may still use the Website, but the
                          Website may be limited in the use of some of the
                          features.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          A User may have limited access to the Website without
                          creating an account on the Website. In order to have
                          access to all the features and benefits on our
                          Website, a User must first create an account on our
                          Website. To create an account, a User is required to
                          provide the following information, which such User
                          recognizes and expressly acknowledges is Personal
                          Information allowing others, including Mindpeers, to
                          identify the User, Name Email address, Phone number,
                          password chosen by the User. Other information
                          requested on the registration page, including the
                          ability to receive promotional offers from Mindpeers,
                          is optional. Mindpeers may, in future, include other
                          optional requests for information from the User to
                          help Mindpeers to customize the Website to deliver
                          personalized information to the User.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          This Privacy Policy applies to services that are owned
                          and operated by Mindpeers. Mindpeers does not exercise
                          control over third party links from within the
                          Mindpeers Platform. These other sites may place their
                          own cookies or other files on the Users’ computer,
                          collect data or solicit personal information from the
                          Users, for which Mindpeers is not responsible or
                          liable. Accordingly, Mindpeers does not make any
                          representations concerning the privacy practices or
                          policies of such third parties or terms of use of such
                          websites, nor does Mindpeers guarantee the accuracy,
                          integrity, or quality of the information, data, text,
                          software, sound, photographs, graphics, videos,
                          messages or other materials available on such
                          websites. The inclusion or exclusion does not imply
                          any endorsement by Mindpeers of the website, the
                          website's provider, or the information on the website.
                          If you decide to visit a third-party website linked to
                          the Website, you do this entirely at your own risk.
                          Mindpeers encourages the User to read the privacy
                          policies of that website.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          The Website may enable User to communicate with other
                          Users or to post information to be accessed by others,
                          whereupon other Users may collect such data. Such
                          Users, including any moderators or administrators, are
                          not authorized Mindpeers representatives or agents,
                          and their opinions or statements do not necessarily
                          reflect those of Mindpeers, and they are not
                          authorized to bind Mindpeers to any contract.
                          Mindpeers hereby expressly disclaims any liability for
                          any reliance or misuse of such information that is
                          made available by Users or visitors in such a manner.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers does not collect information about the
                          visitors of the Website from other sources, such as
                          public records or bodies, or private organisations,
                          save and except for the purposes of registration of
                          the Users (the collection, use, storage and disclosure
                          of which each End User must agree to under the Terms
                          of Use in order for Mindpeers to effectively render
                          the services through the Mindpeers Platform).
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers maintains a strict "No-Spam" policy, which
                          means that Mindpeers does not intend to sell, rent, or
                          otherwise give your e-mail address to a third party
                          without your consent.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers has implemented best international market
                          practices and security policies, rules, and technical
                          measures to protect the personal data that it has
                          under its control from unauthorised access, improper
                          use or disclosure, unauthorised modification and
                          unlawful destruction or accidental loss. However, for
                          any data loss or theft due to unauthorized access to
                          the User’s electronic devices through which the User
                          avails the services through the Mindpeers Platform,
                          Mindpeers shall not be held liable for any loss
                          whatsoever incurred by the User.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers implements reasonable security practices and
                          procedures and has a comprehensively documented
                          information security programme and information
                          security policies that contain managerial, technical,
                          operational and physical security control measures
                          that are commensurate with respect to the information
                          being collected and the nature of Mindpeers business.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers takes your right to privacy very seriously
                          and other than as specifically stated in this Privacy
                          Policy, will only disclose your Personal Information
                          in the event it is required to do so by law, rule,
                          regulation, law enforcement agency, governmental
                          official, legal authority or similar requirements or
                          when Mindpeers, in its sole discretion, deems it
                          necessary in order to protect its rights or the rights
                          of others, to prevent harm to persons or property, to
                          fight fraud and credit risk, or to enforce or apply
                          the Terms of Use.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <u>
                        <strong>
                          PRACTITIONERS NOTE: THIS SECTION APPLIES TO ALL
                          PRACTITIONERS.
                        </strong>
                      </u>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          As part of the registration as well as the application
                          creation and submission process that is available to
                          Practitioners on Mindpeers, certain information,
                          including Personal Information or Sensitive Personal
                          Data or Information is collected from the
                          Practitioners.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          All the statements in this Privacy Policy apply to all
                          Practitioners, and all Practitioners are therefore
                          required to read and understand the privacy statements
                          set out herein prior to submitting any Personal
                          Information or Sensitive Personal Data or Information
                          to Mindpeers, failing which they are required to leave
                          the Mindpeers Platform, including the Website
                          immediately.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Practitioners’ personally identifiable information,
                          which they choose to provide to Mindpeers, is used to
                          help the Practitioners describe and identify
                          themselves. This information is exclusively owned by
                          Mindpeers You will be the owner of your information
                          and you consent to Mindpeers collecting, using,
                          processing, and/or disclosing this information for the
                          purposes hereinafter stated. Mindpeers may use such
                          information for commercial purposes and in an
                          aggregated or non-personally identifiable form for
                          research, statistical analysis and business
                          intelligence purposes, and may sell or otherwise
                          transfer such research, statistical or intelligence
                          data in an aggregated or non-personally identifiable
                          form to third parties and affiliates. Mindpeers also
                          reserves the right to use the information provided by
                          or about the Practitioner for the following purposes:
                        </span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Publishing such information on the Website.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Contacting Practitioners for offering new products
                              or Mindpeers Platform.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Contacting Practitioners for taking product
                              feedback.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing software usage patterns for improving
                              product design and utility.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing anonymized practice information
                              including financial, and inventory information for
                              commercial use.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers automatically enables the listing of
                          Practitioners’ information on its Website as provided
                          by You using the Mindpeers Platform. The Practitioner
                          information listed on Website is displayed when
                          End-Users search for Practitioners on Website, and the
                          Practitioner information listed on Website is used by
                          End-Users to request for appointments. Any personally
                          identifiable information of the Practitioners listed
                          on the Website is not generated by Mindpeers and is
                          provided to Mindpeers by Practitioners who wish to
                          enlist themselves on the Website or is collected by
                          Mindpeers from the public domain. Mindpeers displays
                          such information on its Website on an as-is basis
                          making no representation or warranty on the accuracy
                          or completeness of the information. As such, we
                          strongly encourage Practitioners to check the accuracy
                          and completeness of their information from time to
                          time and inform us immediately of any discrepancies,
                          changes, or updates to such information. Mindpeers
                          will, however, take reasonable steps to ensure the
                          accuracy and completeness of this information.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers may also display information for
                          Practitioners who have not signed up or registered for
                          the Mindpeers Platform, provided that the
                          Practitioners have consented to Mindpeers collecting,
                          processing, and/or disclosing their information on the
                          Website. Such Practitioners are verified by Mindpeers
                          or its associates, and Mindpeers makes every effort to
                          capture accurate information for such Practitioners.
                          However, Mindpeers does not undertake any liability
                          for any incorrect or incomplete information appearing
                          on the Website for such Practitioners.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>
                        END-USERS NOTE: THIS SECTION APPLIES TO ALL END-USERS.
                      </strong>
                    </u>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          As part of the registration/application creation and
                          submission process that is available to End-Users on
                          this Website, certain information, including Personal
                          Information or Sensitive Personal Data or Information
                          is collected from the End-Users.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          All the statements in this Privacy Policy apply to all
                          End-Users, and all End-Users are therefore required to
                          read and understand the privacy statements set out
                          herein prior to submitting any Personal Information or
                          Sensitive Personal Data or Information to Mindpeers,
                          failing which they are required to leave the Mindpeers
                          immediately.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If you have inadvertently submitted any such
                          information to Mindpeers prior to reading the privacy
                          statements set out herein, and you do not agree with
                          the manner in which such information is collected,
                          processed, stored, used or disclosed, then you may
                          access, modify and delete such information{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>by using options provided on the Website.</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          {' '}
                          In addition, you can, by sending an email to{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>hello@Mindpeers.co</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          , inquire whether Mindpeers is in possession of your
                          personal data, and you may also require Mindpeers to
                          delete and destroy all such information.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          End-Users’ personally identifiable information, which
                          they choose to provide on the Website is used to help
                          the End-Users describe/identify themselves. Other
                          information that does not personally identify the
                          End-Users as an individual, is collected by Mindpeers
                          from End-Users (such as, patterns of utilization
                          described above) and is exclusively owned by
                          Mindpeers. Mindpeers may also use such information in
                          an aggregated or non-personally identifiable form for
                          research, statistical analysis and business
                          intelligence purposes, and may sell or otherwise
                          transfer such research, statistical or intelligence
                          data in an aggregated or non-personally identifiable
                          form to third parties and affiliates. In particular,
                          Mindpeers reserves with it the right to use anonymized
                          End-User demographics information and anonymized
                          End-User health information for the following
                          purposes:
                        </span>
                      </span>
                    </span>
                  </p>
                  <ol>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing software usage patterns for improving
                              product design and utility.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Analysing such information for research and
                              development of new technologies.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            Using analysis of such information in other
                            commercial product offerings of Mindpeers.
                          </span>
                        </span>
                      </p>
                    </li>
                    <li>
                      <p align="justify">
                        <span>
                          <span>
                            <span>
                              Sharing analysis of such information with third
                              parties for commercial use.
                            </span>
                          </span>
                        </span>
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers will communicate with the End-Users through
                          email, phone and notices posted on the Website or
                          through other means available through the service,
                          including text and other forms of messaging.{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>The End-Users can change their e-mail</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          {' '}
                          and contact preferences at any time by logging into
                          their "Dashboard" at www.Mindpeers.co and changing the
                          account settings.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          At times, Mindpeers conducts a User survey to collect
                          information about End-Users' preferences. These
                          surveys are optional and if End-Users choose to
                          respond, their responses will be kept anonymous.
                          Similarly, Mindpeers may offer contests to qualifying
                          End-Users in which we ask for contact and demographic
                          information such as name, email address and mailing
                          address. The demographic information that Mindpeers
                          collects in the registration process and through
                          surveys is used to help Mindpeers improve the
                          Mindpeers Platform to meet the needs and preferences
                          of End-Users.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers may keep records of electronic
                          communications and telephone calls received and made
                          for making appointments or other purposes for the
                          purpose of administration of services through the
                          Mindpeers Platform, customer support, research, and
                          development and for better listing of Practitioners.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          All Mindpeers employees and data processors, who have
                          access to, and are associated with the processing of
                          sensitive personal data or information, are obliged to
                          respect the confidentiality of every End-Users’
                          Personal Information or Sensitive Personal Data and
                          Information. Mindpeers has put in place procedures and
                          technologies as per good industry practices and in
                          accordance with the applicable laws, to maintain the
                          security of all personal data from the point of
                          collection to the point of destruction. Any
                          third-party data processor to which Mindpeers
                          transfers Personal Data shall have to agree to comply
                          with those procedures and policies or put in place
                          adequate measures on their own.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers may also disclose or transfer End-Users’
                          personal and other information provided by a User, to
                          a third party as part of reorganization or a sale of
                          the assets of a Mindpeers. Any third party to which
                          Mindpeers transfers or sells its assets will have the
                          right to continue to use the personal and other
                          information that End-Users provide to us, in
                          accordance with the Terms of Use
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          To the extent necessary to provide End-Users with the
                          services through the Mindpeers Platform, Mindpeers may
                          provide their Personal Information to third party
                          contractors who work on behalf of or with Mindpeers to
                          provide End-Users with such services through the
                          Mindpeers Platform, to help Mindpeers communicate with
                          End-Users or to maintain the Website or independent
                          third-party service providers to process payment
                          instructions including providing a payment deferral
                          facility to End-Users in relation to the services
                          through the Mindpeers Platform. These third-party
                          service providers have access to information needed to
                          process payments but may not use it for other
                          purposes. Generally, these contractors do not have any
                          independent right to share this information, however,
                          certain contractors who provide services through the
                          Mindpeers Platform on the Website, including the
                          providers of online communications services, may use
                          and disclose the personal information collected in
                          connection with the provision of these services
                          through the Mindpeers Platform in accordance with
                          their own privacy policies. In such circumstances, you
                          consent to us disclosing your Personal Information to
                          contractors, solely for the intended purposes only.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <u>
                        <strong>CASUAL VISITORS NOTE:</strong>
                      </u>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          No sensitive personal data or information is
                          automatically collected by Mindpeers from any casual
                          visitors of this website, who are merely perusing the
                          Website.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Nevertheless, certain provisions of this Privacy
                          Policy are applicable to even such casual visitors,
                          and such casual visitors are also required to read and
                          understand the privacy statements set out herein,
                          failing which they are required to leave this Website
                          immediately.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If you, as a casual visitor, have inadvertently
                          browsed any other page of this Website prior to
                          reading the privacy statements set out herein, and you
                          do not agree with the manner in which such information
                          is obtained, collected, processed, stored, used,
                          disclosed or retained, merely quitting this browser
                          application should ordinarily clear all temporary
                          cookies installed by Mindpeers. All visitors, however,
                          are encouraged to use the “
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          <em>clear cookies</em>
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          ” functionality of their browsers to ensure such
                          clearing/deletion, as Mindpeers cannot guarantee,
                          predict or provide for the behaviour of the equipment
                          of all the visitors of the Website.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          You are not a casual visitor if you have willingly
                          submitted any personal data or information to
                          Mindpeers through any means, including email, post or
                          through the registration process on the Website. All
                          such visitors will be deemed to be and will be treated
                          as, Users for the purposes of this Privacy Policy, and
                          in which case, all the statements in this Privacy
                          Policy apply to such persons.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>CONFIDENTIALITY AND SECURITY</strong>
                    </u>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Your Personal Information is maintained by Mindpeers
                          in electronic form on its equipment, and on the
                          equipment of its employees. Such information may also
                          be converted to physical form from time to time.
                          Mindpeers takes all necessary precautions to protect
                          your personal information both online and off-line and
                          implements reasonable security practices and measures
                          including certain managerial, technical, operational
                          and physical security control measures that are
                          commensurate with respect to the information being
                          collected and the nature of Mindpeers business.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          No administrator at Mindpeers will have knowledge of
                          your password. It is important for you to protect
                          against unauthorized access to your password, your
                          computer, and your mobile phone. Be sure to log off
                          from the Website when finished. Mindpeers does not
                          undertake any liability for any unauthorised use of
                          your account and password. If you suspect any
                          unauthorized use of your account, you must immediately
                          notify Mindpeers by sending an email to{' '}
                        </span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>hello@Mindpeers.co</span>
                      </span>
                    </span>
                    <span>
                      <span>
                        <span>
                          {' '}
                          You shall be liable to indemnify Mindpeers due to any
                          loss suffered by it due to such unauthorized use of
                          your account and password.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers makes all User information accessible to its
                          employees, agents, or partners and third parties only
                          on a need-to-know basis and binds only its employees
                          to strict confidentiality obligations.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Part of the functionality of Mindpeers is assisting
                          the Practitioners to maintain and organise such
                          information. Mindpeers may, therefore, retain and
                          submit all such records to the appropriate
                          authorities, or to Practitioners who request access to
                          such information.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Part of the functionality of the Mindpeers is
                          assisting the End Users to access information relating
                          to them. Mindpeers may, therefore, retain and submit
                          all such records to the relevant End Users, or to
                          their Practitioners.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Notwithstanding the above, Mindpeers is not
                          responsible for the confidentiality, security, or
                          distribution of your Personal Information by our
                          partners and third parties outside the scope of our
                          agreement with such partners and third parties.
                          Further, Mindpeers shall not be responsible for any
                          breach of security or for any actions of any third
                          parties or events that are beyond the reasonable
                          control of Mindpeers including but not limited to,
                          acts of government, computer hacking, unauthorised
                          access to computer data and storage device, computer
                          crashes, breach of security and encryption, poor
                          quality of Internet service or telephone service of
                          the User etc.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>CHANGE TO PRIVACY POLICY</strong>
                    </u>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers may update this Privacy Policy at any time,
                          with or without advance notice. In the event, there
                          are significant changes in the way Mindpeers treats
                          User’s personally identifiable information, or in the
                          Privacy Policy itself, Mindpeers will display a notice
                          on the Website or send Users an email, as provided for
                          above, so that you may review the changed terms prior
                          to continuing to use the Mindpeers Platform. As
                          always, if you object to any of the changes to our
                          terms, and you no longer wish to use the Mindpeers
                          Platform, you may contact hello@Mindpeers.co to
                          deactivate your account. Unless stated otherwise,
                          Mindpeers current Privacy Policy applies to all
                          information that Mindpeers has about you and your
                          account.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          If a User uses the Mindpeers Platform or accesses the
                          Website after a notice of changes has been sent to
                          such User or published on the Website, such User
                          hereby provides his/her/its consent to the changed
                          terms.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>CHILDREN'S AND MINOR'S PRIVACY</strong>
                    </u>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Mindpeers strongly encourages parents and guardians to
                          supervise the online activities of their minor
                          children and consider using parental control tools
                          available from online Mindpeers Platform and software
                          manufacturers to help provide a child-friendly online
                          environment. These tools also can prevent minors from
                          disclosing their name, address, and other personally
                          identifiable information online without parental
                          permission. Although the Mindpeers Website and
                          Mindpeers Platform are not intended for use by minors,
                          Mindpeers respects the privacy of minors who may
                          inadvertently use the internet or the mobile
                          application.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <u>
                      <strong>CONSENT TO THIS POLICY</strong>
                    </u>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          You acknowledge that this Privacy Policy is a part of
                          the Terms of Use of the Website and the Mindpeers
                          Platform, and you unconditionally agree that becoming
                          a User of the Website and the Mindpeers Platform
                          signifies your (i) assent to this Privacy Policy, and
                          (ii) consent to Mindpeers using, collecting,
                          processing and/or disclosing your Personal Information
                          in the manner and for the purposes set out in this
                          Privacy Policy. Your visit to the Website and use of
                          the Mindpeers Platform is subject to this Privacy
                          Policy and the Terms of Use.
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <u>
                        <strong>ADDRESS FOR PRIVACY QUESTIONS</strong>
                      </u>
                    </span>
                  </span>
                </span>
              </p>
              <ol>
                <li>
                  <p align="justify">
                    <span>
                      <span>
                        <span>
                          Should you have questions about this Privacy Policy or
                          Mindpeers information collection, use and disclosure
                          practices, you may contact, the Grievance Officer
                          appointed by Mindpeers in accordance with the
                          provisions the Information Technology Act, 2000 and
                          Rule 3(11) of Information Technology (Intermediaries
                          guidelines) Rules, 2011. We will use reasonable
                          efforts to respond promptly to any requests,
                          questions, or concerns, which you may have regarding
                          our use of your personal information. If you have any
                          grievance with respect to our use of your information,
                          you may communicate such grievance to the Grievance
                          Officer:
                        </span>
                      </span>
                    </span>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p
            align="justify"
            style={{
              marginLeft: '1.27cm',
              marginTop: '0.21cm',
              marginBottom: '0.21cm',
              lineHeight: '115%',
              orphans: '0',
              widows: '0',
            }}
          >
            <span>
              <span>
                <span>
                  <br />
                </span>
              </span>
            </span>
            <span>
              <span>
                <span>Name: Sarthak Agarwal</span>
              </span>
            </span>
          </p>
          <p
            align="justify"
            style={{
              marginLeft: '1.27cm',
              marginTop: '0.21cm',
              marginBottom: '0.21cm',
              lineHeight: '115%',
              orphans: '0',
              widows: '0',
            }}
          >
            <span>
              <span>
                <span>Address: 125 2</span>
              </span>
            </span>
            <span>
              <sup>
                <span>
                  <span>nd</span>
                </span>
              </sup>
            </span>
            <span>
              <span>
                <span>
                  {' '}
                  Floor, Shahpur Jat, New Delhi, North East, Delhi 110049,
                  India, <br /> Phone: 9810066307
                </span>
              </span>
            </span>
          </p>
          <p
            align="justify"
            style={{
              marginLeft: '1.27cm',
              marginTop: '0.21cm',
              marginBottom: '0.21cm',
              lineHeight: '115%',
              orphans: '0',
              widows: '0',
            }}
          >
            <span>
              <span>
                <span>
                  Email:&nbsp;
                  <a href="mailto:privacy@practo.com">hello@Mindpeers.co</a>
                </span>
              </span>
            </span>
          </p>
          <p>
            <span>
              <span>
                <u>
                  <strong>SCHEDULE</strong>
                </u>
              </span>
            </span>
          </p>
          <p>
            <span>
              <span>
                <u>
                  <strong>
                    Indicative List of Information by Nature of Service
                  </strong>
                </u>
              </span>
            </span>
          </p>
          <ol>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <strong>
                        End-Users using the Mindpeers Platform by registering
                        for an account:{' '}
                      </strong>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      You can create an account by giving us information
                      regarding your [name, mobile number, email address], and
                      such other information as requested on the&nbsp;End-User
                      registration page. This is to enable us to provide you
                      with the facility to use the account to book your
                      appointments and store other health-related information.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <strong>
                        End-Users using the Website without registering for an
                        account (i.e. ‘Guest’ End-User):{' '}
                      </strong>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>You can use the Website </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      <em>(with limited functionality)</em>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      {' '}
                      without registering for an account, but to book an
                      appointment, you may be asked to create an account and/or
                      certain information (including your [mobile number, email
                      ], and such other information as requested when you choose
                      to use the Mindpeers Platform without registration) to
                      confirm the appointment.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <strong>
                        Practitioner listing on the Website and Availing
                        Mindpeers Platform:{' '}
                      </strong>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      As a Practitioner, you may be required to provide us with
                      information regarding your [name, mobile number, email
                      address], and such other information as requested on
                      the&nbsp;Practitioner registration page&nbsp;to create an
                      account. Mindpeers may send an email and/or SMS
                      confirmations or other communications to End-Users in
                      connection with their bookings, appointments, or other
                      interactions with you if such interactions have been
                      facilitated by Mindpeers.
                    </span>
                  </span>
                </span>
              </p>
            </li>
            <li>
              <p align="justify">
                <span>
                  <span>
                    <span>
                      <strong>
                        Practitioners using Mindpeers products and Mindpeers
                        Platform:{' '}
                      </strong>
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      You will be required to create an account and may be
                      required to provide Mindpeers with information regarding
                      your [name, mobile number, email address], and such other
                      information as requested by Mindpeers, in order to
                      complete your registration. Upon registration, Mindpeers
                      will access to your customer/client information and their
                      medical records. You agree to make your customer/client
                      fully aware of such access.
                    </span>
                  </span>
                </span>
                <span>
                  <span>
                    <span>
                      Mindpeers reserves the right to extend and withdraw
                      booking facility to you at its sole discretion, based on
                      the number of End-User appointments being honoured by you.
                      The extension or withdrawal of such facility shall be
                      intimated to you by Mindpeers.
                    </span>
                  </span>
                </span>
              </p>
            </li>
          </ol>
          <p>
            <span>
              <span>
                <span>
                  <em>
                    <u>
                      <strong>
                        This Privacy Policy has been last updated on 4th August
                        2020
                      </strong>
                    </u>
                  </em>
                </span>
              </span>
            </span>
          </p>
        </article>
      </div>
      {/* <ReportFooter /> */}
    </main>
  );
};

export default PrivacyPolicy;
