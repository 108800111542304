const initialState = {
    therapists: [],
    loadedTherapists: false,
    homeTherapists: [],
    loadedHomeTherapists: false,
    therapist: {},
    therapyGiftPlans: {},
    loadedGiftPlans: false,
    filterText: "",
    loadedTherapist: false,
    loadedReviews: false,
    loadedSimilarTherapist: false,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case 'LIST_THERAPISTS':
            return {
                ...state,
                therapists: action.payload,
                loadedTherapists: true
            }
        case 'LIST_THERAPISTS_HOME':
            return {
                ...state,
                homeTherapists: action.payload,
                loadedHomeTherapists: true
            }
        case 'GET_THERAPIST':
            return {
                ...state,
                therapist: { ...state.therapist, ...action.payload },
                loadedTherapist: true
            }
        case 'GET_THERAPIST_REVIEWS':
            return {
                ...state,
                therapist: { ...state.therapist, reviews: action.payload },
                loadedReviews: true
            }
        case 'GET_SIMILAR_THERAPISTS':
            return {
                ...state,
                therapist: { ...state.therapist, similarTherapists: action.payload },
                loadedSimilarTherapist: true
            }
        case 'CLEAR_THERAPIST':
            return {
                ...state,
                loadedSimilarTherapist: false,
                loadedTherapist: false,
                loadedReviews: false,
            }
        case "SET_FILTER_TEXT":
            return {
                ...state,
                filterText: action.payload
            }
        case "SET_THERAPY_GIFT_PLANS":
            return {
                ...state,
                therapyGiftPlans: action.payload,
                loadedGiftPlans: true
            }
        default:
            return state;
    }
}