import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import * as History from 'history';
import rootReducer from './reducers';
// import { composeWithDevTools } from 'redux-devtools-extension';
export const history = History.createBrowserHistory();

const composeEnhanchers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk, routerMiddleware(history)];

export default createStore(
  connectRouter(history)(rootReducer),
  //applyMiddleware(...middleware),
  composeEnhanchers(applyMiddleware(...middleware))
);
