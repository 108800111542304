import React from 'react';
import { useSelector } from "react-redux"
import { formatDate } from "../../../../../utils/formatData"
import { getRedirectionUrl } from '../../../../../utils/_helper';
const Session = () => {
    //this componenets helps us in Profile page in Session section
    const therapist = useSelector(({ fetchTherapists: { therapist } }) => therapist)
    return (
        <>
            <section className="next-session">
                <div className="container">
                    <div className="d-lg-flex justify-content-between align-items-center">
                        {
                            therapist.schedule ?
                                <>
                                    <div className="head d-flex flex-wrap">
                                        next session available on {formatDate(therapist.schedule)}
                                    </div>
                                    <button type="button" className="btn booknow-btn" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/book-therapist/${therapist.user.id}`)}`), "_blank")}>Book Now</button>
                                </>
                                :
                                <div className="head d-flex flex-wrap">
                                    No Slots available
                                </div>
                        }

                    </div>
                </div>
            </section>
        </>
    );

}

export default Session;
