import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import FormPageModel from "../../common/modalfolder/FormPageModal"
import './OrganisationEmp.css'

const OrganisationEmp = () => {
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel
    let isMobile = false;
    if (window.innerWidth <= 500)
        isMobile = true;
    const getTeamCare = () => {
        changemodelValue(!modelValue);
        setShowModel(true);
    }
    return (
        <section id="asia-bg">
            <div className="container-emp">
                <div className="row no-gutters">
                    <div className="col-md-6 col-12">
                        <p className="sub-lead">Organizations & Employees</p>
                        <h3 className="sub-lead-title">
                            This is the new era of employee care. Your workforce wants to grow with you.
                            <br /><br />
                            Make them feel safe, productive and purposeful with TeamCare.
                        </h3>
                        {/* <Link onClick={handleClick} to={props.heading === 'Therapy' && 'get-matched'}><button type="button" className="btn btn-access-tool">{props.btnText}</button></Link> */}
                        {!isMobile && <button type="button" className="btn btn-homepage" onClick={getTeamCare}>GET TEAMCARE</button>}
                    </div>
                    <div className="col-md-6 col-12">
                        <img src="/img/dashboardImg.png" alt="" title="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {isMobile && <button type="button" className="btn btn-homepage" onClick={getTeamCare}>GET TEAMCARE</button>}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row text-center py-80">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2 className="try-app-heading-home">World’s first Mental Strength Platform. Become Mentally Stronger.</h2>
                        <div className="download-app-option mb-5 mt-5">
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <Link to={{pathname: "https://bit.ly/3AWhz9x"}} target="_blank">
                                        <img className="btn-fluid" src="https://cdn.mindpeers.co/site-icons/google_play_button.png" alt="google-play-store" />
                                    </Link>
                                    <Link to={{pathname: "https://apple.co/3z95Vca"}} target="_blank">
                                        <img className="btn-fluid" src="https://cdn.mindpeers.co/site-icons/ios_app_store.png" alt="apple-app-store" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="HomePage" />
        </section>
    )
}

export default OrganisationEmp
