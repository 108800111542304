import React from 'react'
import SectionOne from "./sub_components/homePage/SectionOne"
import SectionTwo from "./sub_components/homePage/SectionTwo"
import Footer from "./sub_components/Footer"
const HomePage = () => {
    return (
        <main className="overflow-hidden">
            <SectionOne />
            <SectionTwo />
            <Footer />
        </main>
    )
}

export default HomePage
