import React, { useEffect } from 'react';
import $ from 'jquery';
const BackToTop = () => {

    useEffect(() => {

        var btn = $('.scroll-top');

        // $(window).scroll(function () {
        //     if ($(window).scrollTop() > 300) {
        //         btn.addClass('show');
        //     } else {
        //         btn.removeClass('show');
        //     }
        // });

        var position = $(window).scrollTop();
        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > position) {
                // console.log('scrollDown');
                $(".scroll-top").removeClass("active");
            } else {
                // console.log('scrollUp');
                $(".scroll-top").addClass("active");
            }
            position = scroll;
            if ($(this).scrollTop() < 200) {
                $(".scroll-top").removeClass("active");
            }
        });

        btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '200');
        });


    }, []);

    return (
        <>
            <a class="scroll-top" href="#">
                <i className="fas fa-chevron-up arrow"></i>
                SCROLL TO TOP
            </a>
        </>
    );
}

export default BackToTop;
