import React from 'react';
import { getRedirectionUrl } from '../../../../utils/_helper';
import './style.css';

export default class Wheel extends React.Component {
    //this componenets helps us in SelfCare page in spinwheeler section
    //dynamic wheel component
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: null,
        };
        this.selectItem = this.selectItem.bind(this);

    }


    selectItem(event) {
        //onclick of the wheel getting the selected value and binding to constructor object
        if (this.state.selectedItem === null) {
            var selectedItemVal = event;
            const selectedItem = Object.keys(this.props.items).find(key => this.props.items[key] === selectedItemVal);
            if (this.props.onSelectItem) {
                this.props.onSelectItem(selectedItem);
            }
            this.setState({ selectedItem });
        } else {
            this.setState({ selectedItem: null }, () => {
                this.selectItem(event)
            });
        }
    }


    render() {
        const { selectedItem } = this.state;
        const { items } = this.props;
        const wheelVars = {
            //this helps the wheel to show the selected value after the wheel rotation in css
            '--nb-item': items.length,
            '--selected-item': selectedItem
        };
        const spinning = 'spinning ';
        if (selectedItem) {
            wheelVars['--selected-item'] = selectedItem
        }

        const selectItemTextVar = {
            '--bottom-px': 0
        }

        if (selectedItem === '3' || selectedItem === '4' || selectedItem === '5') {
            selectItemTextVar['--bottom-px'] = "-10px"
        }

        return (
            <>
                <div className="wheel-container">
                    <div className={`wheel  ${spinning}`} style={wheelVars} onClick={(e) => {
                        this.selectItem(e.target.innerText)
                    }}>
                        {items.map((item, index) => (
                            <div className="wheel-item" key={index} style={{ '--item-nb': index }}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="emotion-content">
                        <h3>I am feeling <br /> <span style={selectItemTextVar}>{items[selectedItem] ? items[selectedItem] : items[0]}</span></h3>
                        <p>Life happens in real time and your emotions don’t have to wait</p>
                        <button type="button" className="btn btn-understand-fear" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}>Feel Better Now</button>
                    </div>
                </div>
            </>
        );
    }
}
