import React , { useEffect, useState } from 'react';
import TherapisitSignUpForm from './sub_components/therapisitSignup/TherapisitSignUpForm';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __resetAllSteps } from '../store/actions/therapistSignUp.action'
import defaultState from '../default_config/default_intial_state'
import Header from './sub_components/therapisitSignup/Header';

const TherapisitSignUpComponent = (props) => {
    const [step, setStep] = useState(1)
    const initialSignupSteps = {
        step1 : defaultState.therapisitSignUpDefaultState.step1,
        step2 : defaultState.therapisitSignUpDefaultState.step2,
        step3 : defaultState.therapisitSignUpDefaultState.step3
    }
    useEffect(()=>{
        props.__resetAllSteps(initialSignupSteps)
    },[window.location.pathname])

    console.log("Therapy",props)
    //this contains overall component of therapisit sign up page
    
    return (
        <>
            <TherapisitSignUpForm step={step} setStep={setStep} />
        </>
    );
}


const mapStateToProps = ({ therapistSignUp }) => ({
    ...therapistSignUp
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
      __resetAllSteps
    },
    dispatch
  );
  
export default connect(mapStateToProps, mapDispatchToProps)(TherapisitSignUpComponent);
