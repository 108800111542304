import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import fetchTherapist from "../../store/actions/fetchTherapist.action"

const SearchBar = () => {
    const dispatch = useDispatch()
    //this is searchbar , we are using it in meet our expert page
    const filterText = useSelector(({ fetchTherapists: { filterText } }) => filterText);
    return (
        <>
            <div className="row search-therapist">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="d-flex flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap mb-9">
                        <input className="from-control" type="text" placeholder="Search MindCoach by name" value={filterText} onChange={(e) => dispatch(fetchTherapist.setFilterText(e.target.value))} />
                        <button type="button" className="btn btn-search"  >Search</button>
                    </div>
                </div>
            </div>
        </>
    );
}
//
export default SearchBar;
