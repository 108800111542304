import React from 'react'
import PropTypes from 'prop-types'

const BackButton = (props) => {
    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="prev-step mb-3">
                <a style={{color:"#294962"}} className="cursor-pointer" onClick={()=>props.handleBack()}><i className="fas fa-chevron-left mr-2" ></i>Back</a>
            </div>
        </div>
    )
}

BackButton.propTypes = {
    handleBack : PropTypes.func
}

export default BackButton
