import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import MeetTherapistsSection from "../../MeetTherapistsSection";
import ProfileHeader from "./ProfileHeader";
import ProfileAbout from "./ProfileAbout";
import ProfileReview from "./ProfileReview";
import Session from "./Session";
import LoadingComp from "../../../../common/LoadingComp";
const ProfileComponent = () => {
  //this componenets helps us in therapy -> subpage ->meetourexperts -> subpage -> profile
  //hold the information about each therapist
  const { loadedTherapist, loadedReviews, loadedSimilarTherapist } =
    useSelector(
      ({
        fetchTherapists: {
          loadedTherapist,
          loadedReviews,
          loadedSimilarTherapist,
        },
      }) => ({ loadedTherapist, loadedReviews, loadedSimilarTherapist })
    );
  return (
    <>
      <section id="home" className="therists-container">
        <section>
          <div className="container">
            <div className="row align-items-center profile-page ">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-8">
                {
                  loadedTherapist && loadedReviews ? (
                    <>
                      <ProfileHeader />
                      <ProfileAbout />
                      <ProfileReview />
                    </>
                  ) : (
                    <LoadingComp text="Loading..." />
                  )
                }
              </div>
            </div>
          </div>
        </section>
      </section>
      {loadedTherapist && <Session />}
      <MeetTherapistsSection toggle={true} />
    </>
  );
};

export default ProfileComponent;
