import api from "./ApiService";
export default {
    getVentOutParents: async (limit, pageNo) => {
        let response = await api(
            `query {
                listWallMessages(parent:null,limit:${limit},offset:${pageNo}){
                  id
                  likes
                  replies
                  message
                  created_at
                  user {id}
                  parent{id likes}
                }
              }
            `
        );
        if (response.error !== null) {
            return false;
        } else {
            return {
                listWallMessages: response.data.listWallMessages,
                TotalVents: response.data.ventOutWallMessagesTotal
            }
        }
    },
    getVentOutChildren: async (parent) => {
        let response = await api(
            `query {
                listWallMessages(parent:"${parent}"){
                  id
                  likes
                  replies
                  message
                  created_at
                  user {id}
                  parent{id likes}
                }
              }
            `
        );
        if (response.error !== null) {
            return false;
        } else {

            return response.data.listWallMessages;
        }
    },
    postWallMessage: async (message, parent = null) => {
        let response = await api(
            `mutation postWallMessage($message:String!){
                postWallMessage(parent:${parent},message:$message){
                    id
                }
              }
            `, { message }
        );
        if (response.error !== null) {
            return false;
        } else {
            return true;
        }
    },

}