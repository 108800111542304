import axios from 'axios';
const saveAccesSFormData = async (formData) => {
    try {
        const config = {
            headers: {
                Content: "application/json",
            },
        };
        const res = await axios.post(process.env.REACT_APP_API_URL, formData, config);
        return res.data;
    } catch (err) {
        console.log("could not send request ...", err)
    }

}

export const uploadFileToS3 = async (S3Uri, fileObj, type) => {
    try {
        const config = {
            headers: {
                'Content-Type': `${type}`
            },
        };
        await axios.put(S3Uri, fileObj, config);
        return true;
    } catch (err) {
        return false
    }
}
export default saveAccesSFormData;