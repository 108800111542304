import React from 'react'
import CommonHeader from '../CommonHeader'
import HeadingSection from '../HeadingSection'
import { getRedirectionUrl } from '../../../utils/_helper'
const SectionOne = () => {
    //this componenets helps us in SelfCare page in Header section
    //passing custom props to section header
    return (

        <section id="home">

            <HeadingSection
                heading="SelfCare By MindPeers"
                firstClassName="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 py-8"
                secondClassName="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 text-right"
                description={`Cope with emotions, respond to overthinking and build routines.`}
                btnText="Get Mentally Strong NOW"
                imgPath={`img/dashboardImg.png`}
                executeBtnClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}
            />
            <CommonHeader
                heading="Get Personalised Care.  Anytime.  Anywhere."
                selfcare={true}
                subHeading="Create your own unwinding zone on our SelfCare tool.
                Prioritise what matters and enjoy all features that help you bring peace of mind. SelfCare lets you -"
                firstImageText="Manage Your Emotions"
                firstImage="img\emotions.svg"
                secondImageText="Develop New Routines"
                secondImage="img\routines.svg"
                thirdImage="img\relax.svg"
                thirdImageText="Relax And Unwind"
            />
        </section>
    )
}

export default SectionOne
