import React from 'react'
import { Link } from 'react-router-dom'

const FooterAppSection = (props) => {
    // this is  footer section  can commonly used  Try On The App section
    return (
        <div>
            <section id="try-app-bg" className="try-our-app text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h2 className="try-app-heading">World’s first Mental Strength Platform. Become Mentally Stronger.</h2>
                        <div className="download-app-option mb-5 mt-5">
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <Link to={{pathname: "https://bit.ly/3AWhz9x"}} target="_blank">
                                        <img className="btn-fluid" src="https://cdn.mindpeers.co/site-icons/google_play_button.png" alt="google-play-store" />
                                </Link>
                                <Link to={{pathname: "https://apple.co/3z95Vca"}} target="_blank">
                                    <img className="btn-fluid" src="https://cdn.mindpeers.co/site-icons/ios_app_store.png" alt="apple-app-store" />
                                </Link>
                            </li>
                        </ul>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FooterAppSection
