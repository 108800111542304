import React, { Fragment } from 'react'

const CustomTooltipTwo = (props) => {
    return (
        <Fragment>
            <i className="fas fa-info-circle info-icon">
                <div className="info-wrapper position-absolute">
                    <h3 className="info-title">{props.ques}</h3>
                    <p className="info-title-content">{props.content}</p>
                </div>
            </i>
        </Fragment>
    )
}

export default CustomTooltipTwo
