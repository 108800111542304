export const __basicInfoScreenOne = (data) => (dispatch) => {
    console.log("from redux data 1",data)
    dispatch({
        type: 'FILL_Basic_Info_Screen1_DATA',
        payload: data
    });
}  
export const __basicInfoScreenTwo = (data) => (dispatch) => {
    console.log("from redux data 2",data)
    dispatch({
        type: 'FILL_Basic_Info_Screen2_DATA',
        payload: data
    });
}  
export const __preferencesScreenOne = (data) => (dispatch) => {
    console.log("from redux data 1",data)
    dispatch({
        type: 'FILL_PREFERENCES_Info_Screen1_DATA',
        payload: data
    });
} 
export const __preferencesScreenTwo = (data) => (dispatch) => {
    console.log("from redux data 1",data)
    dispatch({
        type: 'FILL_PREFERENCES_Info_Screen2_DATA',
        payload: data
    });
} 
export const __logisticScreenOne = (data) => (dispatch) => {
    console.log("from redux data 1",data)
    dispatch({
        type: 'FILL_Logistics_Info_Screen1_DATA',
        payload: data
    });
} 

export const __resetAllSteps = (data) => (dispatch) => {
    console.log("data2222",data)
    dispatch({
        type: 'RESET_DATA',
        payload: data
    });
}
