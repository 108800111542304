
import React from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar';
import TherapyComponent from '../../components/Therapy'
import { connect } from 'react-redux';

const Therapy = ({ TopBarDisplay: { openTopBar } }) => {
    const buttonState = true;
    return (
        <main>
            {openTopBar ? <TopBar /> :
                (<>
                    <TopBar />
                    <TherapyComponent />
                </>
                )
            }
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(Therapy);
