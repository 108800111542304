import React from "react";
import "./LoadingComp.css";

const LoadingComp = ({ text }) => {
  return (
    <div className="fetch-content">
      <div className="loading">
        <p>{text}</p>
        <span></span>
      </div>
    </div>
  );
};

export default LoadingComp;
