import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getRedirectionUrl } from '../../utils/_helper';
import FormPageModel from "../common/modalfolder/FormPageModal";
const CommonHeader = (props) => {
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel
    //this componenets helps us in Organisation page in    below header , Workplace section

    const handleBtnClick = () => {
        if (props.selfcare)
            window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")
        else {
            changemodelValue(!modelValue);
            setShowModel(true);
        }
    }
    return (
        <>
            <div className="container">
                <div className="row mt-100">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className={`sub-lead ${props.selfcare ? "includewhitespace" : ""}`}>{props.heading}</p>
                        <h3 className="sub-lead-small-title">{props.subHeading}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="cure-box text-center">
                            <img src={props.firstImage} className="img-fluid" width="282" height="200" alt="" title="" />
                            <h4>{props.firstImageText}</h4>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="cure-box text-center">
                            <img src={props.secondImage} className="img-fluid" width="282" height="200" alt="" title="" />
                            <h4>{props.secondImageText}</h4>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="cure-box text-center">
                            <img src={props.thirdImage} className="img-fluid" width="268" height="200" alt="" title="" />
                            <h4>{props.thirdImageText}</h4>
                        </div>
                    </div>
                </div>

                <div className="row pb_30">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="text-center">
                            <Link onClick={handleBtnClick} className="get-started">LET'S GET STARTED <i className="fas fa-arrow-right cus-arr"></i></Link>
                        </div>
                    </div>
                </div>
                <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="Ready to Get Started" />
            </div>
        </>
    );
}

export default CommonHeader;
