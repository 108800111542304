import React, { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import ventOutAction from "../../../../store/actions/ventout.action"
import CustomToast from "../../../../components/common/Toast/CustomToast"
import { ToastConstant } from '../../../../default_config/toastConstant'
import $ from "jquery"
const VentPaginationRow = () => {
    const dispatch = useDispatch()
    const { paginationRow, pageNo, maxPages } = useSelector(({ ventOut: { paginationRow, pageNo, maxPages } }) => ({ paginationRow, pageNo, maxPages }))

    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })
    const changePageNo = (page) => {
        dispatch(ventOutAction.changePageNo(page - 1));
        $('html, body').animate({
            scrollTop: $(".vent-out-wall").offset().top
        }, 1000);
    }

    const increasePage = () => {
        if (pageNo + 1 === maxPages) {
            setOpen(true)
            setToastData(ToastConstant.LAST_PAGE)
        } else {
            /// dispatch(ventOutAction.increaseVentOutRow())
            changePageNo(pageNo + 2)
        }
    }

    const decreasePage = () => {
        //  dispatch(ventOutAction.decreaseVentOutRow())
        if (pageNo == 0) {
            setOpen(true)
            setToastData(ToastConstant.FIRST_PAGE)
        } else {
            //dispatch(ventOutAction.decreaseVentOutRow())
            changePageNo(pageNo)
        }
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="d-flex justify-content-end align-items-center">
                    <div>
                        <div className="previous-page" onClick={decreasePage}>
                            <img src="img/left_arrow.svg" height="65" width="65" />
                        </div>
                    </div>
                    <div>
                        <div className="pagination-list">
                            <ul className="list-inline mb-0">
                                {paginationRow.map((page) => < li className={`list-inline-item ${pageNo + 1 == page ? "current" : ""}`} key={page} onClick={() => changePageNo(page)}>
                                    {page}
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div>
                        <div className="next-page" onClick={increasePage}>
                            <img src="img/right_arrow.svg" height="65" width="65" />
                        </div>
                    </div>
                </div>
            </div>
            {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }
        </div >
    )
}

export default VentPaginationRow
