import React, { useEffect, useState } from "react";
import formValidation from "../../../utils/formValidation";
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __setStep3 } from '../../../store/actions/therapistSignUp.action'
import useStepSignUpForm from "../../../utils/useStepSignUpForm";
import TherapyService from "../../../api/services/TherapyService"
import CommonModal from "../../common/modalfolder/CommonModal"
import { __resetAllSteps } from '../../../store/actions/therapistSignUp.action'
import defaultState from '../../../default_config/default_intial_state'
import { useLocation } from 'react-router-dom';

const Step3 = (props) => {
  // this is therpaisisit signup page step 3 form component this component uses redux to handle data
  // this form has separate form-validation hook called useStepSignUpForm

  const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
  const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

  const [submitErrMessage, changeSubmitErrMessage] = useState('');
  const placeholdercolor = "form-control span-error is-danger";
  const formControl = "form-control";
  const location = useLocation();
  const initialSignupSteps = {
    step1: defaultState.therapisitSignUpDefaultState.step1,
    step2: defaultState.therapisitSignUpDefaultState.step2,
    step3: defaultState.therapisitSignUpDefaultState.step3
  }

  const formValues = () => {
    //call back function after the form submit
    props.__setStep3(values)
    TherapyService.signUpTherapist({ step1: props.step1, step2: props.step2, step3: values }, props.experienceEnums).then((res) => {
      console.log("result..", res)
      if (res.signupDoctor) {
        setShowModel(true);
        changemodelValue(!modelValue);
        props.__resetAllSteps(initialSignupSteps)
        setTimeout(() => {
          props.history.push({
            pathname: "/organisation",
            search: `${location.search}`
        })
        }, [5000])
      }
      else if (res.error && res.error.errors[0].code == "EXIST_ALREADY")
        changeSubmitErrMessage("User exist already , try changing Email ID")
      else
        changeSubmitErrMessage("Error in saving , try again")
    })
  };
  const { values, errors, handleChange, handleSubmit } = useStepSignUpForm(
    //sends/recieve our values and errors to formvalidation.js and useform custom hooks
    formValues,
    formValidation.step3Form,
    props.step3,
    {}
  );



  return (
    <>
      <div id="Step-2">
        <h3 className="step-heading">
          Step 3: Enter Specialization and Current Practice
        </h3>
        <div className="step-form">
          <div className="form-group row align-items-center">
            <label
              for="name"
              className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Please tell us something about yourself
            </label>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
              <textarea
                onChange={handleChange}
                className={errors.myself ? placeholdercolor : formControl}
                name="myself"
                id=""
                placeholder={errors.myself || "Write here.."}
                value={values.myself || ""}
              ></textarea>
            </div>
          </div>
          <div className="form-group row align-items-center">
            <label
              for="gender"
              className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Do you have your own space?
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="own-space">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      id="yes"
                      name="radio_own_space"
                      value="yes"
                      checked={values.radio_own_space === "yes"}
                      onChange={handleChange}
                    />
                    <label for="yes">Yes</label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      id="no"
                      name="radio_own_space"
                      value="no"
                      checked={values.radio_own_space === "no"}

                      onChange={handleChange}
                    />
                    <label for="no">No</label>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {
            values && values.radio_own_space === "yes" && (
              <div
                className="form-group row align-items-center"
              >
                <label
                  for="name"
                  className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 col-form-label"
                >
                  Address of Space:
              </label>
                <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                  <textarea
                    onChange={handleChange}
                    className={errors.address ? placeholdercolor : formControl}
                    name="address"
                    id=""
                    placeholder={errors.address || "Enter Address.."}
                    value={values.address || ""}
                  ></textarea>
                </div>
              </div>
            )}
          <div className="row"><div className="col">
            {submitErrMessage && <p className="text-danger">{submitErrMessage}</p>}
          </div></div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="move-btn">
                <div className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center">
                  <a
                    href="javascript:void(0)"
                    className="disabled-step"
                    onClick={() => props.setStep(2)}
                  >
                    Previous Step
                  </a>
                  <button type="submit" className="btn btn-next" onClick={handleSubmit}>
                    Finish
                  </button>
                </div>
                <CommonModal modelValue={modelValue} showModel={showModel} formData={values} errorMessage={errors} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ therapistSignUp }) => ({
  ...therapistSignUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    __setStep3,
    __resetAllSteps
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step3));
