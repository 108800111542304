import React from 'react';

const ReportFooter = () => {
  return (
    <section className="mci-footer">
      <div className="mci-footer-content">
        <p>
          Copyright MindPeers 2021 - Report cannot be replicated. Recipient
          abides by the{' '}
          <a href="/terms-and-condition" target="_blank">
            {' '}
            Terms & Conditions{' '}
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" target="_blank">
            Company Privacy Policy
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default ReportFooter;
