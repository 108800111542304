import React from 'react'
import SectionHeader from '../HeadingSection'
import MindPeersBoat from './MindPeersBoat'
import LatestBoatSection from './LatestBoatSection'
import { getRedirectionUrl } from '../../../utils/_helper'
const SectionOne = () => {
    //this componenets helps us in community page in SectionOne section
    return (
        <section id="home">
            <SectionHeader
                heading="Boats By MindPeers"
                firstClassName="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 py-8"
                secondClassName="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-right"
                description={`Everyone goes through unique issues, which can be difficult for others to understand. Meet people who resonate with your emotions and build a community in a safe space.`}
                btnText="Hop On"
                imgPath={`img/community-right-img.svg`}
                executeBtnClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`boat`)}`), "_blank")}
            />
            {/* <MindPeersBoat /> */}
            <LatestBoatSection />
        </section>
    )
}

export default SectionOne
