import React from 'react'
import SectionOne from './sub_components/community/SectionOne'
import VentOutWall from './sub_components/community/VentOutWall/VentOutWall'
import ForYouWithLove from './sub_components/community/ForYouWithLove'
import OurBlog from './sub_components/community/OurBlog'
import FooterAppSection from './sub_components/FooterAppSection';
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'

const CommunityComponent = () => {
    //this is contain overall componenets of Community page
    return (
        <>
            <SectionOne />
            {/* <VentOutWall /> */}
            {/* <ForYouWithLove /> */}
            {/* <OurBlog /> */}
            <FooterAppSection />
            <BackToTop />
            <Footer />
        </>
    )
}

export default CommunityComponent;
