import React from 'react'
import { useSelector } from "react-redux"
import { TopBar } from "../../components/common/Topbar/TopBar"
import TherapistLogin from "../../components/sub_components/therapistLogin/TherapistLogin"
export default () => {
    const openTopBar = useSelector(({ TopBarDisplay: { openTopBar } }) => openTopBar)
    return (
        <main>
            {openTopBar ? <TopBar btnText={'TRY FOR FREE'} /> :
                <>
                    <TopBar btnText={'TRY FOR FREE'} />
                    <TherapistLogin />
                </>
            }
        </main>
    )
}
