import api from "./ApiService";

export default class TeamCareService {
  static async enquiry(values) {
    let response = await api(
      `mutation{
                submitEnquiry(
                    name:"${values.name}"
                    email:"${values.email}"
                    contact:{
                      code:"${values.phone.slice(0, 3)}"
                      mobile:"${values.phone.slice(4)}"
                    }
                    designation:"${values.designation}"
                    employees:"${values.employees || ""}"
                    company:"${values.company || ""}"
                    message:"""${values.message || ""}"""
                    plan:"${values.plan || ""}"
                    via:"${values.via || ""}"
                )
        }`
    );
    if (response.error !== null) {
      return false;
    } else {
      return response.data;
    }
  }
}
