import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Testimonial from './sub_components/Testimonial'
import TherapyMode from './sub_components/therapy/TherapyMode'
import MeetTherapistsSection from './sub_components/therapy/MeetTherapistsSection'
import TherapyGift from './sub_components/therapy/TherapyGift'
import SectionOne from './sub_components/therapy/SectionOne'
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'
import FooterAppSection from "./sub_components/FooterAppSection"
import fetchTherapist from "../store/actions/fetchTherapist.action"

const TherapyComponent = () => {
    //this is contain overall componenets of Therapy page
    const loadedTherapists = useSelector(({ fetchTherapists: { loadedTherapists } }) => loadedTherapists)
    const dispatch = useDispatch();
    useEffect(() => {
        if (!loadedTherapists)
            dispatch(fetchTherapist.fetchTherapists("home"))
    }, [dispatch])
    return (
        <>
            <SectionOne />
            <TherapyMode />
            <MeetTherapistsSection toggle={false} />
            <Testimonial
                testimonialSubheading="There are thousands like you, who trust us with their wellness and self-care"
                testimonialCardText={[`My therapist is very well equipped and conscious of his role as a facilitator to the process. The best part is, it didn't turn out to be "too much" for me. We talked to the level of my comfort and gradually went ahead`,
                    `She (my therapist) makes me feel so seen and heard. With her, vulnerability comes easy.`,
                    `It was awesome to share so much about myself with someone professional. I felt like I too matter.`,
                    `My therapist focuses on solutions and gives practical baby steps to follow and work on. She makes me self-enquire myself alongside understanding me at a core level.`,
                    `This is the first time I’m able to see my life less difficult as I’ve learnt how to deal with the negative feelings.
                    My therapist has always given me helpful and easy to do homework. I’m happy I’ve got a therapist like him.`]}
                TestimonialNames={["Gaurav Sachdev", "Deepa Malik", "Noor Khanna", "Arhaan Singh", "Kritika Kapoor"]}
                points={5}
                therapy={true}
                imageLogos={[`Bitmap.svg`, `Bitmap.svg`, `Bitmap.svg`, `Bitmap.svg`, `Bitmap.svg`, `Bitmap.svg`]} />
            <TherapyGift />
            <FooterAppSection />
            <BackToTop />
            <Footer />
        </>
    )
}

export default TherapyComponent;
