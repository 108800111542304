import React, { useEffect, useState } from "react";
import "./headers.css";
import { getRedirectionUrl, platformType } from "../../../utils/_helper";
import ScannerModal from "../modalfolder/ScannerModal";

const TopInfoBar = ({ isMobile }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [type, setType] = useState("");
  useEffect(() => {
    setType(platformType());
  }, []);
  const clickHandler = () => {
    if (isMobile) {
      switch (type) {
        case "IOS":
          return window.open(
            "https://apple.co/3z95Vca",
            "_blank",
            "noopener,noreferrer"
          );
        case "ANDROID":
          return window.open(
            "https://bit.ly/3AWhz9x",
            "_blank",
            "noopener,noreferrer"
          );
        default:
          return window.open(
            "https://app.mindpeers.co",
            "_blank",
            "noopener,noreferrer"
          );
      }
    } else {
      setModalOpen(true);
    }
  };

  return (
    <div>
      <ScannerModal show={modalOpen} setShow={setModalOpen} />
      <div
        style={{ minHeight: isMobile ? "3.563rem" : "2.938rem" }}
        className="info-header"
        onClick={clickHandler}
      >
        <img src="/img/link.svg" alt="" />
        <p className="info-header-text">
          Dance to the tunes of your feelings {isMobile ? <br /> : null} with
          MindPeers Club - <span className="hiring-text">Click Here!</span>
          💃
        </p>
      </div>
    </div>
  );
};

export default TopInfoBar;
