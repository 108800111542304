import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BoatLongCard from "./BoatLongCard";
import FilterByTag from "./FilterByTag";
import { useDispatch, useSelector } from "react-redux";
import BoatAction from "../../../store/actions/boatAction";
import LoadingComp from "../../common/LoadingComp";
import BoatService from "./../../../api/services/BoatService";

const LatestBoatSection = props => {
  const [showLoader, setShowLoader] = useState(true);
  const [boatListing, setBoatListing] = useState([]);

  const { selectedFilter, boatList } =
    useSelector(state => state.boatData) || [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BoatAction.getAllBoatWithFilter(selectedFilter));
  }, [selectedFilter, dispatch]);

  useEffect(() => {
    (async () => {
      const response = await BoatService.listAll();
      if (response.success) {
        setShowLoader(false);
        setBoatListing(response.data);
      } else {
        setShowLoader(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedFilter) {
      (async () => {
        const response = await BoatService.listAll(selectedFilter);
        if (response.success) {
          setShowLoader(false);
          setBoatListing(response.data);
        } else {
          setShowLoader(true);
        }
      })();
      console.log(boatListing);
    }
  }, [selectedFilter]);

  return (
    <div className="container">
      <div className="row align-items-center py-5">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          {showLoader ? (
            <LoadingComp text="Loading..." />
          ) : (
            <>
              <h4 className="boat-heading font-18 text-primary-color avenir-bold">
                Upcoming Boats
              </h4>
              <FilterByTag />
              {boatListing?.length ? (
                <div className="latest-boat-wrapper">
                  {boatListing.length > 0 ? (
                    boatListing.map((boat, index) => (
                      <BoatLongCard key={boat.id} boatData={boat} />
                    ))
                  ) : (
                    <div className="text-center">
                      <div className="alert alert-danger" role="alert">
                        <h4 className="mb-0">Sorry! No Data Available.</h4>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="no-data">
                  <img src="https://cdn.mindpeers.co/profile/no-past-plan.svg" alt="NO-BOAT" />
                  <h1>Exciting events will be added here soon 😊</h1>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

LatestBoatSection.propTypes = {};

export default LatestBoatSection;
