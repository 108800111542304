import React from 'react'
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __resetAllSteps } from '../../../store/actions/matchTherapy.action'
import defaultState from '../../../default_config/default_intial_state'

const StartNow = (props) => {
    const initialSignupSteps = {
        basicInfoScreenOne : defaultState.therapisitSignUpDefaultState.basicInfoScreenOne,
        basicInfoScreenTwo : defaultState.therapisitSignUpDefaultState.basicInfoScreenTwo,
        preferencesScreenOne : defaultState.therapisitSignUpDefaultState.preferencesScreenOne,
        preferencesScreenTwo : defaultState.therapisitSignUpDefaultState.preferencesScreenTwo,
        logisticScreenOne : defaultState.therapisitSignUpDefaultState.logisticScreenOne,
    }

    
    const handleStart = () => {
        props.setStepName('Basic Info')
        props.setPage(1)
        props.__resetAllSteps(initialSignupSteps)
    }

    return (
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="match-welcome-page">
                <h2 className="text-center">Match with the right therapist for you</h2>
                <h5 className="text-center">Answer a few questions to get matched with the right therapist for you</h5>
                <div className="start-now text-right">
                    <a className="btn btn-start-now" onClick={()=>handleStart()} >Start Now</a>
                </div>
            </div>
        </div>
    )
}


const mapStateToProps = ({ matchTherapyFormData }) => ({
    ...matchTherapyFormData
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        __resetAllSteps
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StartNow));