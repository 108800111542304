import React, { useEffect } from 'react'
import SignUpModal from '../../components/sub_components/auth/SignUp';
import { TopBar } from '../../components/common/Topbar/TopBar';


const SignUp = () => {
    useEffect(() => {
        var newMeta = document.createElement('meta');
        newMeta.name = "keywords"
        newMeta.content = "MindPeers , therapy, mindpeers organisation, mental, mental therapy"
        document.getElementsByTagName('head')[0].appendChild(newMeta);

        let meta = document.getElementsByTagName('meta')[3]
        meta.content = "This is MindPeers organisation page and here you find about our organisation"

        // document.title = "MP | Organisation"
    }, [])
    return (
        <main>
            <TopBar />
            <SignUpModal />
        </main>
    )
}

export default SignUp;
