import React from 'react'
import CommonHeader from '../CommonHeader'
import SectionHeader from '../HeadingSection'

const SectionOne = () => {
    //this componenets helps us in Organisation page in Header section
    //passing custom props to section header
    return (
        <section id="home">

            <SectionHeader
                heading="TeamCare By MindPeers"
                firstClassName="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 py-8"
                secondClassName="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 text-right"
                description={`Building and Optimizing Employee Mental Strength`}
                btnText="Explore the Tool"
                imgPath={`img/dashboardImg.png`}
            />
            <CommonHeader
                heading="Show Your Employees You Care"
                subHeading="Employees want a workplace that prioritises well-being.
                A peaceful state of mind is just as important for them as career growth. TeamCare lets you -"
                firstImageText="Improve Employee Productivity And Performance"
                firstImage="img\cure-svg-1.svg"
                secondImageText="Attract And Retain The Best Talent"
                secondImage="img\cure-svg-2.svg"
                thirdImageText="Create A World-Class Employee Experience"
                thirdImage="img\cure-svg-3.svg"
            />
        </section>
    )
}

export default SectionOne
