import dayjs from "dayjs"
const therapisitSignUpDefaultState = {
    step1: {
        city: "",
        email: "",
        fName: "",
        gender: "male",
        lName: "",
        number: "",
        password: "",
        language: "",
        state: "",
        picture: "",
        mobileCode: "+91",
        pronoun: "he/him",
        otherLanguages: []
    },
    step2: {
        degree: "",
        specialization: "",
        resume: "",
        certificates: "",
        workexperience: "",
        otherDegrees: [],
        otherSpecialization: []
    },
    step3: {
        address: "",
        myself: "",
        radio_own_space: "no"
    },
    getAccess: {
        company: "",
        designation: "",
        email: "",
        name: ""
        // number: "",
    },
    calcuateWorkSpace: {
        designation: "",
        email: "",
        name: "",
        organization: ""
    },
    signUpIndividual: {
        confirmPassword: "",
        email: "",
        name: "",
        password: "",
    },
    signUpOrganisation: {
        confirmPassword: "",
        email: "",
        name: "",
        password: "",
        designation: ""
    },
    signInIndividual: {
        email: "",
        password: "",
    },
    signInOrganisation: {
        email: "",
        password: "",
        accesscode: ""
    },
    basicInfoScreenOne: {
        dob: dayjs(new Date()).format('YYYY-MM-DD'),
        language: [],
    },
    basicInfoScreenTwo: {
        gender: "",
        preferPronoun: "",
    },
    preferencesScreenOne: {
        therapistGender: "",
        therapistAge: "",
    },
    preferencesScreenTwo: {
        isLGBTQ: "",
        interest: "",
    },
    logisticScreenOne: {
        weekday_group: "",
        weekday_group_timing: "",
    },
    contactUs: {
        "mobileCode": "+91",
        "message": ""
    }
}
export default {
    therapisitSignUpDefaultState
}