import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import default_dropdown_list from "../../../default_config/default_dropdown_list";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __setStep2 } from '../../../store/actions/therapistSignUp.action'
import formValidation from "../../../utils/formValidation";
import useStepSignUpForm from "../../../utils/useStepSignUpForm";
import { format } from "../../../utils/_helper";
import MultiSelectDropDown from "../../common/SelectDropDown/MultiSelectDropDown";
import CustomSingleSelect from "../../common/SelectDropDown/CustomSingleSelect";
import TagsInput from "../../common/TagsInput"
import CommonService from "../../../api/services/CommonService"
import { uploadFileToS3 } from "../../../api/sendRequests"

const Step2 = (props) => {
  // this is therpaisisit signup page step 2 form component this component uses redux to handle data
  // this form has separate form-validation hook called useStepSignUpForm
  // we used custom single select dropdown  and multi select plugin 

  const getSignedUrlAndUploadS3 = async (type, file) => {
    let resSignedUrl, resUploadS3;
    resSignedUrl = await CommonService.getSignedUrl(type, file.name);
    if (resSignedUrl)
      resUploadS3 = await uploadFileToS3(resSignedUrl.getSignedUrl.uri, file, resSignedUrl.getSignedUrl.type)
    return resSignedUrl.getSignedUrl.url
  }

  const [radioBtn, setRadioBtn] = useState('')
  const placeholdercolor = "form-control span-error is-danger";
  const formControl = "form-control";
  const [isRciNo, isRciState] = useState(false);
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedFileIndex, setSelectedFileIndex] = useState('');

  const formValues = async () => {
    //call back function after the form submit

    values['resume_S3'] = ''
    values['degrees_S3'] = []

    values['resume_S3'] = await getSignedUrlAndUploadS3("DOCTOR_DOCUMENT_RESUME", values.resume);
    for (let i = 0; i < values.certificates.length; i++)
      values['degrees_S3'].push(await getSignedUrlAndUploadS3("DOCTOR_DOCUMENT_DEGREE", values.certificates[i]));
    values['workexperience'] = radioBtn
    if (values.certificates && Object.keys(values.certificates).length > 8) {
      return
    } else {
      props.__setStep2(values)
      props.setStep(3)
    }

  };

  const removeFile = (type, index) => {
    // this function helps to delete the file from values
    setSelectedFileType(type);
    setSelectedFileIndex(index);
  };

  const index = props.specialisationsList.map((spec) => spec.label).indexOf("CLINICAL PSYCHOLOGIST")
  const idforClinicalPsychologist = props.specialisationsList[index].value

  const { values, errors, handleChange, handleSubmit } = useStepSignUpForm(
    //sends/recieve our values and errors to formvalidation.js and useform custom hooks
    formValues,
    formValidation.step2Form,
    props.step2,
    { isRciNo, idforClinicalPsychologist },

  );

  useEffect(() => {
    if (selectedFileType === 'resume') {
      delete values['resume']
      // values['resume'] = null;
      setSelectedFileType('')
    } else if (selectedFileType === 'certificates') {
      values['certificates'].splice(selectedFileIndex, 1)
      setSelectedFileType('')
    }
  }, [selectedFileType])

  useEffect(() => {
    if (values.specialization && values.specialization.length > 0) {
      values.specialization.includes(idforClinicalPsychologist) ? isRciState(true) : isRciState(false);
    } else {
      isRciState(false);
    }
    console.log(values);
  }, [values.specialization]);

  useEffect(() => {
    setRadioBtn(props.step2.workexperience)
  }, [props.step2.workexperience])

  return (
    <>
      <div id="Step-2">
        <h3 className="step-heading">
          Step 2: Enter Education Qualification and Work Experience
        </h3>
        <div className="step-form">
          <div className="form-group row">
            <label
              for="name"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Degree:
            </label>
            <div className="col-xl-4 col-lg-4 pr-lg-1 col-md-12 col-sm-12 col-12">
              <MultiSelectDropDown
                value={values.degree}
                options={props.degreeList}
                errorLanguage={errors.degree}
                handleChange={handleChange}
                name={"degree"}
                placeholder={"Select Degree"}
              />
              {errors.degree && (
                <span className="text-danger error">
                  {errors.degree}
                </span>
              )}
            </div>
            <div className="col-xl-4 col-lg-4 pl-lg-0 col-md-12 col-sm-12 col-12">
              {/* <input
                type="text"
                className="form-control"
                id="name"
                name="otherdegree"
                placeholder="Other:"
                value={values.otherdegree || ""}
                onChange={handleChange}
              /> */}
              <TagsInput value={values.otherDegrees} onChange={(newTags) => handleChange(newTags, "otherDegrees")} placeholder={'Enter Other Degrees'} />
              <small className="text-muted">Press Enter to Add Degrees</small>
            </div>
          </div>

          <div className="form-group row">
            <label
              for="name"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Specialization:
            </label>
            <div className="col-xl-4 col-lg-4 pr-lg-1 col-md-12 col-sm-12 col-12">
              <MultiSelectDropDown
                value={values.specialization}
                options={props.specialisationsList}
                errorLanguage={errors.specialization}
                handleChange={handleChange}
                name={"specialization"}
                placeholder={"Kind of Mental Health Professional"}
              />
              {errors.specialization && (
                <span className="text-danger error">
                  {errors.specialization}
                </span>
              )}
            </div>

            <div className="col-xl-4 col-lg-4 pl-lg-0 col-md-12 col-sm-12 col-12">
              {/* <input
                type="text"
                className="form-control"
                id="name"
                name="otherspecialization"
                placeholder="Other:"
                onChange={handleChange}
                value={values.otherspecialization || ""}
              /> */}
              <TagsInput value={values.otherSpecialization} onChange={(newTags) => handleChange(newTags, "otherSpecialization")} placeholder={'Enter Other Specializations'} />
              <small className="text-muted">Press Enter to Add Specializations</small>
            </div>
          </div>

          <div className={isRciNo ? "form-group row" : "d-none"}>
            <label
              for="name"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              RCI No:
            </label>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
              <input
                type="text"
                onChange={handleChange}
                className={errors.rcino ? placeholdercolor : formControl}
                placeholder={errors.rcino || "RCI No."}
                value={values.rcino || ""}
                required
                name="rcino"
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              for="name"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Work Experience:
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <CustomSingleSelect
                list={props.experienceValues}
                placeholder="(Excluding Internships)"
                radioBtn={radioBtn}
                setRadioBtn={setRadioBtn}
              />
              {Object.keys(errors).length > 0 && !radioBtn && (
                <span className="text-danger error">
                  Work Experience is required*
                </span>
              )}
            </div>
          </div>

          <div className="form-group row">
            <label
              for="degree"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Degree (Or Others):
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="custom-input-box">
                <div className="d-flex align-items-center">
                  <div className="custom-picture-choose">
                    <input
                      type="file"
                      id="for-image"
                      onChange={handleChange}
                      name="certificates"
                      accept=".pdf,.png ,.jpeg"
                      multiple={true}
                    />
                    <label for="for-image">Choose File</label>
                  </div>
                  <div>
                    <span className="file-exe">{".jpg or.png or .pdf"}</span>
                  </div>
                </div>
              </div>
              {
                values.certificates && values.certificates.length > 8 &&
                <span className="text-right d-block opaque-gray">{"max file upload is 8"}</span>
              }
              <div className="d-flex">
                <div>
                  {
                    values.certificates && values.certificates.length > 0 &&
                    Object.values(values.certificates).map((val, i) => (
                      <span key={i} className="badge badge-secondary m-0 px-2 mr-2">
                        <i className="fas fa-file mr-2"></i>{format.fileName(val.name)}
                        <i
                          className="fas fa-times ml-2"
                          onClick={() => removeFile("certificates", i)}
                        ></i>
                      </span>
                    ))}
                </div>
              </div>
              {errors.certificates && (
                <span className="text-danger error">{errors.certificates}</span>
              )}
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label
              for="resume"
              className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Resume:
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="custom-input-box">
                <div className="d-flex align-items-center">
                  <div className="custom-picture-choose">
                    <input
                      type="file"
                      id="for-resume"
                      name="resume"
                      onChange={handleChange}
                      accept=".pdf,.docx,.doc"
                    />
                    <label for="for-resume">Choose File</label>
                  </div>
                  <div className="ml-3">
                    {/* <span className="file-exe">.jpg or.png pr pdf</span> */}
                    <div className="d-flex align-items-center">
                      <div className="ml-2">
                        <span className="file-exe">{".pdf or .docx"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {values.resume && (
                <div className="d-flex">
                  <div>
                    <span className="badge badge-secondary m-0 px-2 mr-2">
                      <i className="fas fa-file mr-2"></i>
                      {format.fileName(values.resume.name)}
                      <i
                        className="fas fa-times ml-2"
                        onClick={() => removeFile("resume", 0)}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              {errors.resume && (
                <span className="text-danger error">{errors.resume}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="move-btn">
                <div className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center">
                  <a
                    href="javascript:void(0)"
                    className="disabled-step"
                    onClick={() => props.setStep(1)}
                  >
                    Previous Step
                  </a>
                  <button type="submit" onClick={handleSubmit} className="btn btn-next">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = ({ therapistSignUp }) => ({
  ...therapistSignUp
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    __setStep2
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step2));
