import React from 'react'
import PriceAndPlanSection from '../components/sub_components/PriceAndPlanSection'
import MindCareIndex from "./sub_components/selfcare/MindCareIndex"
import Testimonial from './sub_components/Testimonial'
import FooterAppSection from '../components/sub_components/FooterAppSection'
import SpinWheelSection from './sub_components/selfcare/SpinWheelSection'
import SelfCarePlans from './sub_components/selfcare/SelfCarePlans'
import SectionOne from './sub_components/selfcare/SectionOne'
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'


const SelfcareComponent = () => {
    //this is contain overall componenets of selfcare page
    return (
        <>
            <SectionOne />
            <SpinWheelSection />
            <MindCareIndex />
            <Testimonial
                testimonialSubheading="There are thousands like you, who trust us with their wellness and self-care "
                testimonialCardText={[`Love the safety and comfort of this space 
                `, `It has definitely made me more aware of my thoughts and general line of compassion for my own self.  
                `, `It's a nice approach to lighten the mood. I feel strong emotions and sometimes instant support in managing them is helpful
                `, `I have come a long way from where I was and changed so much in so little time. Thank you `,
                    ` I would like to extend my appreciation to MindPeers as a platform for making access to emotional care an easy journey.
                `]}
                TestimonialNames={["Chaitanya", "Sumidha Agarwal", "Aman Sharma", "Nitika Mehta", "Pranav Singh"]}
                points={5} />
            <SelfCarePlans />
            <FooterAppSection />
            <BackToTop />
            <Footer />
        </>
    )
}

export default SelfcareComponent;
