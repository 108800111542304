import React from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar'
import { connect } from 'react-redux';
import FoundersNote from '../../components/sub_components/FoundersNote';

const FounderNote = ({ TopBarDisplay: { openTopBar } }) => {
    return (
        <main>
            {openTopBar ? <TopBar />
                : (<>
                    <TopBar />
                    <FoundersNote />
                </>
                )
            }
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(FounderNote);