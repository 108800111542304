import React from 'react';

const FundedBy = () => {
    //this FundedBy components helps in about-us page
    return (
        <>
                <section className="fund-wrapper fund-wrapper-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="fund-heading text-center">
                                    <h3>Funded By</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="fund-listing text-center">
                                    <div className="row justify-content-center">
                                        <div className="fund-name position-relative">
                                            <img src="img/fund-listing-1.png" alt="" title=""/>
                                        </div>
                                        <div className="fund-name position-relative">
                                            <img src="img/fund-listing-2.png" alt="" title=""/>
                                        </div>
                                        <div className="fund-name position-relative">
                                            <img src="img/fund-listing-3.png" alt="" title=""/>
                                        </div>
                                        <div className="fund-name position-relative">
                                            <img src="img/fund-listing-4.png" alt="" title=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="fund-leading-text text-center">
                                    <p>Our investors have backed leading enterprise technology companies including <span>SpaceX, Stripe, Palantir, Flexport,</span> and <span>Samsara</span>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
}

export default FundedBy;
