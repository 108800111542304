import React from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar'
import SelfcareComponent from '../../components/Selfcare'
import { connect } from 'react-redux';

const Selfcare = ({ TopBarDisplay: { openTopBar } }) => {
    return (
        <main>
            {openTopBar ? <TopBar />
                : (<>
                    <TopBar />
                    <SelfcareComponent />
                </>
                )
            }
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(Selfcare);
