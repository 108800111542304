import React from 'react'
import BlogContent from './sub_components/community/blog/BlogContent'
import BlogFooter from './sub_components/community/blog/BlogFooter'
import LeftBar from './sub_components/community/blog/LeftBar'
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'
const BlogComponent = () => {
    //this is contain overall componenets of Blog page which comes under community page eg -> community/blog
    return (
        <>
            <BlogContent/>
            <BlogFooter/>
            <BackToTop/>
            <Footer />
        </>
    )
}

export default BlogComponent;
