import BoatService from "../../api/services/BoatService";

export default {
  getBoatById: (id) => async (dispatch) => {
    const response = await BoatService.getById(id);
    if (response.success) {
      dispatch({ type: "SET_BOAT_BY_ID_DATA", payload: response.data });
      dispatch({ type: "RESET_ERROR" });
    } else {
      dispatch({ type: "SET_ERROR", payload: response.data });
    }
  },

  getAllBoatWithFilter: (filters) => async (dispatch) => {
    const response = await BoatService.listAll(filters);
    if (response.success) {
      dispatch({ type: "SET_BOAT_LIST_DATA", payload: response.data });
      dispatch({ type: "RESET_ERROR" });
    } else {
      dispatch({ type: "SET_ERROR", payload: response.data });
    }
  },
}