const commonState = {
    error: {},
    phonePrefixList : []
  };
  
  export default (state = commonState, action) => {
    switch (action.type) {
      case "SET_ERROR":
        return {
          ...state,
          error: action.payload,
        };
      case "RESET_ERROR":
        return {
          ...state,
          error: {},
        };
        break;
      case 'SET_COUNTRY_CODE_LIST' : 
        return {
          ...state,
          phonePrefixList : action.payload
        }
      default:
        return {
          ...state,
        };
    }
  };
  