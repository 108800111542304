import TherapyService from "../../api/services/TherapyService"
import { formatgiftPlans } from "../../utils/formatData"
export default {
    fetchTherapists: (home) => async (dispatch) => {
        const response = await TherapyService.listDoctors(home)
        if (home)
            dispatch({
                type: "LIST_THERAPISTS_HOME",
                payload: response
            })
        else
            dispatch({
                type: "LIST_THERAPISTS",
                payload: response
            })
    },
    fetchTherapist: (id) => async (dispatch) => {
        const response = await TherapyService.getDoctor(id)
        dispatch({
            type: "GET_THERAPIST",
            payload: response
        })
    },
    fetchTherapistReviews: (id) => async (dispatch) => {
        const response = await TherapyService.getTherapistReviews(id)
        dispatch({
            type: "GET_THERAPIST_REVIEWS",
            payload: response
        })
    },
    fetchSimilarTherapist: (id) => async (dispatch) => {
        const response = await TherapyService.getSimilarDoctors(id)
        dispatch({
            type: "GET_SIMILAR_THERAPISTS",
            payload: response
        })
    },
    clearTherapist: () => async (dispatch) => {
        dispatch({
            type: "CLEAR_THERAPIST",
        })
    },
    setFilterText: (filterText) => (dispatch) => {
        dispatch({
            type: "SET_FILTER_TEXT",
            payload: filterText
        })
    },
    setTherapyGiftPlans: () => async (dispatch) => {
        const response = await TherapyService.listPlansByType()
        dispatch({
            type: "SET_THERAPY_GIFT_PLANS",
            //payload: formatgiftPlans(response)
            payload: response
        })
    }
}