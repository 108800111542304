import React from 'react';
import FundedBy from './sub_components/aboutus/FundedBy';
import HeaderSection from './sub_components/aboutus/HeaderSection';
import OurInvestors from './sub_components/aboutus/OurInvestors';
import OurTeam from './sub_components/aboutus/OurTeam';
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'

const AboutUsComponenet = () => {
     //this is contain overall componenets of AboutUs page
     //Our Mission component included in Header section itself
    return (
        <>
            <HeaderSection/>
            <OurTeam/>
            <FundedBy/>
            <OurInvestors/>
            <BackToTop/>
            <Footer />
        </>
    );
}

export default AboutUsComponenet;
