import React, { useState } from 'react'
import useForm from '../../../utils/useForm';
import formValidation from '../../../utils/formValidation';
import CommonService from "../../../api/services/CommonService"
import CommonModal from "../../common/modalfolder/CommonModal"
import CustomToast from "../../../components/common/Toast/CustomToast"
import { ToastConstant } from '../../../default_config/toastConstant'
const SelfCarePlans = () => {
    const placeholdercolor = "form-control span-error is-danger";
    const formControl = "form-control";
    // const [selectedPlan, changeSelectedPlan] = useState(0)

    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })

    const formValues = () => {
        CommonService.contactus(values).then((res) => {
            if (res) {
                setShowModel(true); changemodelValue(!modelValue);
            } else {
                setOpen(true)
                setToastData(ToastConstant.API_ERROR)
            }
        })
    }
    const { values, errors, handleChange, handleSubmit } = useForm(
        formValues,
        formValidation.selfcareForm,
        {},
    );
    return (
        <section id="selfcare-plans">

            <div className="container py-5">
                <p className="sub-lead">Take Care Of your Mind With Us</p>
                <h3 className="sub-lead-title">Be the first one to get access to your personalized SelfCare Journey</h3>

                <div className="waiting-list-box">
                    <div className="row align-items-center no-gutters">
                        <div className="col-md-2">
                            <div className="heading">Name:</div>
                        </div>
                        <div className="col-md-10">
                            <input type="text" onChange={handleChange} className={errors.name ? placeholdercolor : formControl} name="name" id="" placeholder={errors.name || "Name"} value={values.name || ''} />
                        </div>
                    </div>
                    <div className="row align-items-center no-gutters">
                        <div className="col-md-2"><div className="heading">Email:</div></div>
                        <div className="col-md-10">
                            <input type="text" onChange={handleChange} className={errors.email ? placeholdercolor : formControl} name="email" id="" placeholder={errors.email || "Email"} value={values.email || ''} />
                        </div>
                        {errors.email == "Email address is invalid *" && <p className="text-danger pt-2">{errors.email}</p>}
                    </div>
                    <div className="row no-gutters">
                        <div className="col-md-6">
                            <div className="heading">SelfCare matters to me because:</div>
                        </div>
                        <div className="col-md-6">
                            <input type="text" onChange={handleChange} className={`line-input ${errors.message ? "line-input-error" : ""}`} placeholder={errors.message ? errors.message : "Taking care of myself is very Important"} name="message" value={values.message || ''} />
                        </div>
                    </div>
                    <button onClick={handleSubmit}>GRAB A SPOT</button>
                </div>


                {/* <div className={`plan-cards`} >
                    <div className={`popular-card ${selectedPlan == 0 ? "selected-selfcare-plan" : ""} `} onClick={() => changeSelectedPlan(0)}>
                        <div className="recommened-box">
                            <b>Most Popular</b></div>
                        <div className="discount-box">60% off</div>
                        <div className="plan-heading">Annual - First 14 days free</div>
                        <div className="plan-price">
                            <span className="actual-price">₹990</span>
                            <span className="discounted-price">₹180* / mo</span>
                        </div>
                        <div className="plan-sub-title">Total ₹2160 INR, billed annually</div>
                        <div className="plan-end">
                            <div className="plan-end-heading">Includes</div>
                            <div className="include-point">
                                <div className="point"></div>
                                <span>All features of SelfCare for an year </span>
                            </div>

                            <div className="include-point">
                                <div className="point"></div>
                                <span>10% off on all Therapy sessions </span>
                            </div>

                            <div className="include-point">
                                <div className="point"></div>
                                <span>10% off on all Boats sessions </span>
                            </div>
                        </div>
                    </div>
                    <div className={`other-card ${selectedPlan == 1 ? "selected-selfcare-plan" : ""}`} onClick={() => changeSelectedPlan(1)}>
                        <div className="discount-box">40% off</div>
                        <div className="plan-heading">Monthly - First 7 days free</div>
                        <div className="plan-price">
                            <span className="actual-price">₹1100</span>
                            <span className="discounted-price">₹200* / mo</span>
                        </div>
                        <div className="plan-sub-title">billed monthly</div>
                        <div className="plan-end">
                            <div className="plan-end-heading">Includes</div>
                            <div className="include-point">
                                <div className="point"></div>
                                <span>All features of SelfCare for an year </span>
                            </div>

                            <div className="include-point">
                                <div className="point"></div>
                                <span>5% off on all Therapy sessions </span>
                            </div>

                            <div className="include-point">
                                <div className="point"></div>
                                <span>5% off on all Boats sessions </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5">
                    <button type="button" className="btn free-trail-button" onClick={() => window.open(process.env.REACT_APP_DASHBOARD_URL, "_blank")}>START YOUR FREE TRIAL</button>
                    <div className="conditions">
                        <span>After your free trial, annual subscription is ₹2160 INR*.</span>
                        <br />
                        <span>Subject to Terms & conditions of MindPeers</span>
                    </div>
                </div> */}
            </div>
            <CommonModal modelValue={modelValue} showModel={showModel} formData={values} errorMessage={errors} />
            {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }
        </section >
    )
}

export default SelfCarePlans
