import React, { useState } from 'react'

const CustomSingleSelect = ({list,radioBtn,setRadioBtn,placeholder}) => {
    // this select dropdown which is single select we can use it in Therapisit sign up form page 
    const [openDropDown, setOpenDropDown] = useState(false)
    
    return (
        <div className="custom-select-container" onClick={() => setOpenDropDown(true)} onMouseLeave={() => setOpenDropDown(false)}>
            <span>{radioBtn || placeholder}</span>
            <div className="drop_down_arrow position-absolute"></div>
            <div onMouseEnter={() => setOpenDropDown(true)} onMouseLeave={() => setOpenDropDown(false)} className={openDropDown ? "drop-down-container open" : 'drop-down-container'}>
                <div className="nano-scroll-bar">
                    <ul className="list-unstyled mb-0">
                        {
                            list && list.map((item,i) => (
                                <li key={i} className={radioBtn === item ? "active" : ""}>
                                    <a>
                                        <div className="custom_select">
                                            <input type="radio" id={"index-"+i} name={item} value={item} checked={radioBtn === item ? true : false} onChange={() => setRadioBtn(item)} />
                                            <label for={"index-"+i}>{item}</label>
                                        </div>
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CustomSingleSelect
