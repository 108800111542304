import React, { Fragment, useState } from 'react';
import { NavLink, Link, useLocation, useHistory } from 'react-router-dom';
import FormPageModel from "../modalfolder/FormPageModal";
import $ from 'jquery'
import { getRedirectionUrl } from '../../../utils/_helper';

const DesktopTopBar = ({ teamCare }) => {
    const location = useLocation();
    const history = useHistory();
    if (location.pathname !== '/sign-up') {
        window.localStorage.setItem("routeName", location.pathname);
    }

    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const submitTopBarBtn = () => {
        if (teamCare) {
            changemodelValue(!modelValue);
            setShowModel(true);
        } else {
            window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")
        }
    }

    return (
        <Fragment>
            <header className={window.location.pathname === '/community/blog/id' ? "main-header w-100 sticky-on-med" : "position-absolute main-header w-100 sticky-on-med"}>
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link className="navbar-brand" to={getRedirectionUrl("/")}>
                            <img src="/img/mplogo.png" className="logo" alt="" title="" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#MainMenuContent" aria-controls="MainMenuContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <i className="fas fa-bars"></i>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="MainMenuContent">
                            <ul className="navbar-nav mr-auto main-menu mt-1">
                                <li className="nav-item"><NavLink exact to={getRedirectionUrl("/selfcare")} className="nav-link" activeClassName="nav-link active">SelfCare</NavLink></li>
                                <li className="nav-item therapy"><NavLink to={getRedirectionUrl('/therapy')} className="nav-link" activeClassName="nav-link active">Therapy</NavLink></li>
                                <li className="nav-item"><NavLink exact to={getRedirectionUrl('/organisation')} className="nav-link" activeClassName="nav-link active">TeamCare</NavLink></li>
                                <li className="nav-item"><NavLink exact to={getRedirectionUrl('/community')} className="nav-link" activeClassName="nav-link active">Boats</NavLink></li>
                                <li className="nav-item"><NavLink exact to={getRedirectionUrl('/vent-out')} className="nav-link" activeClassName="nav-link active">Vent Out</NavLink></li>
                                <li className="nav-item">
                                    <a className="nav-link" activeClassName="nav-link active" target="_blank" rel="noreferrer" href={getRedirectionUrl("https://blog.mindpeers.co","utm_source=website")}>Blogs</a>
                                </li>
                            </ul>
                            <ul className="navbar-nav main-menu mind-coach-main align-items-center mt-1">
                                <li className="nav-item mr-3">
                                    <NavLink to={getRedirectionUrl('/therapist-sign-up')} className="nav-link single-level-menu join-as-mind-coach" activeClassName="nav-link single-level-menu join-as-mind-coach active">JOIN AS A MINDCOACH</NavLink>
                                </li>
                                <button className="btn btn-get-access my-2" type="submit" onClick={submitTopBarBtn}>
                                    {teamCare ? "GET ACCESS" :
                                        <>
                                            < img src="/img/topbarIcon.svg" />
                                            {" "} SIGN UP/ SIGN IN
                                        </>
                                    }
                                </button>
                            </ul>
                        </div>
                        <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="Get Access" />
                    </div>
                </nav>
            </header>

        </Fragment>
    )
}

export default DesktopTopBar;