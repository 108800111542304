import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const StaticModal = (props) => {
    //this modal works on Calculate Your Workplace Index section in organisation page

    //setshow helps to toggle hide/show modal based on form validation errors 
    const [show, setShow] = useState(false);

    //this closes the modal of popup
    const handleClose = () => {
        if (props.openTermOfUsePopUp)
            props.setOpenTermOfUsePopUp(false)
        if (props.openPrivacyPolicyPopUp)
            props.setOpenPrivacyPolicyPopUp(false)
        setShow(false)
    };

    useEffect(() => {
        if (props.openTermOfUsePopUp) // to ensure when button is clicked model is opened
            setShow(true);
        if (props.openPrivacyPolicyPopUp) // to ensure when button is clicked model is opened
            setShow(true);
    }, [props]);


    return (
        <>
            {/* <button type="submit" className="btn btn-calculate-now">Calculate Now</button> */}

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="modal-90w"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.popUpHeading}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.modalBody}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
            </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StaticModal;