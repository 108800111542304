import { useState, useEffect } from 'react';

const useGetMatchForm = (callback, validate,intialState={}, condition = {}) => {

    const [values, setValues] = useState(intialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        console.log("error",errors)
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    useEffect(()=>{
        if(values.gender !== 'others') { delete values['othergender'] }
        if(values.therapistGender !== 'others') { delete values['othertherapistGender'] }
        
    },[values,condition])  

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        console.log(values);
        setErrors(validate(values,condition));
        setIsSubmitting(true);
    };
    const deleteError = (name) => {
        Object.keys(errors).map(err => {
            if (err === name) {
                delete errors[err]
            }
        })
    }

    const handleChange = (event,targetName) => {
        console.log(event);

        // console.log(event.target.name);

        if(!targetName){
            deleteError(event.target.name)
            setValues(values => ({...values, [event.target.name]: event.target.value }));
        } else{
            deleteError(targetName)
            setValues(values => ({...values, [targetName]: event}));
            console.log("from use hook",targetName);
        }
    };



    return {
        handleChange,
        handleSubmit,
        values,
        errors,
    }
};

export default useGetMatchForm;