import { useState, useEffect } from 'react';

const useStepSignUpForm = (callback, validate, intialState = {}, condition = {}) => {

    const [values, setValues] = useState(intialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        console.log("error", errors)
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback();
        }
    }, [errors]);

    useEffect(() => {
        setValues(intialState)
    }, [intialState])

    useEffect(() => {
        if (values.gender !== 'others') { delete values['othergender'] }
        if (values.radio_own_space !== 'yes') { delete values['address'] }
        if (values.specialization && values.specialization.length > 0 && !values.specialization.includes(condition.idforClinicalPsychologist)) { delete values['rcino'] }

    }, [values, condition])

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
        setIsSubmitting(true);
        setErrors(await validate(values, condition));
    };

    const deleteError = (name) => {
        Object.keys(errors).map(err => {
            if (err === name) {
                delete errors[err]
            }
        })
    }

    const handleChange = (event, targetName) => {
        if (!targetName) {
            event.persist();
            console.log(event.target.files);

            deleteError(event.target.name)

            if (event.target.type === 'file') {
                if (event.target.name === 'certificates') {
                    setValues(values => ({ ...values, [event.target.name]: Array.from(event.target.files) }));
                }
                else {
                    setValues(values => ({ ...values, [event.target.name]: event.target.files[0] }));
                    console.log("resume", values);
                }
            }
            else {
                setValues(values => ({ ...values, [event.target.name]: event.target.value }));
            }
        } else {
            deleteError(targetName)
            setValues(values => ({ ...values, [targetName]: event }));
            // console.log("from use hook",values);
        }



    };



    return {
        handleChange,
        handleSubmit,
        values,
        errors,
    }
};

export default useStepSignUpForm;