import {
    MultiSelectOptionMarkup,
  } from "react-responsive-select";
const states = [
      // {
      //    value: 'AN', label: 'Andaman and Nicobar Islands',
      // },
      // {
      //    value: 'AP', label: 'Andhra Pradesh',
      // },
      "Andaman and Nicobar Islands",
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chandigarh",
      "Chhattisgarh",
      "Dadra and Nagar Haveli",
      "Daman and Diu",
      "Delhi",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jammu and Kashmir",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Ladakh",
      "Lakshadweep",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Puducherry",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal"
    ]
    // AN:"Andaman and Nicobar Islands",
    // AP:"Andhra Pradesh",
    // AR:"Arunachal Pradesh",
    // AS:"Assam",
    // BR:"Bihar",
    // CG:"Chandigarh",
    // CH:"Chhattisgarh",
    // DN:"Dadra and Nagar Haveli",
    // DD:"Daman and Diu",
    // DL:"Delhi",
    // GA:"Goa",
    // GJ:"Gujarat",
    // HR:"Haryana",
    // HP:"Himachal Pradesh",
    // JK:"Jammu and Kashmir",
    // JH:"Jharkhand",
    // KA:"Karnataka",
    // KL:"Kerala",
    // LA:"Ladakh",
    // LD:"Lakshadweep",
    // MP:"Madhya Pradesh",
    // MH:"Maharashtra",
    // MN:"Manipur",
    // ML:"Meghalaya",
    // MZ:"Mizoram",
    // NL:"Nagaland",
    // OR:"Odisha",
    // PY:"Puducherry",
    // PB:"Punjab",
    // RJ:"Rajasthan",
    // SK:"Sikkim",
    // TN:"Tamil Nadu",
    // TS:"Telangana",
    // TR:"Tripura",
    // UP:"Uttar Pradesh",
    // UK:"Uttarakhand",
    // WB:"West Bengal"
// };

const langauges = [
    {
      value: "ENGLISH",
      label: "ENGLISH",
    },
    {
      value: "HINDI",
      label: "HINDI",
    },
    {
      value: "BENGALI",
      label: "BENGALI",
    },
    {
        value: "TELUGU",
        label: "TELUGU",
    },
    {
        value: "MARATHI",
        label: "MARATHI",
     },
     {
        value: "TAMIL",
        label: "TAMIL",
     },
     {
        value: "URDU",
        label: "URDU",
     },
     {
        value: "KANNADA",
        label: "KANNADA",
     },
     {
        value: "GURJARATI",
        label: "GURJARATI",
     },
     {
        value: "ODIA",
        label: "ODIA",
     },
     {
        value: "PUNJABI",
        label: "PUNJABI",
     },
     {
        value: "MALAYALAM",
        label: "MALAYALAM",
     },
     {
        value: "MIZO",
        label: "MIZO",
     },
     {
        value: "KONKANI",
        label: "KONKANI",
     },
     {
        value: "ASSAMESE",
        label: "ASSAMESE",
     },
     {
        value: "OTHERS",
        label: "OTHERS",
     }, 
  ]
// const langauges ={
//     "ENG":"ENGLISH",
//     "HIN":"HINDI",
//     "BEN":"BENGALI",
//     "TEL":"TELUGU",
//     "MAR":"MARATHI",
//     "TAM":"TAMIL",
//     "URD":"URDU",
//     "KAN":"KANNADA",
//     "GUR":"GURJARATI",
//     "ODI":"ODIA",
//     "PUN":"PUNJABI",
//     "MAL":"MALAYALAM",
//     "MIZ":"MIZO",
//     "KON":"KONKANI",
//     "ASA":"ASSAMESE",
//     "OTH":"OTHERS",
// }

const degree = [
   {
     value: "MA",
     text: "MA",
     markup: <MultiSelectOptionMarkup text="MA" />,
   },
   {
     value: "MSc",
     text: "MSc",
     markup: <MultiSelectOptionMarkup text="MSc" />,
   },
   {
     value: "M.Phil",
     text: "M.Phil",
     markup: <MultiSelectOptionMarkup text="M.Phil" />,
   },
   {
       value: "BA",
       text: "BA",
       markup: <MultiSelectOptionMarkup text="BA" />,
   },
   {
      value: "MD",
      text: "MD",
      markup: <MultiSelectOptionMarkup text="MD" />,
  },
   {
      value: "PhD",
      text: "PhD",
      markup: <MultiSelectOptionMarkup text="PhD" />,
   },
   {
      value: "PhD Scholar",
      text: "PhD Scholar",
      markup: <MultiSelectOptionMarkup text="PhD Scholar" />,
   },
   {
      value: "Others",
      text: "Others",
      markup: <MultiSelectOptionMarkup text="Others" />,
   },
 ]
 const specialization = [

   {
     value: "Psychotherapist",
     text: "Psychotherapist",
     markup: <MultiSelectOptionMarkup text="Psychotherapist" />,
   },
   {
       value: "Life Coach",
       text: "Life Coach",
       markup: <MultiSelectOptionMarkup text="Life Coach" />,
   },
   {
      value: "Performance Coach",
      text: "Performance Coach",
      markup: <MultiSelectOptionMarkup text="Performance Coach" />,
  },
   {
      value: "Expressive Arts Therapist",
      text: "Expressive Arts Therapist",
      markup: <MultiSelectOptionMarkup text="Expressive Arts Therapist" />,
   },
   {
      value: "Psychiatrist",
      text: "Psychiatrist",
      markup: <MultiSelectOptionMarkup text="Psychiatrist" />,
   },
   {
      value: "Career Counsellor",
      text: "Career Counsellor",
      markup: <MultiSelectOptionMarkup text="Career Counsellor" />,
   },
   {
      value: "Clinical Psychologist",
      text: "Clinical Psychologist",
      markup: <MultiSelectOptionMarkup text="Clinical Psychologist" />,
    },
    {
      value: "Counselling Psychologist",
      text: "Counselling Psychologist",
      markup: <MultiSelectOptionMarkup text="Counselling Psychologist" />,
    },
    {
      value: "Others",
      text: "Others",
      markup: <MultiSelectOptionMarkup text="Others" />,
   },
 ]
 const workexperience =  [     
            "0 Years",
            "1-2 Years",
            "3-5 Years",
            "6-10 Years",
            "10-15 Years",
            "15+ Years",
         ]


export default {
    states,
    degree,
    specialization,
    workexperience,
    langauges
}