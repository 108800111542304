import React, { useState } from 'react';
import StarRating from "./StarRating"
import { useHistory, useLocation } from 'react-router-dom';
import { getRedirectionUrl } from '../../../../utils/_helper';
import { writeTherapistProfileString, convertRating, showTherapistSpeciality } from "../../../../utils/formatData"
import { convertIsoDate } from "../../../../utils/formatData"
const TherapisitCard = ({ _item: { user: { name, id, rating , profile}, pronouns, schedule, specialisations, degrees } }) => {
    //this componenets helps us in Therapy page in Therpists details card section
    // we are using infinite scroll npm library so we can get onscroll load the data
    //for more info on infinite scroll  https://www.npmjs.com/package/react-infinite-scroll-component

    const userName = name.length > 14 ? name.slice(0, name.indexOf(' ') + 2) : name
    const history = useHistory();
    const location = useLocation();
    const handleProfile = () => {
        history.push({
            pathname: `/therapy/meet-our-experts/profile/${id}`,
            search: `${location.search}`
        })
    }

    return (
        <>
            <div className='therapist-list'>
                <div className="therapist-review-box">
                    <StarRating stars={convertRating(rating)} />
                    <div className="therapist-review-rating">({rating})</div>
                </div>
                <div className="therapist-details">
                    <div className="therapist-info">
                        <div className="therapist-name">{name}</div>
                        <p className="ot-gender">({pronouns})</p>
                        <div className="therapist-title">
                            <h4 className="therapist-title">{showTherapistSpeciality(specialisations)}</h4>
                            <div className="therapist-qualification">
                                <span className="therapist-qualification">Qualification</span>
                                <span className="thrapist-qualification-content">: {writeTherapistProfileString(degrees)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="therapist-img">
                        <img className="therapist-img" src={`${profile && profile !== "undefined" ? profile : "/img/cardimg.svg"}`} alt="card-img" />
                    </div>
                </div>
                <div className="next-available">
                    {schedule ? `Next Available on : ${convertIsoDate(schedule)}` : "No Slots Available"}
                </div>
                <div className="therapist-booking-details">
                    <button 
                        type="button" onClick={() => handleProfile()} 
                        className="therapist-know-more-btn">Know more
                    </button>
                    {schedule ? 
                        <button type='button' className='btn therapist-btn therapist-book-now-btn' onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/book-therapist/${id}`)}`), "_blank")}>Book Now</button> 
                        : <button type='button' className='btn therapist-btn therapist-book-now-btn therapist-closed-btn' disabled>Book Now</button>
                    }
                </div>
            </div>
        </>
    );
}

export default TherapisitCard;
