import { combineReducers } from 'redux';
import therapistSignUp from './therapistSignUp.reducer'
import matchTherapyFormData from './matchTherapy.reducer'
import TopBarDisplay from './topBarDisplay.reducer'
import fetchTherapists from "./fetchTherapist.reducer"
import ventOut from "./ventout.reducer"
import boatData from "./boatReducer"
import commonState from './commonReducer'

export default combineReducers({
    therapistSignUp,
    matchTherapyFormData,
    TopBarDisplay,
    fetchTherapists,
    ventOut,
    boatData,
    commonState
});
