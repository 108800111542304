import React from 'react';
// import ReportFooter from '../components/sub_components/ReportFooter';

const TermsOfUse = () => {
  return (
    <main>
      <div className="container">
        <article id="termsOfUse">
          <h1 className="hr-below">
            Terms and Conditions
            {/* <span className="float-right small">
              <small>Private &amp; Confidential</small>
            </span> */}
          </h1>
          {/* <hr className="light" /> */}
          <p>
            Please read these Terms carefully before you start to use the
            Website. By using the Website, you indicated that you accept these
            Terms and our Privacy Policy that you agree to abide by them. If you
            do not agree to these Terms, please refrain from using the Website.
          </p>
          <p>
            In order to become a User (<strong>"User"</strong> or{' '}
            <strong>"you"</strong>) of the Website and to enjoy the services
            offered and features available on the Website (
            <strong>"MindPeers Services"</strong>), you must read and accept all
            these Terms and the Privacy Policy. By browsing, accessing or using
            the Website or by using any facilities or services made available
            through it or by transacting through or on it, you are agreeing to
            these Terms. Nothing in these Terms shall be deemed to confer any
            third-party rights or benefits.
          </p>
          <p>
            We may modify these Terms from time to time without notice to
            reflect changes in market conditions affecting our business, changes
            in technology, changes in relevant laws and regulatory requirements
            and changes in our service offering. Such modification shall be
            effective upon posting by us on the Website. You agree to be bound
            by any changes to these Terms when you use the MindPeers Services
            after any such modification is posted on the Website. It is
            therefore important that you review these Terms regularly to ensure
            you are updated as to any changes.
          </p>
          <h2>1. USE OF THE WEBSITE</h2>
          <ul className="abc">
            <li>
              Your use of the Website is governed by these Terms. We may at our
              absolute discretion refuse you access to the Website.
            </li>
            <li>You must be 18 years of age or over to use the Website.</li>
            <li>
              Use of the Website requires a connection to the Internet and
              appropriate telecommunication infrastructure. We are not liable
              for any costs you may incur from use of such.
            </li>
            <li>
              The Website may not be used in connection with any commercial
              endeavours except those that are specifically endorsed or approved
              by MindPeers. Illegal and/or unauthorised use of the Website is
              prohibited. Appropriate legal action will be taken against you for
              any illegal or unauthorised use by you of the Website.
            </li>
            <li>
              We disclaim any and all responsibility in relation to any
              MindPeers Services offered or made available on the Website to the
              maximum extent permitted by law. Neither MindPeers nor its
              officers, employees or agents may be held liable whether in
              relation to contract, warranty, tort (including negligence),
              product liability, tax matters or any other form of liability for
              any claim, damage or loss, arising from or relating to the
              provision of MindPeers Services accessed or agreed to be supplied
              in connection with the Website.
            </li>
          </ul>
          <h2>2. USER CONTENT GUIDELINES</h2>
          <ul className="abc">
            <li>
              You are solely responsible for your conduct and any data that you
              submit, post or display on or via the Website. MindPeers shall
              have no liability for conduct in relation to your use of our
              Website. Violations of these Terms may result in legal
              consequences prescribed by the azpplicable laws.
            </li>
            <li>
              The Website provides Users the facility to create, share and post
              content (together, "User Content"). MindPeers claims no ownership
              rights over User Content created by you. MindPeers has the right
              (but not the obligation) in its sole discretion to remove any User
              Content that is stored via the Website.
            </li>
            <li>
              MindPeers takes no responsibility and assumes no liability for any
              User Content that you or any other User or third party creates,
              stores, shares, posts or sends through the Website. You shall be
              solely responsible for your User Content and the consequences of
              posting, publishing it, or sharing it and you agree that we are
              only acting as a passive conduit for your online distribution and
              publication of your User Content. If your Content violates these
              Terms, you may bear legal responsibility for that content.
            </li>
            <li>
              You agree not to post, store, transmit, create or share any User
              Content that: (i) may create a risk of harm, loss, physical or
              mental injury, emotional distress, death, disability,
              disfigurement, or physical or mental illness to you, to any other
              person, or to any animal; (ii) may create a risk of any other loss
              or damage to any person or property; (iii) seeks to harm or
              exploit children by exposing them to inappropriate content, asking
              for personally identifiable details or otherwise; (iv) may
              constitute or contribute to a crime or tort; (v) contains any
              information or content that we deem to be unlawful, harmful,
              abusive, racially or ethnically offensive, defamatory, infringing,
              invasive of personal privacy or publicity rights, harassing,
              humiliating to other people (publicly or otherwise), libelous,
              threatening, profane, or otherwise objectionable; (vi) contains
              any information or content that is illegal (including, without
              limitation, the disclosure of insider information under securities
              law or of another party’s trade secrets); or (vii) contains any
              information or content that you do not have a right to make
              available under any law or under contractual or fiduciary
              relationships. You agree that any User Content that you create,
              store, or share does not and will not violate third-party rights
              of any kind, including without limitation any Intellectual
              Property Rights (as defined below) or rights of privacy. MindPeers
              reserves the right, but is not obligated, to reject and/or remove
              any User Content that MindPeers believes, in its sole discretion,
              violates these provisions. For the purposes of these Terms,
              “Intellectual Property Rights” means all patent rights, copyright
              rights, mask work rights, moral rights, rights of publicity,
              trademark, trade dress and service mark rights, goodwill, trade
              secret rights and other intellectual property rights as may now
              exist or hereafter come into existence, and all Website therefore
              and registrations, renewals and extensions thereof, under the laws
              of any state, country, territory or other jurisdiction.
            </li>
            <li>
              In connection with your User Content, you affirm, represent and
              warrant the following:{' '}
            </li>
            <ul className="abc1">
              <li>
                Your User Content and your use thereof as contemplated by these
                Terms and the Website will not violate any law or infringe any
                rights of any third party, including but not limited to any
                Intellectual Property Rights or privacy rights.
              </li>
              <li>
                To the extent that you use the Website for any commercial
                purpose and your User Content contains any copyrighted material
                that is not your original work or in which you do not otherwise
                own the copyright, you have obtained all rights, licenses,
                consents, and permissions necessary in order to use that
                copyrighted material in connection with the creation and/or
                dissemination of that User Content using the Website.
              </li>
              <li>
                MindPeers takes no responsibility and assumes no liability for
                any User Content that you or any other User or third party
                creates, stores, shares, posts, sends or otherwise makes
                available through the Website, whether directly or indirectly.
                You shall be solely responsible for your User Content and the
                consequences of posting, publishing it, or sharing it and you
                agree that MindPeers is only acting a passive conduit for the
                distribution and publication of your User Content. If your
                Content violates these Terms, you may bear legal responsibility
                for that content.
              </li>
            </ul>
          </ul>
          <h2>3. CONTENT</h2>
          <ul className="abc">
            <li>
              Other than personally identifiable information (otherwise referred
              to as "personal data" under applicable data protection
              legislation), which is covered under our Privacy Policy, any
              material you transmit or submit to the Website shall be considered
              non-confidential and non-proprietary.
            </li>
            <li>
              You understand that by supplying information via the submission of
              enquiries that you may have on the Website or otherwise, you are
              granting us an irrevocable non-exclusive license to use such
              information for any purpose in the course of offering our
              services.
            </li>
            <li>
              You are permitted to print and download limited extracts from the
              Website for your own personal use provided that:{' '}
            </li>
            <ul className="abc1">
              <li>
                MindPeers's copyright and trade mark notices appear in all
                copies. Our status (and that of any identifying contributors) as
                the authors of any material on the Website must always be
                acknowledged.
              </li>
              <li>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded in any way, and you must not
                use any illustrations, photographs, video or audio sequences or
                any graphics separately from any accompanying text.
              </li>
              <li>
                You must not use any part of the materials on the Website for
                commercial purposes without obtaining prior written consent to
                do so from us or our licensors.
              </li>
            </ul>
            <li>
              If you print off, copy or download any part of the Website in
              breach of these Terms, your right to use the Website will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made.
            </li>
            <li>
              MindPeers reserves the right to remove any User Content from the
              Website for any reason, without prior notice. User Content removed
              from the Website may continue to be stored by MindPeers,
              including, without limitation, in order to comply with certain
              legal obligations, but may not be retrievable without a valid
              court order. MindPeers will not be liable to you for any
              modification, suspension, or discontinuation of the Website, or
              the loss of any User Content.
            </li>
          </ul>
          <h2>4. SUBSCRIPTION</h2>
          <ul className="abc">
            <li>
              Auto-renewable subscriptions are available from the App Store for MindPeers Club
            </li>
            <li>
              Payment will be charged to your iTunes account at confirmation of purchase and
              will automatically renew (at the duration/price selected) unless auto-renew is turned off at least 24 hrs before the end of the current period.
            </li>
            <li>
              Account will be charged for renewal within 24-hours prior to the end of the current period.
            </li>
            <ul className="abc1">
              <li>
                Current subscription may not be cancelled during the active subscription period; however,
                you can manage your subscription and/or turn off auto-renewal by visiting your iTunes Account Settings after purchase.
              </li>
              <li>
                Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable
              </li>
              <li>
                You must not use any part of the materials on the Website for
                commercial purposes without obtaining prior written consent to
                do so from us or our licensors.
              </li>
            </ul>
            <li>
              If you print off, copy or download any part of the Website in
              breach of these Terms, your right to use the Website will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made.
            </li>
            <li>
              MindPeers reserves the right to remove any User Content from the
              Website for any reason, without prior notice. User Content removed
              from the Website may continue to be stored by MindPeers,
              including, without limitation, in order to comply with certain
              legal obligations, but may not be retrievable without a valid
              court order. MindPeers will not be liable to you for any
              modification, suspension, or discontinuation of the Website, or
              the loss of any User Content.
            </li>
          </ul>
          <h2>5. END USEER LICENSE AGREEMENT</h2>
          <ul className="abc">
            <li>
              Apps made available through the App Store are licensed, not sold, to you. Your license to each App is subject to your prior acceptance of either this Licensed Application End User License Agreement (“Standard EULA”),
              or a custom end user license agreement between you and the Application Provider (“Custom EULA”), if one is provided.
              Your license to any Apple App under this Standard EULA or Custom EULA is granted by Apple, and your license to any Third Party App under this Standard EULA or Custom EULA is granted by the Application Provider of that Third Party App.
              Any App that is subject to this Standard EULA is referred to herein as the “Licensed Application.”
              The Application Provider or Apple as applicable (“Licensor”) reserves all rights in and to the Licensed Application not expressly granted to you under this Standard EULA.
            </li>
            <li>
              a. Scope of License: Licensor grants to you a nontransferable license to use the Licensed Application on any Apple-branded products that you own or control and as permitted by the Usage Rules.
              The terms of this Standard EULA will govern any content, materials, or services accessible from or purchased within the Licensed Application as well as upgrades provided by Licensor that replace or supplement the original Licensed Application,
              unless such upgrade is accompanied by a Custom EULA. Except as provided in the Usage Rules, you may not distribute or make the Licensed Application available over a network where it could be used by multiple devices at the same time.
              You may not transfer, redistribute or sublicense the Licensed Application and, if you sell your Apple Device to a third party, you must remove the Licensed Application from the Apple Device before doing so.
              You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed Application, any updates,
              or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Licensed Application).
            </li>
            <li>
              b. Consent to Use of Data: You agree that Licensor may collect and use technical data and related information—including but not limited to technical information about your device,
              system and application software, and peripherals—that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Licensed Application.
              Licensor may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.
            </li>
            <li>
              c. Termination. This Standard EULA is effective until terminated by you or Licensor. Your rights under this Standard EULA will terminate automatically if you fail to comply with any of its terms.
            </li>
            <li>
              d. External Services. The Licensed Application may enable access to Licensor’s and/or third-party services and websites (collectively and individually, "External Services").
              You agree to use the External Services at your sole risk. Licensor is not responsible for examining or evaluating the content or accuracy of any third-party External Services, and shall not be liable for any such third-party External Services.
              Data displayed by any Licensed Application or External Service, including but not limited to financial, medical and location information, is for general informational purposes only and is not guaranteed by Licensor or its agents.
              You will not use the External Services in any manner that is inconsistent with the terms of this Standard EULA or that infringes the intellectual property rights of Licensor or any third party.
              You agree not to use the External Services to harass, abuse, stalk, threaten or defame any person or entity, and that Licensor is not responsible for any such use. External Services may not be available in all languages or in your Home Country, and may not be appropriate or available for use in any particular location.
              To the extent you choose to use such External Services, you are solely responsible for compliance with any applicable laws. Licensor reserves the right to change, suspend, remove, disable or impose access restrictions or limits on any External Services at any time without notice or liability to you.
            </li>
            <li>
              e. NO WARRANTY: YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LICENSED APPLICATION AND ANY SERVICES PERFORMED OR PROVIDED BY THE LICENSED APPLICATION ARE PROVIDED "AS IS" AND “AS AVAILABLE,”
              WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND, AND LICENSOR HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH RESPECT TO THE LICENSED APPLICATION AND ANY SERVICES,
              EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OF FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, OF QUIET ENJOYMENT, AND OF NONINFRINGEMENT OF THIRD-PARTY RIGHTS.
              NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY LICENSOR OR ITS AUTHORIZED REPRESENTATIVE SHALL CREATE A WARRANTY. SHOULD THE LICENSED APPLICATION OR SERVICES PROVE DEFECTIVE, YOU ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION.
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR LIMITATIONS ON APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO THE ABOVE EXCLUSION AND LIMITATIONS MAY NOT APPLY TO YOU.
            </li>
            <li>
              f. Limitation of Liability. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL LICENSOR BE LIABLE FOR PERSONAL INJURY OR ANY INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OF OR INABILITY TO USE THE LICENSED APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR OTHERWISE)
              AND EVEN IF LICENSOR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES,
              SO THIS LIMITATION MAY NOT APPLY TO YOU. In no event shall Licensor’s total liability to you for all damages (other than as may be required by applicable law in cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if the above stated remedy fails of its essential purpose.
            </li>
            <li>
              g. You may not use or otherwise export or re-export the Licensed Application except as authorized by United States law and the laws of the jurisdiction in which the Licensed Application was obtained.
              In particular, but without limitation, the Licensed Application may not be exported or re-exported (a) into any U.S.-embargoed countries or (b) to anyone on the U.S. Treasury Department's Specially Designated Nationals List or the U.S. Department of Commerce Denied Persons List or Entity List. By using the Licensed Application, you represent and warrant that you are not located in any such country or on any such list.
              You also agree that you will not use these products for any purposes prohibited by United States law, including, without limitation, the development, design, manufacture, or production of nuclear, missile, or chemical or biological weapons.
            </li>
            <li>
              h. The Licensed Application and related documentation are "Commercial Items", as that term is defined at 48 C.F.R. §2.101, consisting of "Commercial Computer Software" and "Commercial Computer Software Documentation", as such terms are used in 48 C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable.
              Consistent with 48 C.F.R. §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Government end users (a) only as Commercial Items and (b) with only those rights as are granted to all other end users pursuant to the terms and conditions herein.
              Unpublished-rights reserved under the copyright laws of the United States.
            </li>
            <li>
              i. Except to the extent expressly provided in the following paragraph, this Agreement and the relationship between you and Apple shall be governed by the laws of the State of California, excluding its conflicts of law provisions. You and Apple agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Santa Clara,
              California, to resolve any dispute or claim arising from this Agreement. If (a) you are not a U.S. citizen; (b) you do not reside in the U.S.; (c) you are not accessing the Service from the U.S.; and (d) you are a citizen of one of the countries identified below,
              you hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below, without regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the courts located in the state, province or country identified below whose law governs:
            </li>
            <li>
              If you are a citizen of any European Union country or Switzerland, Norway or Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.

              Specifically excluded from application to this Agreement is that law known as the United Nations Convention on the International Sale of Goods.
            </li>
          </ul>
          <h2>6. PRIVACY AND SECURITY</h2>
          <ul className="decimal">
            <li>
              Your Personal Information is maintained by Mindpeers in electronic
              form on its equipment and the equipment of its employees. Such
              information may also be converted to physical form from time to
              time. Mindpeers takes all necessary precautions to protect your
              personal information both online and off-line and implements
              reasonable security practices and measures including certain
              managerial, technical, operational and physical security control
              measures that are commensurate with respect to the information
              being collected and the nature of Mindpeers business.
            </li>
            <li>
              No administrator at Mindpeers will know your password. You need to
              protect against unauthorized access to your password, your
              computer, and your mobile phone. Be sure to log off from the
              Website when finished. Mindpeers does not undertake any liability
              for any unauthorised use of your account and password. If you
              suspect any unauthorized use of your account, you must immediately
              notify Mindpeers by sending an email to hello@mindpeers.co. You
              shall be liable to indemnify Mindpeers due to any loss suffered by
              it due to such unauthorized use of your account and password.{' '}
            </li>
            <li>
              Mindpeers makes all User information accessible to its employees,
              agents, or partners and third parties only on a need-to-know basis
              and binds its employees to strict confidentiality obligations.
            </li>
            <li>
              Mindpeers makes all User information accessible to its employees,
              agents, or partners and third parties only on a need-to-know basis
              and binds its employees to strict confidentiality obligations.
            </li>
            <li>
              Part of the functionality of the Mindpeers is assisting the
              Customers/ Clients/ Users access information relating to them.
              Mindpeers may, therefore, retain and submit all such records to
              the relevant users, or their Mental Health Care Professionals.
            </li>
            <li>
              Notwithstanding the above, Mindpeers is not responsible for the
              confidentiality, security, or distribution of your Personal
              Information by our partners and third parties outside the scope of
              our agreement with such partners and third parties. Further,
              Mindpeers shall not be responsible for any breach of security or
              for any actions of any third parties or events that are beyond the
              reasonable control of Mindpeers including but not limited to, acts
              of government, computer hacking, unauthorised access to computer
              data and storage device, computer crashes, breach of security and
              encryption, poor quality of Internet service or telephone service
              of the User etc.
            </li>
            <li>
              MINDPEERS DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE WEBSITE OR ANY HYPERLINKED WEBSITE OR
              SERVICE, AND MINDPEERS WILL NOT BE A PARTY TO OR IN ANY WAY
              MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
              PRODUCTS OR SERVICES.
            </li>
          </ul>
          <h2>7. INTELLECTUAL PROPERTY</h2>
          <ul className="abc">
            <li>
              The Website and MindPeers Services contain intellectual property,
              which is the property of MindPeers (
              <strong>"MindPeers IP"</strong>
              ). This includes, but is not limited to, copyrights, trade marks,
              information about technology, and other proprietary rights, and
              may be provided in the form of text, graphic, audio and video
              downloads, links or source codes. We retain the rights to the
              MindPeers IP and reserve all rights in respect of same.
            </li>
            <li>
              Subject to these Terms, we grant you a non-exclusive,
              non-transferable, limited, revocable right to access and use the
              Website and MindPeers IP and the material displayed thereon.
              However, no right, title or interest in any such materials or
              MindPeers IP will be granted or transferred to you as a result of
              any permitted use of such materials.
            </li>
            <li>
              All materials incorporated in or accessible through the Website,
              including, without limitation, text, photographs, images,
              graphics, illustrations, logos, button icons, audio clips, video
              clips, software and other content, and the compilation,
              collection, arrangement and assembly thereof (including the look
              and feel of the Website), are owned, controlled or licensed by
              MindPeers, or by the original creators of such materials or their
              permitted licensors. Such materials may be used only for viewing
              the Website in the ordinary course or as a resource for using the
              MindPeers Services through the Website. Any other use of such
              materials, including any copying, reproduction, modification,
              sale, distribution, extraction, re-use, transmission,
              republication, downloading, display, posting, performance or other
              exploitation thereof by any means or medium without the prior
              written permission of the copyright owner is strictly prohibited.
            </li>
            <li>
              In respect of all content or material that you submit via the
              Website or otherwise: You grant us a worldwide, irrevocable,
              non-exclusive, royalty-free license to use, reproduce, adapt,
              publish, translate and distribute such material in any media,
              together with the right to sub-license such rights; and
            </li>
            <li>
              The MindPeers IP is provided by us on an "as is" basis, and we
              expressly disclaim, to the extent permitted by applicable law, any
              or all warranties, express or implied, including without
              limitation warranties of merchantability and fitness for a
              particular purpose, with respect to any MindPeers IP. To the
              extent permitted by applicable law, we exclude any or all
              responsibility and /or liability for any damages or loss of any
              kind whatsoever with respect to the MindPeers IP whether any of
              the foregoing are, without limitation, indirect, incidental,
              special, punitive, consequential or of any other kind whatsoever.
            </li>
          </ul>
          <h2>8. INDEMNIFICATION</h2>
          <ul className="abc">
            <li>
              You acknowledge and expressly agree that use of this web site is
              at your sole and own risk. You agree to defend, indemnify and hold
              MindPeers, its affiliates and business partners as well as their
              respective directors, officers, trustees and employees harmless
              from any and all liabilities, costs and expenses, including
              reasonable attorneys’ fees, related to any violation of these
              terms of use by you or users of your account, or in any way
              arising out of the use of this web site, including without
              limitation, the placement or transmission of any information or
              other materials on this web site by you or users of your account.
            </li>
            <li>
              By accepting these Terms, you agree to indemnify, keep us
              indemnified and otherwise hold harmless MindPeers, its officers,
              employees, agents and other partners from any direct, indirect,
              incidental, special, consequential or exemplary damages, and all
              other losses, costs, changes, demands, proceedings, and actions,
              howsoever incurred by MindPeers arising from any claims or legal
              proceedings which are brought or threatened against us by any
              person resulting from: (a) your use of the Website and MindPeers
              Services; (b) unauthorized access to the Website and MindPeers
              Services; or (c) any breach of these Terms by you or any other
              matter relating to the Website or MindPeers Services.
            </li>
            <li>
              In the event that any of your enquiries submitted via the Website
              infringe any rights of any third party, you shall, at your own
              expense and at our discretion, either obtain the right to use such
              contribution or render such contribution free from infringement.
            </li>
          </ul>
          <h2>9. SUSPENSION &amp; TERMINATION</h2>
          <ul className="abc">
            <li>
              We will determine, in our discretion, whether there has been a
              breach of these Terms through your use of the Website or MindPeers
              Services. When a breach of these terms has occurred, we may take
              such action as we deem to be appropriate.
            </li>
            <li>
              You agree that we may, with or without cause and/or prior notice
              take any or all of the following actions:{' '}
            </li>
            <ul className="abc1">
              <li>
                Immediate, temporary or permanent withdrawal of your right to
                use the Website or MindPeers Services;
              </li>
              <li>issue a warning to you;</li>
              <li>
                legal proceedings against you for reimbursement of all costs on
                an indemnity basis (including but not limited to, reasonable
                administrative and legal costs resulting from the breach);
              </li>
              <li>further legal action against you; and/or</li>
              <li>
                disclosure of such information to law enforcement authorities as
                we reasonably feel is necessary.
              </li>
            </ul>
            <li>
              Without limiting the foregoing, the following will lead to a
              termination by us of your use of the MindPeers Services: (a)
              breaches or violations of these Terms or other incorporated
              MindPeers agreements or guidelines; (b) requests by law
              enforcement or other government agencies; (c) an express request
              by you; (d) suspicion of fraud; or (e) unexpected technical issues
              or problems.
            </li>
            <li>
              We exclude liability for actions taken in response to breaches of
              these Terms. The responses described in these Terms are not
              limited and we may take any other action we reasonably deem
              appropriate.
            </li>
          </ul>
          <h2>10. DISCLAIMERS &amp; LIMITATION OF LIABILITY</h2>
          <ul className="abc">
            <li>
              To the extent permitted by law, we, our officers, employees,
              agents, directors and third parties connected to us hereby
              exclude:{' '}
            </li>
            <ul className="abc1">
              <li>
                all conditions, warranties and other terms which may otherwise
                be implied by statute, common law or the law of equity,
                including without limitation:{' '}
              </li>
              <li>
                that the Website and MindPeers Services are of satisfactory
                quality, accurate, timely, fit for a particular purpose or need,
                or non-infringing;
              </li>
              <li>
                that the Website and MindPeers Services are of satisfactory
                quality, accurate, timely, fit for a particular purpose or need,
                or non-infringing;
              </li>
              <li>
                warranties as to privacy and security other than as stated in
                our Privacy Statement;
              </li>
              <li>
                that you will be able to access or use the Website or MindPeers
                Services at times or locations of your choosing; and
              </li>
              <li>
                any warranties on the basis of oral or written information given
                by a MindPeers representative.
              </li>
              <li>
                any liability for any direct, indirect, consequential, special,
                incidental or exemplary loss or damage incurred by any user in
                connection with the Website and MindPeers Services or in
                connection with their use, or results of the use of the Website
                and MindPeers Services, any websites linked to them and any
                materials posted on them, including without limitation any
                liability for; loss of income or revenue loss of business, loss
                of profits or contracts, loss of anticipated savings, the use of
                money or opportunity, loss of data; loss of goodwill or
                reputation, wasted management, office time or personnel time,
                work stoppage, computer failure or malfunction, or any other
                loss or damage of any kind, howsoever arising and whether caused
                by tort (including negligence), breach of contract or otherwise,
                even if foreseeable.
              </li>
              <li>
                Nothing in this Section affects our liability for death or
                personal injury arising from our negligence, nor our liability
                for fraudulent misrepresentation, nor any other liability which
                cannot be excluded or limited under applicable law.{' '}
                <p>
                  You understand and agree that although an Expert may be a
                  MindPeers therapist or other mental health professional,
                  MindPeers cannot predict or assess the competence of, or
                  appropriateness, or verify the credentials of such
                  Professional. MindPeers disclaims any and all liability for
                  any consultation and therapy services rendered by a
                  Professional to you through the Website. You further
                  acknowledge and agree that you take full responsibility for
                  the decision to access a Professional through the Website and
                  to continue to interact with such individual(s), and that the
                  role of MindPeers is strictly limited to providing access to
                  such Professionals to you.
                </p>
              </li>
            </ul>
          </ul>
          <h2>11. GOVERNING LAW </h2>
          <p>
            This Agreement shall be governed by the Laws of India, and the
            Courts of New Delhi shall have exclusive jurisdiction with respect
            to any dispute arising hereunder.
          </p>
          <h2>12. MISCELLANEOUS PROVISIONS </h2>
          <ul className="decimal">
            <li>
              Survival: In the event of termination or expiration of these Terms for any reason, any provisions of these Terms that by their nature should survive termination of these Terms will survive termination of these Terms, unless contrary to the pertinent provisions herein stated.
            </li>
            <li>
              Severability: If any term or provision in these Terms is held to be either illegal or unenforceable, in whole or in part, under any enactment or rule of law, such term or provision or part shall to that extent be deemed not to form part of these Terms, but the validity and enforceability of the remainder of these Terms shall not be affected.
            </li>
            <li>
              Unenforceability: If any provision of these Terms or any word, phrase, clause, sentence, or other portion thereof should be held to be unenforceable or invalid for any reason, then provided that the essential consideration for entering into these Terms on the part of any Party is not unreasonably impaired, such provision or portion thereof shall be modified or deleted in such manner as to render these Terms as modified legal and enforceable to the maximum extent permitted under applicable laws.
            </li>
            <li>
              No Waiver: No delay or omission by either Party hereto to exercise any right or power occurring upon any noncompliance or default by the other party with respect to any of the terms of these Terms shall impair any such right or power or be construed to be a waiver thereof. The terms and conditions of these Terms may be waived or amended only in writing or mutual agreement of the Parties. A waiver by either of the Parties hereto of any of the covenants, conditions, or agreements to be performed by the other shall not be construed to be a waiver of any succeeding breach thereof or of any covenant, condition, or agreement herein contained (whether or not the provision is similar).
            </li>
            <li>
              No Refund on Plan Expiry: MindPeers may have plans on it's portal which may allow the user to buy 'credits' in advance for ease in transaction or also gift session credits to other users. However, sometimes, these plans may carry an expiry term with them. Once the term of expiry is reached, the credits will no longer be usable. These plans or any plans of this nature are not refundable under any circumstance.
            </li>
          </ul>
        </article>
      </div>
      {/* <ReportFooter /> */}
    </main>
  );
};

export default TermsOfUse;
