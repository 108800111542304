import React from "react";
import { connect } from "react-redux";
import TherapistCard from "./TherapisitCard";
import LoadingComp from "../../../common/LoadingComp";
const ViewAllTherapists = ({ loadedTherapists, therapists }) => {
  return (
    <>
      {loadedTherapists ? (
        <div className="row justify-content-between card-section">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="row justify-content-between">
              {therapists.map((_item) => {
                return <TherapistCard key={_item.id} _item={_item} />;
              })}
            </div>
          </div>
        </div>
      ) : (
        <LoadingComp text="Loading..." />
      )}
    </>
  );
};
const getVisibleTherapists = (therapists, filterText) => {
  return therapists.filter((therapist) => {
    return therapist.user.name.toLowerCase().includes(filterText.toLowerCase());
  });
};

const mapStateToProps = ({
  fetchTherapists: { loadedTherapists, therapists, filterText },
}) => {
  return {
    loadedTherapists,
    therapists: getVisibleTherapists(therapists, filterText),
  };
};

export default connect(mapStateToProps)(ViewAllTherapists);
