import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { anchorRedirection } from '../../utils/_helper'
import FormPageModel from "../common/modalfolder/FormPageModal";
import './HeadingSection.css'

const HeadingSection = (props) => {

    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel
    // this is  common header section  can commonly used on all pages
    const handleAction = (e) => {
        //helps to naviagate to getAccess form component in organisation page
        if (props.heading === 'TeamCare By MindPeers') {
            anchorRedirection.tag("get-access");
        }
    }
    const handleClick = (event) => {
        event.preventDefault();
        if (props.heading === 'TeamCare By MindPeers') {
            changemodelValue(!modelValue);
            setShowModel(true);
        }
        if (props.executeBtnClick)
            props.executeBtnClick()
    }
    let homeMobile = false;
    if (props.home && window.innerWidth <= 500)
        homeMobile = true;

    return (
        <div>
            <div className="container">
                <div className={`row  ${homeMobile ? "pt-5 pb-2" : " min-vh-100 home-section"}`}>
                    <div className={props.firstClassName}>
                        <h1 className="main-title">{props.heading}</h1>
                        <p className="main-title-lead" dangerouslySetInnerHTML={{ __html: props.description }}></p>
                        {/* <Link onClick={handleClick} to={props.heading === 'Therapy' && 'get-matched'}><button type="button" className="btn btn-access-tool">{props.btnText}</button></Link> */}
                        {!homeMobile && <Link onClick={handleClick} to='#'><button type="button" className="btn btn-access-tool">{props.btnText}</button></Link>}
                    </div>
                    <div className={props.secondClassName}>
                        {
                            homeMobile ?
                                <div className="d-flex">
                                    <button type="button" onClick={handleClick} className="btn btn-access-tool mr-2">{props.btnText}</button>
                                    <img src={props.imgPath} className="home-img-brain" alt="" title="" />
                                </div> :
                                <img src={props.imgPath} className="img-fluid banner-left-8" alt="" title="" />
                        }
                    </div>
                </div>
                <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="Explore The Tool" />
            </div>
        </div>
    );
}

export default HeadingSection;
