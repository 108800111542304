import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import CustomRadioButtonField from '../../../common/Buttons/CustomRadioButtonField'
import useGetMatchForm from '../../../../utils/useGetMatchForm';
import formValidation from "../../../../utils/formValidation";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __basicInfoScreenTwo } from '../../../../store/actions/matchTherapy.action'

const BasicInfoScreenTwo = (props) => {

    const options1 = ["Male", "Female", "Prefer Not to Say", "Others"]
    const options2 = ["He/Him", "She/Her", "They/Them"]

    const formValues = () => {
        //call back function after the form submit
        console.log(values);
        props.setPage(1)
        props.setStepName('Prefrences')
        props.__basicInfoScreenTwo(values);
      };
    const { values, errors, handleChange, handleSubmit } = useGetMatchForm(
        //sends/recieve our values and errors to formvalidation.js and useform custom hooks
        formValues,
        formValidation.basicInfoformScreen2,
        props.basicInfoScreenTwo,
        {}
      );
    return (
        <>
        <form className="step-form" onSubmit={handleSubmit} noValidate>
            <CustomRadioButtonField options={options1} name="gender" ques={"What is your Gender?" } error={errors.gender} errorother={errors.othergender} handleChange={handleChange} value={values.gender} valueOther={values.othergender}/> 
            <CustomRadioButtonField options={options2} name="preferPronoun" ques={"What are your preferred pronouns?"} error={errors.preferPronoun} handleChange={handleChange} value={values.preferPronoun} /> 
            <div className="row">
                <div className="col-md-12">
                    <div className="start-now text-right">
                        <button
                            type="submit"
                            className="btn btn-start-now"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </form>    
        </>
    )
}
const mapStateToProps = ({ matchTherapyFormData }) => ({
    ...matchTherapyFormData
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        __basicInfoScreenTwo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicInfoScreenTwo));

