import api from "./ApiService";

export default class TherapyService {
  //Guest api call
  static async getSignedUrl(type, filename) {
    let response = await api(
      `query{
          getSignedUrl(type:${type} filename:"${filename}"){
            filename
            type
            uri
            url
        }
      }`
    );
    if (response.error !== null) {
      return false;
    } else {
      return response.data;
    }
  }

  static async contactus(values, limit = 100, offset = 0) {
    let response = await api(
      `mutation{
                submitContact(
                    name:"${values.name}"
                    email:"${values.email}"
                    contact:{
                        code:"${values.mobileCode || ""}"
                        mobile:"${values.phone || ""}"
                    }
                    message:"""${values.message || ''}"""
                )
        }`
    );
    if (response.error !== null) {
      return false;
    } else {
      return response.data;
    }
  }

  static async selfcareform(values, limit = 100, offset = 0) {
    let response = await api(
      `mutation{
                type:SELF_CARE_PREMIUM
                submitContact(
                    name:"${values.name}"
                    email:"${values.email}"
                    message:"""${values.message || ''}"""
                )
        }`
    );
    if (response.error !== null) {
      return false;
    } else {
      return response.data;
    }
  }
  static async enumApi(name) {
    let response = await api(
      `query{
            enums(name:"${name}"){
              key
              value
            }
          }`
    );
    if (response.error !== null) {
      return {
        success: false
      }
    } else {
      return {
        success: true,
        data: response.data.enums
      }
    }
  }
  static async getAllCountryCodes() {
    let response = await api(
      `query {
            listCountryCodes
        }`
    );
    
    if (response.error !== null) {
      return {
        success: false
      }
    } else {
      return {
        success: true,
        data: response.data.listCountryCodes
      }
    }
  }
}
