import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ScannerModal.css";

const ScannerModal = props => {
  const handleClose = () => {
    props.setShow(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        className="scanner-modal-desktop"
        dialogClassName="custom-scanner-modal"
        centered
        backdrop="static"
      >
        <Modal.Body className="scanner-modal-body">
          <div className="scanner-modal-content">
            <img
              src="https://cdn.mindpeers.co/site-icons/appdownload_qr.svg"
              className="scanner-qr-code"
              alt=""
              title=""
            />
            <div className="scanner-info-text-outer-div">
              <span className="scanner-info-text">
                Wuho! You're about to enter MindPeers Club. Just one more step
                🥳
              </span>

              <div className="app-store-buttons">
                <Link
                  to={{ pathname: "https://bit.ly/3AWhz9x" }}
                  target="_blank"
                >
                  <img
                    className="app-icon"
                    src="https://cdn.mindpeers.co/site-icons/google_play_button.png"
                    alt="google-play-store"
                  />
                </Link>
                <Link
                  to={{ pathname: "https://apple.co/3z95Vca" }}
                  target="_blank"
                >
                  <img
                    className="app-icon"
                    src="https://cdn.mindpeers.co/site-icons/ios_app_store.png"
                    alt="apple-app-store"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div
            className="scanner-modal-close-btn"
            onClick={() => props.setShow(false)}
          >
            <img src="./img/close-btn.svg" alt="" title="" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScannerModal;
