import React from 'react';

const OurMission = () => {
     //this OurMission components helps in about-us page
    return (
        <>
                <div className="row align-items-center">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                        <div className="our-mission-content position-relative">
                            <p>Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to</p>
                            <p>Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to</p>
                            <p>Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to</p>
                            <p>Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to</p>

                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="ceo-wrapper-img position-relative">
                            <span className="position-absolute"><img src="img/Repeat-Grid.svg"></img></span>
                            <img src="img/ceo.png" alt="Kanika Aggarwal" title="Kanika Aggarwal"/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="head-detail">
                            <h2 className="head-name">Kanika Aggarwal</h2>
                            <p className="head-designation">Co-Founder and Chief Executive Officer</p>
                        </div>
                    </div>
                </div> 
        </>
    );
}

export default OurMission;
