import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FormPageModel from "../../common/modalfolder/FormPageModal";

const SectionTwo = () => {
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel
    return (
        <section id="scientific_report">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="sub-lead">Designed On The Foundation Of Cognitive Behavioral Techniques</p>
                        <h3 className="sub-lead-title">Benefit from science backed digital interventions</h3>
                    </div>
                </div>
                <div className="row mb-30-scientificReport">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <img src="img/mobiles.svg" className="img-fluid" height="765" width="748" alt="" title="" />
                    </div>
                </div>
                <div className="row mb-200">
                    <div className="col-md-6 offset-md-5">
                        <h3 className="sub-lead-title">With personalised modules, games and activities for unwinding</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <h3 className="sub-lead-small-title">Using the perfect blend of technological empathy and human expertise</h3>
                    </div>
                </div>
                <div className="row mb-2 mt-n100">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <img src="img/Group 23.png" className="img-fluid showcase--large" alt="" title="" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-md-center">
                        <h3 className="sub-lead-title mb-50">Get an outcome based progress and culture roadmap</h3>
                        {/* <Link onClick={() => redirect()} to={`/form-page?event=${'Try For Free'}`} ><button type="button" className="btn btn-access-tool">Access the tool</button></Link> */}
                        <Link ><button onClick={() => { changemodelValue(!modelValue); setShowModel(true); }} type="button" className="btn btn-access-tool">Try TeamCare Now</button></Link>
                    </div>
                </div>
                <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="Try TeamCare Now" />
            </div>
        </section>
    )
}

export default SectionTwo
