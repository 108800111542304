import React from "react";

const StepLeftBar = (props) => {
  // this component keeps track on which step are we used in therapisit sign up form 
  const formImage = {
    '--therapy-form-image': props.step === 1 ? "url('../img/therapist-left-bg-2.svg')" : props.step === 2 ? "url('../img/therapist-left-bg-3.svg')"
      : props.step === 3 && "url('../img/therapist-left-bg-4.svg')"
  }

  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div className="signup-thearpist-left stl-bg" style={formImage}>
          <ul className="list-unstyled" id="steps">
            <li>
              <a onClick={() => props.setStep(1)}>
                <span className={props.step === 1 ? "step-count active" : (props.step === 2 || props.step === 3) ? "step-count complete" : "step-count"}>
                  {
                    (props.step === 2 || props.step === 3) ? <i className="fas fa-check"></i> : 1
                  }
                </span>
                Personal Details
              </a>
            </li>
            <li>
              <a onClick={props.step === 1 ? (e) => (e) : () => props.setStep(2)} className={(props.step === 1) ? "cursor-not-allowed" : ''}>
                <span className={props.step === 2 ? "step-count active" : props.step === 3 ? "step-count complete" : "step-count"}>
                  {
                    props.step === 3 ? <i className="fas fa-check"></i> : 2
                  }
                </span>
                EDUCATION & EXPERIENCE
              </a>
            </li>
            <li>
              <a className={(props.step === 1 || props.step === 2) ? "cursor-not-allowed" : ''}>
                <span className={props.step === 3 ? "step-count active" : "step-count"}>
                  3
                </span>
                ADDITIONAL INFO
              </a>
            </li>
          </ul>
          <a className="trouble-text">Having Trouble? <u>Get Help</u></a>
        </div>
      </div>
    </>
  );
};

export default StepLeftBar;
