import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import useForm from "../../../utils/useForm";
import formValidation from "../../../utils/formValidation";
import IntialState from "../../../default_config/default_intial_state"
import StaticModal from "./StaticModal";
import TermsOfUse from "../../../static/TermsOfUse";
import PrivacyPolicy from "../../../static/PrivacyPolicy";
import { facebookHelper } from "../../../utils/_helper";
import TeamCareService from "../../../api/services/TeamCareServices";
import { ToastConstant } from '../../../default_config/toastConstant'
import CustomToast from "../../../components/common/Toast/CustomToast"
import { useSelector } from "react-redux";

const FormPageModal = (props) => {
  //to validate the form we are using useForm custom hook and formvalidation js 

  const placeholdercolor = "form-control span-error is-danger";
  const formControl = "form-control";

  const intialState = IntialState.therapisitSignUpDefaultState.getAccess;
  const { phonePrefixList } = useSelector((state) => state.commonState);

  const [open, setOpen] = useState(false)
  const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })

  const [show, setShow] = useState(false);
  const [noOfEmp, setNoOfEmp] = useState('')
  //isSubmit helps to show success message after the form submit
  const [isSubmit, setIsSubmit] = useState(false);
  const [initialData, setIntialData] = useState(intialState)

  const [mobileCode, setMobileCode] = useState("+91") // use for set mobile code


  const [openTermOfUsePopUp, setOpenTermOfUsePopUp] = useState(false); // for constantly changing modelValue
  const [openPrivacyPolicyPopUp, setOpenPrivacyPolicyPopUp] = useState(false); // for constantly changing modelValue


  const formValues = () => {
    //call back function after the form submit
    let updatedValues = { ...values, employees: noOfEmp, phone: mobileCode + '-' + values.phone, via: props.clickedFrom }
    let cond = true
    console.log("phone -> ", updatedValues.phone)
    if (props.plan)
      updatedValues = { ...updatedValues, "plan": props.plan }

    let objValue = Object.keys(updatedValues)

    if (objValue.length > 0) {
      for (let i = 0; i < objValue.length; i++) {
        if (!updatedValues[objValue[i]]) {
          cond = false
          break;
        }
      }
    }
    if (objValue.length === 0) cond = false;

    // if (cond) {
    //   saveAccessFormData(updatedValues).then((res) => {
    //     if (noOfEmp && res && res.ResponseCode == "200") {
    //       setIsSubmit(true)
    //         facebookHelper.leadOperation();
    //     }
    //   })
    // }

    if(cond){
        TeamCareService.enquiry(updatedValues).then((res) => {
          if (noOfEmp && res) {
            setIsSubmit(true)
                facebookHelper.leadOperation();
          } else {
            setIsSubmit(false)
            setOpen(true)
            setToastData(ToastConstant.API_ERROR)
          }
       })
    }

  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    //sends/recieve our values and errors to formvalidation.js and useform custom hooks

    // intial state is default value state for all form fields coming from default_intial_state
    // "formPageModal",
    formValues,
    formValidation.validate,
    initialData,
    { fromPopup: true, componentComingFrom: "formPageModal", mobileCode }
  );

  useEffect(() => {
    if (props.showModel) { // to ensure when button is clicked model is opened
      setShow(true);
      Object.keys(errors).map((key) => {
        delete errors[key]; // to clear all errors
      });
      Object.keys(values).map((key) => {
        delete values[key]; // to clear all values
      });
      setIntialData(intialState)
      setNoOfEmp('')
      setMobileCode('+91')
    }
  }, [props.modelValue]);


  useEffect(() => {
    //setShow(true);
    if (isSubmit) {
      setTimeout(() => {
        setShow(false)
        setIsSubmit(false);
        // props.history.push({ pathname: "/organisation" })
      }, 10000)
    }
    else {
      if(!!noOfEmp){
        setIntialData(intialState);
        setNoOfEmp('')
        setMobileCode('+91')
        Object.keys(values).map((key) => {
          delete values[key]; // to clear all values
        });
      }
    }
  }, [isSubmit]);

  return (
    <>
      {/* <Link className={`${props.linkClass}`}><button type="button" onClick={() => setShow(true)} className={`btn ${props.btnClass}`}>{props.btnName}</button></Link> */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName={!isSubmit ? "custom-m-w" : "ty-wrapper"}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Body>
          <div className="get-access-form">
            {/* <Link className={`float-right cursor-pointer text-secondary ${isSubmit ? 'mt-2 mr-3' : ''}`} to={"/organisation"} ><i className="fa fa-times" ></i></Link> */}
            <span className={`position-absolute right-0 top-0 cursor-pointer text-secondary'`} onClick={() => setShow(false)} ><i className="fa fa-times" ></i></span>
            {
              !isSubmit ?
                <>
                  <h3 className="form-title text-center">Get Access</h3>
                  <form className="ga-form" onSubmit={handleSubmit} noValidate>
                    <div className="row align-items-center">
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Name:</label>
                          <input type="text" onChange={handleChange} className={errors.name ? placeholdercolor : formControl} name="name" id="" placeholder={errors.name || ""} value={values.name || ''} required />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Email:</label>
                          <input type="email" className={errors.email ? placeholdercolor : formControl} onChange={handleChange} id="" name="email" placeholder={errors.email || ""} value={values.email || ''} required />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Phone:</label>
                          <div className="d-flex align-items-center">
                            <div className="country-code mr-3">
                              <select className="form-control" value={mobileCode} onChange={(e) => setMobileCode(e.target.value)}>
                                {/* <option value="+91">+91</option>
                                <option value="+65">+65</option> */}
                                {
                                  phonePrefixList?.map((prefix , i)=>(
                                      <option key={i} value={prefix}>{prefix}</option>
                                  ))
                                }
                              </select>
                            </div>
                            <div className="country-phone-input flex-grow-1">
                              <input type="tel" onChange={handleChange} className={errors.phone ? placeholdercolor : formControl} id="" name="phone" placeholder={errors.phone || ""} value={values.phone || ''} required />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Company</label>
                          <input type="text" onChange={handleChange} className={errors.company ? placeholdercolor : formControl} id="" name="company" placeholder={errors.company || ""} value={values.company || ''} required />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Designation</label>
                          <input type="text" className={errors.designation ? placeholdercolor : formControl} onChange={handleChange} name="designation" placeholder={errors.designation || ""} value={values.designation || ''} required id="" />
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-12">
                        <div className="form-group">
                          <label>Number of employees {!noOfEmp && <span className="text-danger">*</span>} </label>
                          <div className="no-of-emps">
                            <div onClick={() => setNoOfEmp("0-10")} className={`emp ${noOfEmp === '0-10' ? 'selected' : ''}`}>0-10</div>
                            <div onClick={() => setNoOfEmp("11-25")} className={`emp ${noOfEmp === '11-25' ? 'selected' : ''}`}>11-25</div>
                            <div onClick={() => setNoOfEmp("25-50")} className={`emp ${noOfEmp === '25-50' ? 'selected' : ''}`}>25-50</div>
                            <div onClick={() => setNoOfEmp("50-100+")} className={`emp ${noOfEmp === '50-100+' ? 'selected' : ''}`}>50-100+</div>
                          </div>
                        </div>
                      </div>
                      {
                        errors.email === 'Only work emails are allowed' &&
                        <div className="col-md-12 text-center">
                          <span className="text-danger">{errors.email} *</span>
                        </div>
                      }
                      {
                        errors.phone === 'Please enter a valid phone number' &&
                        <div className="col-md-12 text-center">
                          <span className="text-danger">{errors.phone} *</span>
                        </div>
                      }
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group text-center">
                          <button type="submit" className="btn btn-ga-submit">Submit</button>
                        </div>
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="privacy-policy-box mx-3" style={{ "--font-size": "9px" }}>
                          <p>By clicking 'SUBMIT' , you consent to the use of your personal data for marketing purposes and also agree to MindPeers's <u className="cursor-pointer" onClick={() => setOpenTermOfUsePopUp(true)}>Terms of Use</u> and <u className="cursor-pointer" onClick={() => setOpenPrivacyPolicyPopUp(true)}>Privacy Policy</u></p>
                        </div>
                      </div>
                    </div>
                    {
                      openTermOfUsePopUp && <StaticModal modalBody={<TermsOfUse />} setOpenTermOfUsePopUp={setOpenTermOfUsePopUp} openTermOfUsePopUp={openTermOfUsePopUp} popUpHeading="Terms Of Use" />
                    }
                    {
                      openPrivacyPolicyPopUp && <StaticModal modalBody={<PrivacyPolicy />} setOpenPrivacyPolicyPopUp={setOpenPrivacyPolicyPopUp} openPrivacyPolicyPopUp={openPrivacyPolicyPopUp} popUpHeading="MINDPEERS PRIVACY POLICY" />
                    }
                  </form>
                </>
                :
                <div className="thank-you-wrapper">
                  <div className="d-flex align-items-center flex-xl-no-wrap flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap">
                    <div className="ty-success-bg">
                      <div className="d-flex align-items-center">
                        <div>
                          <div className="green-check">
                            <i className="fas fa-check"></i>
                          </div>
                          <h3>Submitted successfully</h3>
                        </div>
                      </div>
                    </div>
                    <div className="ty-success-content flex-grow-1 text-xl-left text-lg-left text-md-left text-sm-center text-center">
                      <h2>Thank You!</h2>
                      <p>Access link with all information will be emailed to you shortly..</p>
                    </div>
                  </div>
                  {/* <h3>Thank You!</h3>
                  <div className="green-check">
                    <i className="fas fa-check"></i>
                  </div>
                  <p className="success-msg">
                    Access link with all information will be emailed to you
                    shortly..
                  </p> */}
                </div>
            }
          </div>
        </Modal.Body>
      </Modal>
      {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }

    </>
  );
};

export default withRouter(FormPageModal);
