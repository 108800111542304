import React from 'react'
import GiftPackageComp from "./GiftPackageComp"
import Footer from "../Footer"
import BackToTop from "../BackToTop"
const GiftPackages = () => {
    return (
        <>
            <GiftPackageComp />
            <Footer />
            <BackToTop />
        </>
    )
}

export default GiftPackages
