import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import StarRating from "../StarRating";
import { convertRating, convertIsoDate } from "../../../../../utils/formatData"

//this componenets helps us in Profile page in Review section
const ProfileReview = () => {

  //this is rating star npm librabry later we can use it if we want
  // const starData = {
  //     size: 50,
  //     count: 5,
  //     color: "#ACACAC",
  //     activeColor: "#E5FC0A",
  //     value: 4,
  //     a11y: true,
  //     isHalf: true,
  //     emptyIcon:<li className="list-inline-item"><i className="far fa-star"></i></li>,
  //     halfIcon: <li className="list-inline-item"><i className="fas fa-star"></i></li>,
  //     filledIcon: <li className="list-inline-item"><i className="fas fa-star"></i></li>,
  //     onChange: newValue => {
  //       console.log(`Example 2: new value is ${newValue}`);
  //     }
  //   };
  const therapist = useSelector(({ fetchTherapists: { therapist } }) => therapist)
  const [expandedValue, expandstate] = useState(false);
  const expandOnClick = () => {
    //on click see more button in review section , helps to controll hide/show here
    if (expandedValue === false) {
      expandstate(true);
      console.log(expandedValue);
    }
    else {
      expandstate(false);
      console.log(expandedValue);
    }
  };
  return (
    <>
      <div className="row review-container pb-4">
        <div className="col-lg-8">
          <div className="d-sm-flex justify-content-between pb-4">
            <div className="head">Reviews</div>
            <div>
              <div className="rating">User Rating ({therapist.user.rating})</div>
              < StarRating stars={convertRating(therapist.user.rating)} />
            </div>
          </div>

          <div className="review-message">
            {!therapist.reviews.length && <p className="title text-center">No Reviews</p>}
            {
              therapist.reviews.map((review, i) =>
                i % 2 == 0 ? <div key={review.id} className={(i < 5 || expandedValue) ? "d-sm-flex align-items-center justify-content-between left-message" : "d-none"}>
                  <div className="profile-box">
                    <div className="img-box">
                      <i className="fas fa-user-alt"></i>
                    </div>
                  </div>
                  <div className="message-box flex-grow-1">
                    {review}
                    {/* <div className="date">{convertIsoDate(review.created_at)}</div> */}
                  </div>
                </div>
                  : <div className={(i < 5 || expandedValue) ? "d-sm-flex align-items-center justify-content-between right-message" : "d-none"}>
                    <div className="message-box flex-grow-1">
                      {review}
                      {/* <div className="date">{convertIsoDate(review.created_at)}</div> */}
                    </div>
                    <div className="profile-box">
                      <div className="img-box">
                        <i className="fas fa-user-alt"></i>
                      </div>
                    </div>
                  </div>
              )
            }
            {therapist.reviews.length > 5 &&
              <div className="text-center">
                <div className="see-more" onClick={expandOnClick}>{expandedValue ? "See Less" : "See More"}</div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileReview;
