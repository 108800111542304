import React from 'react';
import { Link } from 'react-router-dom';
import { getRedirectionUrl } from '../../../utils/_helper';
const TherapyHeader = () => {
    //this componenets helps us in Therapy page in below header section
    return (
        <>
            <div className="container">
                <div className="row ">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="sub-lead">Find a MindCoach</p>
                        <h3 className="sub-lead-title">Whether you need help identifying a concern or dealing with it, we're here for you.</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="matching-box text-center angle-right" style={{ '--angle-right-pos': '-36px', 'width': '319px' }}>
                            <img src="img/pre-assesment.svg" className="img-fluid icon-1" width="80" height="80" alt="Pre Assessment" title="Pre Assessment" />
                            <h4>Pre-assessment</h4>
                            <p>Answer a few questions to help us understand what's on your mind</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="matching-box text-center position-relative angle-right" style={{ '--angle-right-pos': '-50px', 'width': '318px' }}>
                            <img src="img/get-recommend.svg" className="img-fluid" width="80" height="80" alt="Get Recommendations" title="get recommendations" />
                            <h4>get recommendations</h4>
                            <p>Get matched with a therapist ideal for you</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                        <div className="matching-box text-center" style={{ 'width': '339px' }}>
                            <img src="img/book-therapy.svg" className="img-fluid" width="80" height="80" alt="Book Therapy" title="Book Therapy" />
                            <h4>Book Therapy</h4>
                            <p>Talk to a therapist who specializes in your concern</p>
                        </div>
                    </div>
                </div>
                <div className="row pb_30">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="text-center">
                            {/* <Link className="get-matched" to="/get-matched">Get matched</Link> */}
                            <Link className="get-matched" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/get-matched`)}`), "_blank")}>Get matched</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TherapyHeader;
