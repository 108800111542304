import api from "./ApiService";

export default class TherapyService {
  static async listAll(categories = [], limit = 100, offset = 0) {
    let countryCodeString = "";
    try {
      let userDetails = await fetch(
        `https://geolocation-db.com/json/${process.env.REACT_APP_GEO_KEY}`
      );
      userDetails = await userDetails.json();
      countryCodeString = `countryCode:"${userDetails.country_code}"`;
    } catch (error) {}
    let response = await api(
      `query{
            listBoats(
                limit : ${limit}
                offset : ${offset}
                ${categories.length > 0 ? `categories :[${categories}]` : ""}
                ${countryCodeString}
            ){
                id
                title
                image
                description
                image
                mode
                slug
                price
                start_at
                end_at
                categories
            }
          }`
    );
    if (response.error !== null) {
      return {
        success: false,
        data : response.error.errors && response.error.errors[0] || {}
      };
    } else {
      return {
        success: true,
        data: response.data.listBoats,
      };
    }
  }

  static async getById(id) {
    let response = await api(
      `query{
            getBoat(
                boat : "${id}"
                ){
                id
                title
                image
                description
                cards{
                    title
                    data
                }
                mode
                slug
                video
                price
                start_at
                end_at
                categories
            }
          }`
    );
    if (response.error !== null) {
      return {
        success: false,
        data : response.error.errors && response.error.errors[0] || {}
      };
    } else {
      return {
        success: true,
        data: response.data.getBoat,
      };
    }
  }

  static async getBoatAvailability(id) {
    let response = await api(
      `query{
        getBoatAvailability(
          boat : "${id}"
        )
      }`
    );
    
    if (response.error !== null) {
      return {
        success: false,
        data : response.error.errors && response.error.errors[0] || {}
      };
    } else {
      return {
        success: true,
        data: response.data.getBoatAvailability,
      };
    }
  }
}
