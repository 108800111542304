import React, { useState } from "react";
import TermsOfUse from "../../static/TermsOfUse";
import PrivacyPolicy from "../../static/PrivacyPolicy";
import StaticModal from "../common/modalfolder/StaticModal";
import ContactUsModal from "../common/modalfolder/ContactUsModal";
import { getRedirectionUrl } from "../../utils/_helper";
const Footer = () => {
  const [openTermOfUsePopUp, setOpenTermOfUsePopUp] = useState(false); // for constantly changing modelValue
  const [openPrivacyPolicyPopUp, setOpenPrivacyPolicyPopUp] = useState(false); // for constantly changing modelValue

  const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue
  const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

  return (
    <>
      <section className="mp-footer mp-footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-12 mb-5">
              <div className="mp-footer-about">
                <img
                  className="mp-footer-logo"
                  src="/img/mplogo.png"
                  alt="MindPeers"
                  title="MindPeers"
                />
                <p className="mp-footer-tagline">
                  "Building and Optimizing Your Mental Strength"
                </p>
                <div className="mp-footer-social">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/mindpeers.co/"
                      >
                        <img src="/img/insta.svg" alt="" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/company/mindpeersco"
                      >
                        <img src="/img/footer-linkedin.svg" alt="" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/mindpeersco"
                      >
                        <img src="/img/footer-twitter.svg" alt="" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/mindpeers/"
                      >
                        <img src="/img/footer-facebook.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-6">
              <h3 className="mp-footer-heading">Products</h3>
              <div className="mp-footer-link">
                <ul className="list-unstyled mb-0">
                  <li className="">
                    <a href={getRedirectionUrl("/selfcare")}>SelfCare</a>
                  </li>
                  <li className="">
                    <a href={getRedirectionUrl("/organisation")}>TeamCare</a>
                  </li>
                  <li className="">
                    <a href={getRedirectionUrl("/therapy")}>Therapy</a>
                  </li>
                  <li className="">
                    <a href={getRedirectionUrl("/community")}>Boats</a>
                  </li>
                  <li className="">
                    <a href={getRedirectionUrl("/vent-out")}>Vent Out</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-6">
              <h3 className="mp-footer-heading">Resources</h3>
              <div className="mp-footer-link">
                <ul className="list-unstyled mb-0">
                  <li className="">
                    <a
                      href={getRedirectionUrl(
                        "https://blog.mindpeers.co",
                        "utm_source=website"
                      )}
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="">
                    <a
                      href={getRedirectionUrl(
                        "https://bit.ly/hiring-mindpeers",
                        "utm_source=website"
                      )}
                      target="_blank"
                    >
                      We're hiring! 🚀
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-3 col-12 m-md-0 mt-3">
              <h3 className="mp-footer-heading">Contact</h3>
              <div className="mp-footer-link">
                <ul className="list-unstyled mb-0">
                  <li className="">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        setShowModel(true);
                        changemodelValue(!modelValue);
                      }}
                    >
                      Contact Us
                    </a>
                  </li>
                  {/* <li className="">
                                        <a href="javascript:void(0)">FAQ</a>
                                    </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mp-footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="mp-copyright text-xl-left text-lg-left text-md-center text-sm-center text-center">
                  <p>© Copyright 2022, MindPeers</p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="text-xl-right text-lg-right text-md-center text-sm-center text-center">
                  <div className="mp-quick-links">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          onClick={() => setOpenTermOfUsePopUp(true)}
                        >
                          Terms of Use
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="javascript:void(0)"
                          onClick={() => setOpenPrivacyPolicyPopUp(true)}
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {openTermOfUsePopUp && (
                <StaticModal
                  modalBody={<TermsOfUse />}
                  setOpenTermOfUsePopUp={setOpenTermOfUsePopUp}
                  openTermOfUsePopUp={openTermOfUsePopUp}
                  popUpHeading="Terms Of Use"
                />
              )}
              {openPrivacyPolicyPopUp && (
                <StaticModal
                  modalBody={<PrivacyPolicy />}
                  setOpenPrivacyPolicyPopUp={setOpenPrivacyPolicyPopUp}
                  openPrivacyPolicyPopUp={openPrivacyPolicyPopUp}
                  popUpHeading="MINDPEERS PRIVACY POLICY"
                />
              )}
            </div>
            <ContactUsModal showModel={showModel} modelValue={modelValue} />
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
