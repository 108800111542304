import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import SectionOne from './sub_components/therapy/meetourexperts/SectionOne';
import BackToTop from './sub_components/BackToTop'
import Footer from './sub_components/Footer'
import fetchTherapist from "../store/actions/fetchTherapist.action"
const MeetOurExpertComponent = () => {
    //this is contain overall componenets of MeetOurExpert page
    // const loadedTherapists = useSelector(({ fetchTherapists: { loadedTherapists } }) => loadedTherapists)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTherapist.fetchTherapists())
    }, [dispatch])

    return (
        <>
            <SectionOne />
            <BackToTop />
            <Footer />
        </>
    )
}

export default MeetOurExpertComponent;
