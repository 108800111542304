import React from 'react'
import CommunityComponent from '../../components/Community';
import { TopBar } from '../../components/common/Topbar/TopBar';
import { connect } from 'react-redux';

const Community = ({ TopBarDisplay: { openTopBar } }) => {
    return (
        <main>
            {openTopBar ? <TopBar /> :
                (<>
                    <TopBar />
                    <CommunityComponent />
                </>
                )}
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(Community);
