import React, { useState } from 'react'
import CommonModal from '../common/modalfolder/CommonModal'
import useForm from '../../utils/useForm'
import formValidation from '../../utils/formValidation'
import IntialState from "../../default_config/default_intial_state"
import saveAccessFormData from "../../api/sendRequests";
import TermsOfUse from '../../static/TermsOfUse'
import StaticModal from '../common/modalfolder/StaticModal'
import PrivacyPolicy from '../../static/PrivacyPolicy'
import { facebookHelper } from '../../utils/_helper'

const WorkplaceCalculateSection = () => {
    const intialState = IntialState.therapisitSignUpDefaultState.calcuateWorkSpace;
    //Default intial sate automatically opens calcaulte form here
    // we are using react-bootstrap to get modal popup here
    //Common Modal Popup will open once form completed

    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const [openTermOfUsePopUp, setOpenTermOfUsePopUp] = useState(false); // for constantly changing modelValue
    const [openPrivacyPolicyPopUp, setOpenPrivacyPolicyPopUp] = useState(false); // for constantly changing modelValue

    const formValues = () => {
        let cond = true
        let updatedValues = { ...values, phone: "NA", via: "MindCare Index" }
        updatedValues["company"] = values["organization"];
        delete updatedValues["organization"];

        let objValue = Object.keys(values)

        if (objValue.length > 0) {
            for (let i = 0; i < objValue.length; i++) {
                if (!values[objValue[i]]) {
                    cond = false
                    break;
                }
            }
        }
        if (objValue.length === 0) cond = false;
        if (cond) {
            saveAccessFormData(updatedValues).then((res) => {
                  facebookHelper.leadOperation();
            });
        }
    }

    const {
        //form validation and dispalying error message happens her
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(formValues, formValidation.calcvalidate, intialState, { componentComingFrom: "workplaceCalculation" });
    const placeholdercolor = "form-control span-error is-danger";
    const formControl = "form-control";


    return (
        <section id="index">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="sub-lead">Workplace MindCare Index</p>
                        <h3 className="sub-lead-title">Participate in the new industry standard of behavioural healthcare. Take the audit to be listed as part of the top 100 mental wellness friendly organisations.</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6 co-md-12 col-sm-12 col-12 text-sm-center text-lg-left d-lg-flex align-items-lg-center">
                        <img src="img/accessForm.svg" className="img-fluid mb-4" alt="" title="" />
                    </div>
                    <div className="col-xl-6 col-lg-6 co-md-12 col-sm-12 col-12">
                        <div className="calculate-index">
                            <h3 className="sub-lead-workplace-title mb-50 font-bold">Calculate Your Workplace Index</h3>
                            <form className="calculate-form" onSubmit={handleSubmit} noValidate>
                                <div className="form-group row align-items-center">
                                    <label className="col-sm-3 col-form-label">Name:</label>
                                    <div className="col-sm-9">
                                        <input type="text" onChange={handleChange} name="name" className={errors.name ? placeholdercolor : formControl} id="" placeholder={errors.name || "Full Name"} value={values.name || ''} required />
                                    </div>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label for="" className="col-sm-3 col-form-label">Email:</label>
                                    <div className="col-sm-9">
                                        <input type="email" onChange={handleChange} name="email" className={errors.email ? placeholdercolor : formControl} id="" placeholder={errors.email || "Email Address"} value={values.email || ''} required />
                                    </div>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label for="" className="col-sm-3 col-form-label">Designation:</label>
                                    <div className="col-sm-9">
                                        <input type="text" onChange={handleChange} name="designation" className={errors.designation ? placeholdercolor : formControl} id="" placeholder={errors.designation || "Designation"} value={values.designation || ''} required />
                                    </div>
                                </div>
                                <div className="form-group row align-items-center">
                                    <label for="" className="col-sm-3 col-form-label">Organisation Name:</label>
                                    <div className="col-sm-9">
                                        <input type="text" onChange={handleChange} name="organization" className={errors.organization ? placeholdercolor : formControl} id="" placeholder={errors.organization || "Organisation"} value={values.organization || ''} required />
                                    </div>
                                </div>
                                {
                                    errors.email === 'Only work emails are allowed' &&
                                    <div className="col-md-12 text-center">
                                        <span className="text-danger">{errors.email} *</span>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-sm-9 offset-sm-3">
                                        <button type="submit" className="btn btn-calculate-now" onClick={() => { changemodelValue(!modelValue); setShowModel(true); }}>Calculate Now</button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="offset-sm-3 col-sm-9 mt-3">
                                        <div className="privacy-policy-workindex-box" style={{ "width": "94%", "--font-size": "9px" }}>
                                            <p>By clicking 'CALCULATE NOW' , you consent to the use of your personal data for marketing purposes and also agree to MindPeers's <u className="cursor-pointer" onClick={() => setOpenTermOfUsePopUp(true)}>Terms of Use</u> and <u className="cursor-pointer" onClick={() => setOpenPrivacyPolicyPopUp(true)}>Privacy Policy</u></p>
                                        </div>
                                    </div>
                                </div>
                                <CommonModal modelValue={modelValue} showModel={showModel} formData={values} errorMessage={errors} />
                                {
                                    openTermOfUsePopUp && <StaticModal modalBody={<TermsOfUse />} setOpenTermOfUsePopUp={setOpenTermOfUsePopUp} openTermOfUsePopUp={openTermOfUsePopUp} popUpHeading="Terms Of Use" />
                                }
                                {
                                    openPrivacyPolicyPopUp && <StaticModal modalBody={<PrivacyPolicy />} setOpenPrivacyPolicyPopUp={setOpenPrivacyPolicyPopUp} openPrivacyPolicyPopUp={openPrivacyPolicyPopUp} popUpHeading="MINDPEERS PRIVACY POLICY" />
                                }

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}

export default WorkplaceCalculateSection
