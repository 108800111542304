import React from 'react'
import { Route, Switch } from 'react-router-dom';
import MatchTherapyFormPage from '../pages/matchTherapyForm/MatchTherapyFormPage';


const PrivateRoute = () => {
    const checkAuthenticity = () => {
        if (window.localStorage.getItem('userAuthToken')) return true
        else return false
    }
    return (
        <Switch>
            {/* <Route exact path="/get-matched" component={MatchTherapyFormPage} /> */}
        </Switch>
    )
}

export default PrivateRoute;
