import React, { useEffect } from 'react';
import DesktopBar from "./DesktopTopBar";
import MobileTopBar from "./MobileTopBar";
import TopInfoBar from "./TopInfoBar";
//this component is navbar and holds all the page navigation links 
//and we are using nav link to bold the current link
//more info on NavLink https://reactrouter.com/web/api/NavLink

export const TopBar = (props) => {
    const isMobile = window.innerWidth <= 500;
    return (
        <div>
            <TopInfoBar isMobile={isMobile}/>
            {isMobile ? <MobileTopBar {...props} /> : <DesktopBar {...props} />}
        </div>
    )
}
