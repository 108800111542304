import React from 'react'
import BoatDetailComponent from '../../components/BoatDetailComponent';
import { TopBar } from '../../components/common/Topbar/TopBar';
import { connect } from 'react-redux';

const BoatDetail = ({ TopBarDisplay: { openTopBar } }) => {
    return (
        <main>
            {openTopBar ? <TopBar /> :
                (<>
                    <TopBar />
                    <BoatDetailComponent />
                </>
                )}
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(BoatDetail);
