import React, { useState } from 'react'
import useForm from '../../../utils/useForm'
import formValidation from '../../../utils/formValidation'
import IntialState from "../../../default_config/default_intial_state"
import saveAccessFormData from "../../../api/sendRequests";
import CommonModal from '../../common/modalfolder/CommonModal';
import StaticModal from '../../common/modalfolder/StaticModal';
import TermsOfUse from '../../../static/TermsOfUse';
import PrivacyPolicy from '../../../static/PrivacyPolicy';
import { facebookHelper } from '../../../utils/_helper';
import { useSelector } from 'react-redux';

const GetAccessForm = () => {

    //this componenets helps us in Organisation page in GetAccessForm section
    //to validate the form we are using useForm custom hook and formvalidation js 

    const { phonePrefixList } = useSelector((state) => state.commonState);
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const [mobileCode, setMobileCode] = useState("+91") // use for set mobile code

    const [openTermOfUsePopUp, setOpenTermOfUsePopUp] = useState(false); // for constantly changing modelValue
    const [openPrivacyPolicyPopUp, setOpenPrivacyPolicyPopUp] = useState(false); // for constantly changing modelValue

    const placeholdercolor = "form-control span-error is-danger";
    const formControl = "form-control";

    const intialState = IntialState.therapisitSignUpDefaultState.getAccess;

    const formValues = async() => {
        //callback function after form succesfully submited
        let cond = true

        let updatedValues = { ...values, phone: mobileCode + '-' + values.phone, via: "Access Form" }

        let objValue = Object.keys(values)

        if (objValue.length > 0) {
            for (let i = 0; i < objValue.length; i++) {
                if (!values[objValue[i]]) {
                    cond = false
                    break;
                }
            }
        }
        if (objValue.length === 0) cond = false;

        if (cond) {
            saveAccessFormData(updatedValues).then((res) => {
                facebookHelper.leadOperation();    
                console.log(res)
            });
            // if(resp.ResponseCode === "200"){
            setMobileCode('+91')
            // }
        }
    }

    const {
        //handles form validation and error messages displaying
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(formValues, formValidation.validate, intialState, { componentComingFrom: "accessForm", mobileCode });

    return (
        <section id="get-access">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h3 className="get-access-heading text-md-center"><b>Have Any Questions?{/**Ask Away Your Questions */}</b></h3>
                        <p className="get-access-lead-text text-md-center">Every business is different. If there is something specific you need help with, please reach out</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12">
                        <form className="calculate-form" id="create-course-form" onSubmit={handleSubmit} noValidate>
                            <div className="form-group row align-items-center mb-0 mb-md-4">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Name:</label>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <input type="text" onChange={handleChange} className={errors.name ? placeholdercolor : formControl} name="name" id="" placeholder={errors.name || "Name"} value={values.name || ''} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Phone:</label>
                                        <div className="col-md-12 d-flex align-item-center custom-country-dropdown-get-access">
                                            <div className="mr-3">
                                                <select className="form-control" value={mobileCode} onChange={(e) => setMobileCode(e.target.value)}>
                                                    {/* <option value="+91">+91</option>
                                                    <option value="+65">+65</option> */}
                                                    {
                                                        phonePrefixList?.map((prefix , i)=>(
                                                            <option key={i} value={prefix}>{prefix}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="flex-grow-1">
                                                <input type="tel" onChange={handleChange} className={errors.phone ? placeholdercolor : formControl} id="" name="phone" placeholder={errors.phone || "Phone No."} value={values.phone || ''} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row align-items-center mb-0 mb-md-4">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Email:</label>
                                        <div className="col-12">
                                            <input type="email" className={errors.email ? placeholdercolor : formControl} onChange={handleChange} id="" name="email" placeholder={errors.email || "Email"} value={values.email || ''} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Designation:</label>
                                        <div className="col-12">
                                            <input type="text" className={errors.designation ? placeholdercolor : formControl} onChange={handleChange} name="designation" placeholder={errors.designation || "Designation"} value={values.designation || ''} required id="" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group row align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Company:</label>
                                        <div className="col-12">
                                            <input type="text" onChange={handleChange} className={errors.company ? placeholdercolor : formControl} id="" name="company" placeholder={errors.company || "Company"} value={values.company || ''} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                    <div className="row align-items-center">
                                        <label className="col-12 col-form-label">Message:</label>
                                        <div className="col-12">
                                            <input type="text" className={errors.message ? placeholdercolor : formControl} onChange={handleChange} name="message" placeholder={errors.message || "Please share your questions here"} value={values.message || ''} required id="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                errors.email === 'Only work emails are allowed' &&
                                <div className="text-center">
                                    <span className="text-danger">{errors.email} *</span>
                                </div>
                            }
                            <div className="row align-items-center justify-content-center mt-md-5 mt-3">
                                <button type="submit" className="btn btn-get-access-submit" onClick={() => { changemodelValue(!modelValue); setShowModel(true); }}>Submit</button>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="privacy-policy-box" style={{ "--font-size": "14px" }}>
                                        <p>By clicking 'SUBMIT' , you consent to the use of your personal data for marketing purposes and also agree to MindPeers's <u className="cursor-pointer" onClick={() => setOpenTermOfUsePopUp(true)}>Terms of Use</u> and <u className="cursor-pointer" onClick={() => setOpenPrivacyPolicyPopUp(true)}>Privacy Policy</u></p>
                                    </div>
                                </div>
                            </div>
                            <CommonModal modelValue={modelValue} showModel={showModel} formData={values} errorMessage={errors} />
                            {
                                openTermOfUsePopUp && <StaticModal modalBody={<TermsOfUse />} setOpenTermOfUsePopUp={setOpenTermOfUsePopUp} openTermOfUsePopUp={openTermOfUsePopUp} popUpHeading="Terms Of Use" />
                            }
                            {
                                openPrivacyPolicyPopUp && <StaticModal modalBody={<PrivacyPolicy />} setOpenPrivacyPolicyPopUp={setOpenPrivacyPolicyPopUp} openPrivacyPolicyPopUp={openPrivacyPolicyPopUp} popUpHeading="MINDPEERS PRIVACY POLICY" />
                            }
                        </form>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default GetAccessForm
