import React from 'react';

const LeftBar = (props) => {
    //this component provides information about Blog LeftBar section and comes under community/blog page

    return (
        <>
            <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                <div className="blog-side-widget">
                    <div className="blog-by">
                        <div className="d-flex align-items-center">
                            <div className="bw-img"></div>
                            <div className="bw-content">
                                <h4>By Kevin Guo</h4>
                                <p>March 2, 2020</p>
                            </div>
                        </div>

                        <div className="bw-like">
                            <ul className="list-unstyled mb-0">
                                <li>
                                    <a href="#">
                                        <img src="/img/clapping-active.svg"></img>
                                                        5.7K
                                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={() => props.handleCommentClick(!props.isCommentClick)}>
                                        <img src="/img/speech-bubble-active.svg"></img>
                                                        100
                                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeftBar;
