import React, { useState } from 'react';
import { Select, Space } from 'antd';
import 'antd/dist/antd.css';
const MultiSelectDropDown = (props) => {
  // this multi select is plugin for more info https://ant.design/components/select/
  // this select dropdown which is Multi select we can use it in Therapisit sign up form page 

  const [value, setValue] = useState([]);
  const changeHandler = (newValue) => {
    // helps to set a selected value to dropdown 
    setValue(newValue);
  }
  const selectProps = {
    mode: 'multiple',
    style: {
      width: '100%',
    },
    maxTagCount: 'responsive',
  };
  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} >
        <Select {...selectProps} name={"language"}
          placeholder={props.placeholder}
          value={props.value || []}
          options={props.options} className="" onChange={(newValue) => props.handleChange(newValue, props.name)}
          onSelect={(newValue) => changeHandler(newValue)} showSearch={false} showArrow={true} />
      </Space>
    </>
  );
}

export default MultiSelectDropDown;
