import defaultState from '../../default_config/default_intial_state'

const matchTherapyFormData = {
    basicInfoScreenOne : defaultState.therapisitSignUpDefaultState.basicInfoScreenOne,
    basicInfoScreenTwo : defaultState.therapisitSignUpDefaultState.basicInfoScreenTwo,
    preferencesScreenOne : defaultState.therapisitSignUpDefaultState.preferencesScreenOne,
    preferencesScreenTwo : defaultState.therapisitSignUpDefaultState.preferencesScreenTwo,
    logisticScreenOne : defaultState.therapisitSignUpDefaultState.logisticScreenOne,
}

export default (state = matchTherapyFormData , action) => {
  switch (action.type) {
    case 'FILL_Basic_Info_Screen1_DATA':
        return {
            ...state,
            basicInfoScreenOne : action.payload
        }
    case 'FILL_Basic_Info_Screen2_DATA':
        return {
            ...state,
            basicInfoScreenTwo : action.payload
        }   
    case 'FILL_PREFERENCES_Info_Screen1_DATA':
        return {
            ...state,
            preferencesScreenOne : action.payload
        }  
    case 'FILL_PREFERENCES_Info_Screen2_DATA':
        return {
            ...state,
            preferencesScreenTwo : action.payload
        } 
    case 'FILL_Logistics_Info_Screen1_DATA':
        return {
            ...state,
            logisticScreenOne : action.payload
        }               
    case 'RESET_DATA':
        return {
            ...state,
            ...action.payload
        }
    default:
      return {
          ...matchTherapyFormData
      };
  }
};