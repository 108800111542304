
export const __setStep1 = (data) => (dispatch) => {
    console.log("data",data)
    dispatch({
        type: 'FILL_STEP_ONE_DATA',
        payload: data
    });
}  

export const __setStep2 = (data) => (dispatch) => {
    console.log("data2222",data)
    dispatch({
        type: 'FILL_STEP_TWO_DATA',
        payload: data
    });
}

export const __setStep3 = (data) => (dispatch) => {
    console.log("data333",data)
    dispatch({
        type: 'FILL_STEP_THREE_DATA',
        payload: data
    });
}

export const __resetAllSteps = (data) => (dispatch) => {
    console.log("data2222",data)
    dispatch({
        type: 'RESET_DATA',
        payload: data
    });
}

