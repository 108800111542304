import axios from "axios";

const apiUrl = "https://api-prod.mindpeers.co/graph-api";

const resolve = responseData => {
    let resultant = {};
    if (responseData.errors)
        resultant.error = {
            errors: [...responseData.errors]
        };
    else resultant.error = responseData.error || null;
    resultant.data = responseData.data || null;
    return resultant;
};

/*
    Function returns {error, data} and can be of 3 forms only
        1. data             (200 response)
        2. data = undefined (network related issue)
        3. data = null      (query issue)
*/
const api = async(query, variables = null) => {
    let data = {
        query: query
    };
    if (variables) {
        data.variables = variables;
    }
    let resultant = {};
    try {
        let { data: response } = await axios({
            method: "POST",
            url: apiUrl,
            data: data
        });
        resultant = resolve(response);
    } catch (err) {
        console.log('api error', err);
        if (err.response) {
            let { data: response } = err.response;
            resultant = resolve(response);
        } else {
            resultant = { error: err.message, data: undefined };
        }
    }
    return resultant;
};

export default api;