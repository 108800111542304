import React from 'react'
import BlogComponent from '../../components/Blog';
import { TopBar } from '../../components/common/Topbar/TopBar';



const Community = () => {
    const buttonState = true;
    return (
        <main>
            <TopBar hideButton={buttonState} />
            <BlogComponent />
        </main>
    )
}

export default Community;
