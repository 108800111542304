const boatState = {
  boatList: [],
  filters: [],
  selectedFilter: [],
  boatByID : {}
};

export default (state = boatState, action) => {
  switch (action.type) {
    case "SET_BOAT_LIST_DATA":
      return {
        ...state,
        boatList: action.payload,
      };
    case "SET_BOAT_BY_ID_DATA":
      return {
        ...state,
        boatByID: action.payload,
      };
    case "SET_BOAT_SELECTED_FILTER":
      return {
        ...state,
        selectedFilter: action.payload,
      };
    case "SET_BOAT_FILTER":
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
