import React from 'react'
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import SmallPackageCards from './SmallPackageCards'
const GiftPlans = () => {
    const GiftPlans = {
        infinite: false,
        slidesToShow: 3,
        initialSlide: 1,
        speed: 500,
        className: "planSlider",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            }
        ]
    }
    const plans = useSelector(({ fetchTherapists: { therapyGiftPlans } }) => therapyGiftPlans)
    return (
        <>
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                <div className="how-it-work-wrapper">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="work-count mr-xl-4 mr-lg-4 mr-md-2 mr-sm-2 mr-2">1</div>
                        <h1 className="how-it-work-title">CHOOSE YOUR GIFT!</h1>
                    </div>
                </div>
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    plans.length > 0 &&
                    <Slider {...GiftPlans}>
                        {
                            plans.map((plan, i) => (
                                <div key={plan.id}>
                                    <SmallPackageCards
                                        packageName={plan.name}
                                        noOfSessions={plan.units?.session}
                                        planId={plan.id}
                                        indexNo={i}
                                        btnTitle="SELECT PACKAGE"
                                        time={parseInt(plan.time)}
                                        currency={plan.region?.currency?.symbol || 'INR'}
                                        price={plan.summary?.per_base || 0}
                                    />
                                </div>
                            ))
                        }
                    </Slider>
                }
                {/* <div className="row pb-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="text-center seasion-footer-list">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">*prices are exclusive of GST</li>
                                <li className="list-inline-item">Session balance should be used before expiry.</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p className="imp-text text-center">Prices Are Exclusive of Taxes. All Session Credits can only be used before expiry.</p>
            </div>
        </>
    )
}

export default GiftPlans
