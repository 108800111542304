import React from 'react'
import Slider from "react-slick";
const WhyMindpeers = () => {

    const goals = {
        infinite: true,
        slidesToShow: 3,
        initialSlide: 0,
        speed: 500,
        className: "goalSlider",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            }
        ]
    }
    let isMobile = false;
    if (window.innerWidth <= 500)
        isMobile = true;
    // slick slider
    return (
        <div className="container-whmp">
            <div className="row">
                {!isMobile && <div className="col-md-5 col-12">
                    <img src="/img/illustration why mp.png" className="why-mp" />
                </div>}
                <div className="col-md-5 col-12">
                    <div className="ml-3 m-md-0">
                        <p className="sub-lead">Why MindPeers</p>
                        <h3 className="sub-lead-home-title">
                        You build body strength for physical fitness. At MindPeers, workout your mind to become mentally strong.
                        </h3>
                    </div>
                    {/* <div className="row"> */}
                    <Slider {...goals}>
                        <div className="goals">
                            <img src="/img/mental health.svg" alt="" />
                            <div className="why-mindpeers-goals">
                                <div className="small-vertical-line"></div>
                                <div>
                                    <div className="goal">Feel Better</div>
                                    <div className="goal-heading">Learn to cope with emotions</div>
                                </div>
                            </div>
                        </div>
                        <div className="goals">
                            <img src="/img/personal enhan.svg" alt="" />
                            <div className="why-mindpeers-goals">
                                <div className="small-vertical-line"></div>
                                <div>
                                    <div className="goal">Think Rationally</div>
                                    <div className="goal-heading">Respond to unhelpful thoughts</div>
                                </div>
                            </div>
                        </div>
                        <div className="goals">
                            <img src="/img/prof growth.svg" alt="" />
                            <div className="why-mindpeers-goals">
                                <div className="small-vertical-line"></div>
                                <div>
                                    <div className="goal">Behave Positively</div>
                                    <div className="goal-heading">Enhance behavioral patterns</div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    {/* </div> */}
                </div>
            </div >
            {isMobile && <div className="col">
                <img src="/img/illustration why mp.png" className="w-100" />
            </div>}
        </div >
    )
}

export default WhyMindpeers
