import React from 'react';

const OurInvestors = () => {
     //this OurInvestors components helps in about-us page
    return (
        <>
            <section className="our-investor-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                            <p className="sub-lead">Our Investors</p>
                            <h3 className="sub-lead-title mb_65">See what our customers love about us. Employees want a workplace that prioritises mental wellness. Peace of mind and happiness is just as important for them as career growth.</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="investor-left">
                                <div className="investor-box text-center">
                                    <div className="investor-img"></div>
                                    <div className="investor-name">Anjali Sharma</div>
                                    <div className="investor-designation">CEO</div>
                                    <div className="investor-info">Employees want a workplace that prioritises mental wellness.</div>
                                </div>
                                <div className="investor-box text-center">
                                    <div className="investor-img"></div>
                                    <div className="investor-name">Anjali Sharma</div>
                                    <div className="investor-designation">CEO</div>
                                    <div className="investor-info">Employees want a workplace that prioritises mental wellness.</div>
                                </div>
                            </div>
                            <div className="investor-right text-right">
                                <div className="investor-box text-center">
                                    <div className="investor-img"></div>
                                    <div className="investor-name">Anjali Sharma</div>
                                    <div className="investor-designation">CEO</div>
                                    <div className="investor-info">Employees want a workplace that prioritises mental wellness.</div>
                                </div>
                                <div className="investor-box text-center">
                                    <div className="investor-img"></div>
                                    <div className="investor-name">Anjali Sharma</div>
                                    <div className="investor-designation">CEO</div>
                                    <div className="investor-info">Employees want a workplace that prioritises mental wellness.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OurInvestors;
