import React, { useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomToast = (props) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setOpen(false);
    };

    useEffect(() => {
        props.setOpen(props.open)
    }, [props.open])

    return (
        <Snackbar open={props.open} autoHideDuration={props.time} onClose={handleClose}>
            <Alert onClose={handleClose} severity={props.severity}>
                {props.message}
            </Alert>
        </Snackbar>
    )
}

CustomToast.propTypes = {
    time: PropTypes.number.isRequired,
    severity: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    setOpen: PropTypes.func.isRequired
}

export default CustomToast
