import React from 'react';
import { useSelector } from "react-redux"
import { Link } from 'react-router-dom';

const ProfileAbout = () => {
    //this componenets helps us in Profile page in About section
    const therapist = useSelector(({ fetchTherapists: { therapist } }) => therapist)
    return (
        <>
            <div className="row">
                <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                    <div className="title">About</div>
                    <p>{therapist.about}</p>
                    {/* <div className="custom-btn custom-stress-tags">
                        {therapist.specialities.map((speciality) => <Link key={speciality.id} to="#!">{speciality.name}</Link>)}
                    </div> */}
                </div>
            </div>
        </>
    );
}

export default ProfileAbout;
