import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { showTherapistSpeciality, writeTherapistProfileString } from "../../../../../utils/formatData"
import { getRedirectionUrl } from '../../../../../utils/_helper';
const ProfileHeader = () => {
    //this componenets helps us in Profile page in Header section
    const therapist = useSelector(({ fetchTherapists: { therapist } }) => therapist)


    return (
        <>
            <Link className="back-btn" to={getRedirectionUrl("/therapy/meet-our-experts/")}><i
                className="fas fa-chevron-left mr-1"></i>Back</Link>
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                            <h1 className="profile-name">{therapist.user.name} </h1>
                            <p className="profile-gender">({therapist.pronouns})</p>
                            <div className="destination">{showTherapistSpeciality(therapist.specialisations)}</div>
                            <div className="destination2">{writeTherapistProfileString(therapist.degrees)}</div>

                        </div>
                        {
                            therapist?.schedule && 
                            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 text-xl-right text-lg-right text-md-left text-sm-left">
                                <button type="button" className="btn booknow-btn" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/book-therapist/${therapist.user.id}`)}`), "_blank")}>Book Now</button>
                            </div>
                        }
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <img src={therapist.user.profile || "/img/cardimg.svg"} className="img-fluid profile-img" alt="profile image" />
                </div>
            </div>
        </>
    );
}

export default ProfileHeader;
