import React from 'react'
import VentOutWall from "./VentOutWall"
import Footer from "../../Footer"
import BackToTop from "../../BackToTop"
const VentOutComp = () => {
    return (
        <>
            <VentOutWall />
            <Footer />
            <BackToTop />
        </>
    )
}

export default VentOutComp
