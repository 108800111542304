import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ventoutAction from '../../../../store/actions/ventout.action';
import CustomToast from "../../../../components/common/Toast/CustomToast"
import { ToastConstant } from '../../../../default_config/toastConstant'
import { formatVentOutDate } from "../../../../utils/formatData"
import $ from "jquery"
const VentOutMessages = () => {


    const dispatch = useDispatch();
    const { messages } = useSelector(({ ventOut: { messages } }) => ({ messages }))

    const defaultMessageShowReplies = {};
    const defaultMessageReplies = {};
    messages.forEach((message) => {
        defaultMessageShowReplies[message.id] = false;
        defaultMessageReplies[message.id] = ""
    })
    const [messageShowReplies, changeMessageShowReplies] = useState(defaultMessageShowReplies)

    const [showReplyBoxs, changeReplyBoxs] = useState(defaultMessageShowReplies);
    const [messageReplies, changeMessageReplies] = useState(defaultMessageReplies);
    const [parentReply, changeParentReply] = useState("")
    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })

    const addParentReply = (event) => {
        event.preventDefault();
        if (parentReply) {
            dispatch(ventoutAction.addVentOutChild(parentReply))
            setOpen(true)
            setToastData(ToastConstant.SUBMITTED_SUCCESSFULLY)
            changeParentReply("")
        } else {
            setOpen(true)
            setToastData(ToastConstant.EMPTY_VENT)
        }
    }

    const changeChildReply = (e, id) => {
        changeMessageReplies({ ...messageReplies, [id]: e.target.value })
    }
    const addChildReply = (id) => {
        if (messageReplies[id]) {
            dispatch(ventoutAction.addVentOutChild(messageReplies[id], id))
            changeMessageReplies({ ...messageReplies, [id]: "" })
            setOpen(true)
            setToastData(ToastConstant.SUBMITTED_SUCCESSFULLY)
            $('html, body').animate({
                scrollTop: $(".vent-out-wall").offset().top
            }, 1000);
        } else {
            setOpen(true)
            setToastData(ToastConstant.EMPTY_VENT)
        }
    }
    const showMoreReplies = (id) => {
        changeMessageShowReplies({ ...messageShowReplies, [id]: true })

    }

    const hideReplies = (id) => {
        changeMessageShowReplies({ ...messageShowReplies, [id]: false })
    }

    const openChildReplyBox = (id) => {
        changeReplyBoxs({ ...showReplyBoxs, [id]: true })
    }

    const closeChildReplyBox = (id) => {
        changeReplyBoxs({ ...showReplyBoxs, [id]: false })
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="vent-out-title">Vent Out Wall</div>
                    <h3 className="sub-lead-small-title mb-50">No judging and no obligations. Simply let it out, anonymously.</h3>
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="vent-wrapper-bg">

                        <div className="vent-out-feeling-wrapper position-absolute">
                            <div className="vent-out-heading">Vent out your feelings</div>
                            <div className="vent-out-textarea">
                                <textarea placeholder="Write your feelings here." value={parentReply} onChange={(e) => changeParentReply(e.target.value)}></textarea>
                            </div>
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div className="submit-anonymous">
                                    * Your Vents Will Remain Anomymous
                                </div>
                                <div className="submit-user">
                                    <button type="button" className="btn submit-user-btn" onClick={addParentReply}><img src="img/paper-plane.svg" /> Submit</button>
                                </div>
                            </div>
                        </div>


                        <div className="vent-inner-wrapper">
                            {
                                messages.map((message) => <div className="user-reply-wrapper" key={message.id}>
                                    <div className="d-flex">
                                        <div className="v-user-img">
                                            <img src="/img/ionic-md-person.svg" alt="" />
                                        </div>
                                        <div className="vent-user-reply-content flex-grow-1 mb-3 w-80">
                                            <p className="text-break"><span className="user">Anonymous</span> {message.message}</p>
                                            <div className="d-flex justify-content-between">
                                                <div className="vent-user-meta">
                                                    <div className="post-meta">
                                                        <ul className="list-inline mb-0">
                                                            {<li className="list-inline-item">{formatVentOutDate(message.created_at)}</li>}
                                                            {/* <li className="list-inline-item">{formatVentOutDate(message.created_at).date}</li> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="user-msg-reply" onClick={() => openChildReplyBox(message.id)}>Reply</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showReplyBoxs[message.id] && <div className="show-all-replies-wrapper">
                                        <div className="user-reply-wrapper">
                                            <div className="d-flex justify-content-between">
                                                <div className="v-user-img">
                                                    <img src="/img/ionic-md-person.svg" alt="" />
                                                </div>
                                                <div className="w-80">
                                                    <div className="reply-textarea" id="replyText">
                                                        <textarea placeholder="We’re here for you and your needs whether it is finding out or acting upon." value={messageReplies[message.id]} onChange={(e) => changeChildReply(e, message.id)} rows="20" cols="100" autofocus={true}></textarea>
                                                    </div>
                                                    <div className="reply-actions-vent-out">
                                                        <span className="cancel-reply" onClick={() => closeChildReplyBox(message.id)}>Cancel</span>
                                                        <button type="button" className="btn reply-btn" onClick={() => addChildReply(message.id)}>Reply<img src="img/paper-plane.svg" /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {message.replies > 0 && !messageShowReplies[message.id] && <div className="view-all-msg" onClick={() => showMoreReplies(message.id)}>
                                        <hr />
                                        <span >{message.replies == 1 ? `View reply` : `View all ${message.replies} replies`}</span>
                                    </div>}
                                    {message.replies > 0 && messageShowReplies[message.id] && <div className="show-all-replies-wrapper">
                                        {message.childMessages.map((childMessage) => <div key={childMessage.id} className="user-reply-wrapper">
                                            <div className="d-flex">
                                                <div className="v-user-img">
                                                    <img src="/img/ionic-md-person.svg" alt="" />
                                                </div>
                                                <div className="vent-user-reply-content flex-grow-1 w-80 text-break">
                                                    <p><span className="user">Anonymous</span> {childMessage.message}</p>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="vent-user-meta">
                                                            <div className="post-meta">
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item">{formatVentOutDate(childMessage.created_at)}</li>
                                                                    {/* <li className="list-inline-item">{formatVentOutDate(childMessage.created_at).date}</li> */}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>)}
                                        <div className="view-all-msg" onClick={() => hideReplies(message.id)}>
                                            View Less
                                        </div>
                                    </div>}
                                </div>

                                )
                            }
                        </div>
                    </div>

                </div>

            </div>
            {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }
        </>
    )
}

export default VentOutMessages;
