import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import useForm from "../../../utils/useForm"
import formValidation from "../../../utils/formValidation"
import IntialState from "../../../default_config/default_intial_state"
import CommonModal from "../../common/modalfolder/CommonModal"
import CommonService from "../../../api/services/CommonService"
import CustomToast from "../../../components/common/Toast/CustomToast"
import { ToastConstant } from '../../../default_config/toastConstant'
import { useSelector } from 'react-redux';
const ContactUsModal = (props) => {

    const placeholdercolor = "form-control span-error is-danger";
    const formControl = "form-control";

    const { phonePrefixList } = useSelector((state) => state.commonState);

    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })

    useEffect(() => {
        if (props.showModel) // to ensure when button is clicked model is opened
            setShow(true);
    }, [props.modelValue, props.showModel]);

    const intialState = IntialState.therapisitSignUpDefaultState.contactUs;

    const formValues = () => {
        CommonService.contactus(values).then((res) => {
            if (res) {
                setShowModel(true); changemodelValue(!modelValue);

                setTimeout(() => {
                    setShow(false);
                    setShowModel(false);
                    changemodelValue(!modelValue);
                }, [5000])
            } else {
                setOpen(true)
                setToastData(ToastConstant.API_ERROR)
            }
        })

    }
    const { values, errors, handleChange, handleSubmit } = useForm(
        formValues,
        formValidation.contactUs,
        intialState,
        //{ componentComingFrom: "contactus" }
    );

    return (
        <>
            {/* <button type="submit" className="btn btn-calculate-now">Calculate Now</button> */}

            <Modal
                show={show}
                onHide={handleClose}
                dialogClassName="custom-m-w contact-us-modal"
                centered
            >

                <Modal.Body>
                    <span className="cancel-btn" onClick={() => setShow(false)} ><i className="fa fa-times" ></i></span>
                    <div className="contact-us-modal-content">
                        <p className="contact-heading">Contact Us</p>
                        <div className="contact-input-box">
                            <div className="input-name">Name:</div>
                            <input type="text" onChange={handleChange} className={errors.name ? placeholdercolor : formControl} name="name" id="" placeholder={errors.name || "Name"} value={values.name || ''} />
                        </div>
                        <div className="contact-input-box">
                            <div className="input-name" >Email:</div>
                            <input type="text" onChange={handleChange} className={errors.email ? placeholdercolor : formControl} name="email" id="" placeholder={errors.email || "Email"} value={values.email || ''} />
                        </div>

                        <div className="contact-input-box">
                            <div className="input-name">Phone:</div>
                            <div className="d-flex">
                                <select value={values.mobileCode || '+91'} onChange={handleChange} name="mobileCode" >
                                    {/* <option value="+91">+91</option>
                                    <option value="+65">+65</option> */}
                                    {
                                        phonePrefixList?.map((prefix , i)=>(
                                            <option key={i} value={prefix}>{prefix}</option>
                                        ))
                                    }
                                </select>
                                <input type="text" onChange={handleChange} className={errors.phone ? placeholdercolor : formControl} name="phone" id="" placeholder={errors.phone || "Phone"} value={values.phone || ''} />
                            </div>
                        </div>
                        <div className="contact-input-box">
                            <div className="input-name">Message:</div>
                            <textarea onChange={handleChange} className={errors.message ? placeholdercolor : formControl} name="message" id="" placeholder={errors.message || "Any message For Us"} value={values.message || ''} />
                        </div>
                        {errors.phone === "Please enter a valid phone number *" && <p className="text-danger text-center">{errors.phone}</p>}
                        <button type="button" className="send-message" onClick={handleSubmit}>SEND MESSAGE</button>
                    </div>
                    <CommonModal modelValue={modelValue} showModel={showModel} formData={values} errorMessage={errors} />
                </Modal.Body>
            </Modal>
            {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }
        </>
    );
}

export default ContactUsModal;