import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom';
import Organisation from '../pages/organisation/Organisation';
import Formpage from '../pages/formpage/FormPage';
import HomePage from "../pages/homePage/HomePage"
import Selfcare from '../pages/selfcare/Selfcare';
import Therapy from '../pages/therapy/Therapy';
import Community from '../pages/community/Community';
import MeetOurExpert from '../pages/therapy_subpage/MeetOurExpert';
import Profile from '../pages/therapy_subpage/Profile';
import AboutUs from '../pages/aboutus/AboutUs'
import Blog from '../pages/community_subpage/Blog'
import SignUp from '../pages/auth/SignUp';
import TherapisitSignUp from '../pages/therapisit_signup/TherapisitSignUp';
import DoctorLogin from "../pages/therapy_subpage/DoctorLogin"
import GiftTherapy from "../pages/GiftTherapy/GiftTherapy"
import VentOutWall from "../pages/ventOutWall/VentOutWall"
import PrivateRoute from './PrivateRoute';
import BoatDetail from '../pages/community_subpage/BoatDetail';
import TermsOfUse from '../static/TermsOfUse';
import PrivacyPolicy from '../static/PrivacyPolicy';
import Disclaimer from '../static/Disclaimer';
import CommunityGuidelines from '../static/CommunityGuidelines';
import PageNotFound from '../components/common/NotFound/PageNotFound'
import TermsAndUse from '../static/TermsAndUse';
import FounderNote from '../pages/founder-note/FounderNote';


const PublicRoute = () => {
    const routePath = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [routePath])
    return (
        <Switch>
            <Route exact path='/' component={HomePage} />
            <Route path='/organisation' component={Organisation} />
            {/* <Route path='/form-page' component={Formpage} /> */}
            <Route path="/selfcare" component={Selfcare} />
            <Route exact path="/therapy" component={Therapy} />
            <Route exact path="/therapy/meet-our-experts" component={MeetOurExpert} />
            <Route path="/therapy/meet-our-experts/profile/:id" component={Profile} />
            <Route exact path="/community" component={Community} />
            <Route exact path="/community/:id" component={BoatDetail} />
            <Route path="/community/blog/id" component={Blog} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/therapist-sign-up" component={TherapisitSignUp} />
            <Route exact path="/therapist-log-in" component={DoctorLogin} />
            <Route exact path="/therapy/gift-packages" component={GiftTherapy} />
            <Route exact path="/vent-out" component={VentOutWall} />
            <Route exact path='/terms-and-condition' component={TermsOfUse} />
            <Route exact path='/terms-and-use' component={TermsAndUse} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/community-guidelines' component={CommunityGuidelines} />
            <Route exact path='/mci-disclaimer' component={Disclaimer} />
            <Route exact path='/founders-note' component={FounderNote} />
            <Route path='*' render={() => <PageNotFound />} />
            <PrivateRoute />
        </Switch>
    )
}

export default PublicRoute;
