import React from 'react'
import { useSelector } from "react-redux"
import { TopBar } from '../../components/common/Topbar/TopBar';
import AboutUsComponenet from '../../components/AboutUs'
const AboutUs = () => {
    const openTopBar = useSelector(({ TopBarDisplay: { openTopBar } }) => openTopBar)
    return (
        <main>
            {openTopBar ? <TopBar /> :
                (<>
                    <TopBar />
                    <AboutUsComponenet />
                </>
                )}
        </main>
    )
}

export default AboutUs;
