import dayjs from 'dayjs';
import React from 'react'
import Slider from "react-slick";
import { Player, ControlBar, VolumeMenuButton } from 'video-react';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay';
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider';
import { getRedirectionUrl } from '../../../../utils/_helper';

const BoatDescription = ({ boatById, boatBooking, loader, type = '' }) => {

    const boatLeadingCarousel = {
        // Slider plugin is used more info  visit  https://www.npmjs.com/package/react-slick
        infinite: false,
        arrows: true,
        dots: false,
        prevArrow: <img className="slick-prev" src="/img/left_arrow.svg" />,
        nextArrow: <img className=" next slick-next" src="/img/right_arrow.svg" />,
        slidesToShow: 1,
        mobileFirst: true,
        variableWidth: true,
        responsive: [
            {

                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    infinite: false,
                    rtl: true
                }

            },
            {

                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    dots: false,
                    infinite: false,
                    variableWidth: false
                }

            },
            {

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    infinite: false,
                    variableWidth: false
                }

            }, {

                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    infinite: false,
                    variableWidth: false
                }

            },
        ]
    }

    return (
        <div>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="
                                d-flex 
                                flex-xl-nowrap flex-lg-nowrap flex-md-nowrap flex-sm-wrap flex-wrap
                                justify-content-xl-between justify-content-lg-between 
                                justify-content-md-between justify-content-sm-start 
                                justify-content-start">
                        <div>
                            <div className="boat-desc-detail boat-desc-detail-title">
                                <h2>{boatById.title}</h2>
                                {/* <p>{boatById.description}</p> */}
                                <div dangerouslySetInnerHTML={{ __html: boatById.description }}></div>
                            </div>
                        </div>
                        <div className="flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 flex-sm-grow-1 flex-grow-1">
                            <a href={getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`boat/${boatById.slug}/?landing=1`)}`) /*`${process.env.REACT_APP_DASHBOARD_URL}?redirect=boat/${boatById.slug}`*/} target="_blank" className="boat-btn cursor-pointer register-boat" rel="noreferrer">SIGN UP TO JOIN @ ₹{boatById.price}</a>
                        </div>
                    </div>
                    <div className="boat-desc-detail mb-5">
                        <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                            {
                                boatById.categories && boatById.categories.length > 0 &&
                                boatById.categories.map((tag, i) => (
                                    <div key={i} className="filter selected mr-3">{tag}</div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="boat-desc-detail mb-5">
                        <div className="d-flex align-items-center justify-content-between flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                            <div>
                                <h4 className="ready-boat-title">Are you ready for the boat?</h4>
                            </div>
                            <div>
                                <span className="boat-desc-timing mb-0"><strong>{dayjs(boatById.start_at).format("MMMM D, YYYY")}</strong> FROM {dayjs(boatById.start_at).format("h:mm A")} TO {dayjs(boatById.end_at).format("h:mm A")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="boat-leading-slider mb-5">
                        {
                            boatById.cards && boatById.cards.length > 0 &&
                            <Slider {...boatLeadingCarousel}>
                                {
                                    boatById.cards.map((card, i) => (
                                        <div className="boat-leading-wrapper" key={i} style={{ width: 450 }}>
                                            <h3>{card.title}</h3>
                                            {/* <ul class="list-unstyled mb-0"> */}
                                            {
                                                card.data && card.data.length > 0 &&
                                                card.data.map((item, j) => (
                                                    <div key={j} dangerouslySetInnerHTML={{ __html: item }}></div>
                                                ))
                                            }
                                            {/* </ul> */}
                                        </div>
                                    ))
                                }
                            </Slider>
                        }
                    </div>

                    <div className="common-que-threapy-wrapper pt-5">
                        <h3 className="font-24 text-capitalize text-primary-color font-bold mb-5">Want more details? We've got you covered</h3>

                        <div id="video-container">
                            <Player
                                muted
                                src={`${boatById.video || "/video/video-playback-2.mp4"}`}

                            >
                                <BigPlayButton position="center" />
                                <ControlBar autoHide={true}>
                                    <CurrentTimeDisplay order={4.1} />
                                    <TimeDivider order={4.2} />
                                    <VolumeMenuButton vertical />
                                </ControlBar>
                            </Player>
                        </div>

                    </div>

                    {/* <div className="boat-desc-detail mb-5">
                    <h3 className="font-24 text-primary-color avenir-demi">Code of Conduct</h3>
                    <p className="font-18 text-primary-color avenir-regualr lh-initial">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
                </div> */}
                </div>
            </div>
        </div>
    )
}

BoatDescription.propTypes = {

}

export default BoatDescription
