
import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import ProfileComponent from '../../components/sub_components/therapy/meetourexperts/profile/Profile';
import { TopBar } from '../../components/common/Topbar/TopBar';
import fetchTherapists from "../../store/actions/fetchTherapist.action"

const Profile = () => {
    const dispatch = useDispatch()
    const params = useParams()
    useEffect(() => {
        dispatch(fetchTherapists.fetchTherapist(params.id))
        dispatch(fetchTherapists.fetchTherapistReviews(params.id))
        dispatch(fetchTherapists.fetchSimilarTherapist(params.id))
    }, [])
    return (
        <main>
            <TopBar />
            <ProfileComponent />
        </main>
    )
}

export default Profile;
