import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
// pick a date util library
import MomentUtils from '@date-io/dayjs';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import MultiSelectDropDown from '../../../common/SelectDropDown/MultiSelectDropDown';
import default_dropdown_list from '../../../../default_config/default_dropdown_list';
import useGetMatchForm from '../../../../utils/useGetMatchForm';
import formValidation from "../../../../utils/formValidation";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __basicInfoScreenOne } from '../../../../store/actions/matchTherapy.action'
import dayjs from 'dayjs';

const BasicInfoScreenOne = (props) => {

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    if(date && date.$d){
      let newDate = dayjs(date.$d).format('YYYY-MM-DD');
      setSelectedDate(newDate);
      console.log(newDate, "dob");
      handleChange(newDate, "dob");
    }else{
      return
    }
  };

  const formValues = () => {
    //call back function after the form submit

    props.setPage(2)
    props.setStepName('Basic Info')
    props.__basicInfoScreenOne(values);
  };
  const { values, errors, handleChange, handleSubmit } = useGetMatchForm(
    //sends/recieve our values and errors to formvalidation.js and useform custom hooks
    formValues,
    formValidation.basicInfoformScreen1,
    props.basicInfoScreenOne,
    {}
  );

  return (
    <>
      <form className="step-form" onSubmit={handleSubmit} noValidate>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="row mb-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <h3 className="page-inner-title">What is your date of birth? {errors.dob && <span className="text-danger error">* Required</span>}</h3>
              <div className="">
                <KeyboardDatePicker
                  // margin="normal"
                  label="Date of Birth"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  variant="inline"
                  inputVariant="outlined"
                  disableFuture={true}
                  name="dob"
                  value={values.dob ? new Date(values.dob) : selectedDate}
                  onChange={(e) => handleDateChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <h3 className="page-inner-title">
                What languages are you comfortable to take your sessions in?
                (please select at least two)  {errors.language && <span className="text-danger error">* Required</span>}
              </h3>
              <div>
                <MultiSelectDropDown
                  value={values.language}
                  options={default_dropdown_list.langauges}
                  //   errorLanguage={errors.language}
                  handleChange={handleChange}
                  name={"language"}
                />
              </div>
            </div>
          </div>
        </MuiPickersUtilsProvider>
        <div className="row">
          <div className="col-md-12">
            <div className="start-now text-right">
              {/* <a className="btn btn-start-now" onClick={() => handleNext()}>NEXT</a> */}
              <button
                type="submit"
                className="btn btn-start-now"
              // onClick={() => handleNext()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
const mapStateToProps = ({ matchTherapyFormData }) => ({
  ...matchTherapyFormData
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    __basicInfoScreenOne
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BasicInfoScreenOne));
