import { useState, useEffect } from 'react';

const useForm = (callback, validate, intialState = {}, condition = {}) => {

    const [values, setValues] = useState(intialState);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            callback()
            //if (condition.componentComingFrom == "workplaceCalculation" || condition.componentComingFrom == "accessForm" || condition.componentComingFrom == "contactus")
            setValues(intialState)
        }
    }, [errors]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values, condition));
        setIsSubmitting(true);
    };

    const handleChange = (event) => {

        event.preventDefault();
        if (event) {

            Object.keys(errors).map(err => {
                if (err === event.target.name) {
                    delete errors[err]
                }
            })
            setValues(values => ({ ...values, [event.target.name]: event.target.value }));
        }
    };



    return {
        handleChange,
        handleSubmit,
        values,
        errors,
    }
};

export default useForm;