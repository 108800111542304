import api from "./ApiService";
import ip from 'public-ip';

export default class TherapyService {
  //Guest api call
  static async listAll(limit = 100, offset = 0) {
    let response = await api(
      `query {
            listLanguages(
                limit: ${limit}
                offset: ${offset}
            ) {
                id
                name
                code
            }
            listDegrees{id name code}
            listSpecialisations{id name code}
            enums(name:"EnumDoctorExperience") {key value}
        }`
    );
    if (response.error !== null) {
      return false;
    } else {
      return response.data;
    }
  }
  static async signUpTherapist(therapistData, expKeys) {
    const exp = expKeys[therapistData.step2.workexperience]
    const lanagguesArr = JSON.stringify(therapistData.step1.language)
    const otherLngs = JSON.stringify(therapistData.step1.otherLanguages)
    const deg = JSON.stringify(therapistData.step2.degree)
    const spec = JSON.stringify(therapistData.step2.specialization)
    const otherDegs = JSON.stringify(therapistData.step2.otherDegrees)
    const otherspecs = JSON.stringify(therapistData.step2.otherSpecialization)
    const certificates = JSON.stringify(therapistData.step2.degrees_S3)
    let ipAdd = ""
    try { ipAdd = await ip.v4() } catch { }
    const ownSpace = therapistData.step3.radio_own_space == "yes" ? true : false
    let response = await api(
      `mutation signupDoctor($about: String! , $spaceAddress: String){
        signupDoctor(
          name:"${therapistData.step1.fName} ${therapistData.step1.lName}",
          email:"${therapistData.step1.email}",
          password:"${therapistData.step1.password}",
          contact:{code:"${therapistData.step1.mobileCode}" mobile:"${therapistData.step1.number}"},
          gender:"${therapistData.step1.gender}"
          dob: "${therapistData.step1.dob}"
          pronouns:"${therapistData.step1.pronoun}"
          location:{country:"India" state:"${therapistData.step1.state}" city: "${therapistData.step1.city}" }
          languages:{languages:${lanagguesArr} others:${otherLngs}}
          degrees:{degrees:${deg} others:${otherDegs}}
          specialisations:{specialisations:${spec} others:${otherspecs}}
          rci:"${therapistData.step2.rcino}"
          experience:${exp}
          ownSpace:${ownSpace}
          profile:"${therapistData.step1.picture_S3}"
          resume:"${therapistData.step2.resume_S3}"
          certificates:${certificates}
          about:$about
          spaceAddress:$spaceAddress
          ip:"${ipAdd}"
        ){
          verifyToken
        }
      }`, { about: therapistData.step3.myself, spaceAddress: therapistData.step3.address })
    if (response.error !== null) {
      return response;
    } else {
      return response.data;
    }
  }
  static async checkDuplicateUser(EmailId, mobileCode, phone) {
    let response = await api(
      `query {
          checkUser(email: "${EmailId}" contact: {code:"${mobileCode}" mobile:"${phone}"})
        }`
    );
    if (response.error !== null)
      return response.error
    else
      return response.data;
  }
  static async listDoctors(home, limit = 100) {
    let response = await api(
      `	query {
          listDoctors(limit:${limit} ${home ? "home:true" : ""}){
            id
            user{name id rating profile}
            specialisations{name}
            degrees{name}
            experience
            schedule
            pronouns
        }
      }
      `
    );
    if (response.error !== null)
      return response.error
    else
      return response.data.listDoctors;
  }
  static async getDoctor(id) {
    let response = await api(
      `query {
          getDoctor(doctor: "${id}") {
            user {
              id
              name
              rating
              profile
            }
            degrees { name }
            specialisations{ name}
            pronouns
            about
            schedule
      }
    }`
    );
    if (response.error !== null)
      return response.error
    else
      return response.data.getDoctor;
  }
  static getTherapistReviews = async (id) => {
    let response = await api(`
    query{
      listReviews(doctor:"${id}")
      }   
    `
    )
    return response.data.listReviews;
  }

  static getSimilarDoctors = async (id) => {
    let response = await api(`
    query {
    listSimilarDoctors(doctor:"${id}"){
      id
      user{name id rating profile}
      specialisations{name}
      degrees{name}
      pronouns
    } 
  }
    `
    )
    return response.data.listSimilarDoctors;
  }

  static listPlansByType = async (type, limit = 100, offset = 0) => {
    let countryCodeString = "";
    try {
      let userDetails = await fetch(
        `https://geolocation-db.com/json/${process.env.REACT_APP_GEO_KEY}`
      );

      userDetails = await userDetails.json();
      countryCodeString = `countryCode:"${userDetails.country_code}"`;
    } catch (error) {}

    let response = await api(`
    query {
      listPlansByType(
        type:GIFT ${countryCodeString}){
        id
          units{
            session
            boat
          }
          name
          time
          code
          type
          description
          price
          standard
          discount
          is_tax_inclusive
          region {
            currency {
              code
              symbol
            }
          }
          summary{
            per_base
          }
      }
    }
`);
    return response.data.listPlansByType;
  };

}