import React, { useEffect } from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar'
import OrganisationComponent from '../../components/Organisation'
import { connect } from 'react-redux'

const Organisation = ({ TopBarDisplay: { openTopBar } }) => {
    useEffect(() => {

        var newMeta = document.createElement('meta');
        newMeta.name = "keywords"
        newMeta.content = "MindPeers , therapy, mindpeers organisation, mental, mental therapy"
        document.getElementsByTagName('head')[0].appendChild(newMeta);

        let meta = document.getElementsByTagName('meta')[3]
        meta.content = "This is MindPeers organisation page and here you find about our organisation"

        // document.title = "MP | Organisation"
    }, [])
    return (
        <main>
            {openTopBar ? <TopBar teamCare={true} /> :
                (<>
                    <TopBar teamCare={true} />
                    <OrganisationComponent />
                </>
                )
            }
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(Organisation);
