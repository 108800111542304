import React, { useEffect } from 'react'
import Slider from "react-slick";

const Testimonial = (props) => {
    // this is  Testimonial section in organisation page and it also image slider carousel 
    let multiFeedBack = []
    for (let i = 1; i <= props.points; i++)
        multiFeedBack.push(props.points);
    const slickRequirement = {
        // Slider plugin is used more info  visit  https://www.npmjs.com/package/react-slick
        infinite: true,
        arrows: true,
        dots: false,
        slidesToShow: 3.8,
        mobileFirst: true,
        prevArrow: <img className=" next slick-next" src="img/left_arrow.svg" alt=" image" />,
        nextArrow: <img className="slick-prev" src="img/right_arrow.svg" alt="d image" />,
        responsive: [
            {

                breakpoint: 1440,
                settings: {
                    slidesToShow: 2.5,
                    infinite: true,
                    rtl: true
                }

            },
            {

                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                    dots: false,
                    infinite: true,
                }

            }, {

                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    infinite: true,
                }

            }
        ]
    }

    return (
        <section id="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                        <p className="sub-lead">Testimonials</p>
                        <h3 className="sub-lead-title">{props.testimonialSubheading}</h3>
                    </div>
                </div>

            </div>
            <div className="testimonials-slide">
                <Slider {...slickRequirement}>
                    {
                        multiFeedBack.map((_fb, index) => {
                            return (
                                <div key={index} className={`${props.teamCareTestimonail ? "testimonials-box" : "testimonials-small-box"}`} >
                                    {props.teamCareTestimonail &&
                                        <div className="row">
                                            <div className="col-7 col-sm-6">
                                                <img src={`img/${props.imageLogos[index]}`} className={`${props.imageAdjust ? "logo-adjust" : ""}`} />
                                            </div>
                                            <div className="col-5 col-sm-6">
                                                <img src="img/group.svg" alt={`image-${index}`} className="float-right" />
                                            </div>
                                        </div>
                                    }
                                    {
                                        props.teamCareTestimonail ? (<div className="testimonials-name">{props.designations[index]}</div>) :
                                            (<>
                                                <div className="testimonials-name">{props.TestimonialNames ? props.TestimonialNames[index] : "Anjali Sharma"}</div>
                                            </>)
                                    }

                                    <div className="testimonials-message">{props.testimonialCardText[index]}                       </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            </div>
        </section >
    )
}

export default Testimonial
