import React from 'react'
import PropTypes from 'prop-types'
import { getRedirectionUrl } from '../../../utils/_helper'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
// import {__setGiftPackageName} from '../../../../store/actions/giftTherapy.action'

const SmallPackageCards = (props) => {

    const btnAction = () => {
        window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/gift-to-friend/recipient-form/${props.planId}`)}`), "_blank")
    }

    return (
        <div className="package-pd-100">
            <div className={`package text-center session-plan${props.indexNo === 1 ? ' reco' : ''}`}>
                <div className="package-content">
                    <div className="gift package-title">
                        <h2>{props.packageName}</h2>
                        {
                            props.indexNo === 1 &&
                            <p className="gift-to-friend-plan">Recommended</p>
                        }
                    </div>
                    <div className="gift package-price">
                        <h3>{props.noOfSessions} Sessions <br /> {props.currency} {props.price}/-</h3>
                    </div>
                    <p class="package-validity mt-0 mb-3"><strong>Per Session</strong></p>
                    <div class="package-sub-title"><p>{parseInt((props.time) / (1000 * 60 * 60 * 24))} Days Validity</p></div>
                    <div className="package-footer">
                        <button type="button" onClick={() => btnAction()} className="btn btn-send-package">{props.btnTitle}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

SmallPackageCards.propTypes = {
    packageName: PropTypes.string,
    noOfSessions: PropTypes.number,
    price: PropTypes.number,
    btnTitle: PropTypes.string,
    from: PropTypes.string,
    indexNo: PropTypes.number,
}

export default SmallPackageCards
