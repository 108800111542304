import React from 'react';
import Slider from "react-slick";

const OurTeam = () => {
    //this OurTeam components helps in about-us page
    const multiFeedBack = [1, 2, 3, 4, 5, 6]
    const slickRequirement = {
        // Slider plugin is used more info  visit  https://www.npmjs.com/package/react-slick
        infinite: true,
        arrows: true,
        dots: false,
        slidesToShow: 3.8,
        mobileFirst: true,
        prevArrow: <img className=" next slick-next" src="img/left_arrow.svg" alt=" image" />,
        nextArrow: <img className="slick-prev" src="img/right_arrow.svg" alt="d image" />,
        responsive: [
            {

                breakpoint: 1440,
                settings: {
                    slidesToShow: 2.5,
                    infinite: true,
                    rtl: true
                }

            },
            {

                breakpoint: 768,
                settings: {
                    slidesToShow: 2.5,
                    dots: false,
                    infinite: true,
                }

            }, {

                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    infinite: true,
                }

            }
        ]
    }

    return (
        <>
            <section id="our-team">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                            <p className="sub-lead">Our Team</p>
                            <h3 className="sub-lead-title mb_65">See what our customers love about us. Employees want a workplace that prioritises mental wellness. Peace of mind and happiness is just as important for them as career growth.</h3>
                        </div>
                    </div>

                </div>
                <div className="testimonials-slide about-slides">
                    <Slider {...slickRequirement}>
                        {
                            multiFeedBack.map((_fb, index) => {
                                return (
                                    <div key={index} className="team-wrapper team-bg">
                                        <div className="team-content position-relative">
                                            <div className="team-member-name">Anjali Sharma</div>
                                            <div className="team-member-designation">CEO</div>
                                            <div className="team-member-message">Employees want a workplace that prioritises mental wellness.</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default OurTeam;
