import React from 'react'
import Slider from "react-slick";
import { getRedirectionUrl } from '../../../utils/_helper';
const MindpeersServices = () => {
    const services = {
        infinite: true,
        slidesToShow: 3,
        initialSlide: 1,
        speed: 500,
        className: "serviceSlider",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            }
        ]
    }
    return (
        <div className="container adjust-service-cont">
            <p className="sub-lead">Our Services</p>
            <div className="row">
                <div className="col-md-8">
                    <h3 className="sub-lead-title mb-0">
                    Choose from our wide spectrum of clinically validated modules and personalized programs
                    </h3>
                </div>
            </div>
            <div className="row mt-5">
                <Slider {...services} >
                    <div className="service-card">
                        <img src="/img/selfcare.svg" />
                        <div className="card-headings">
                            <div className="heading">SELF CARE</div>
                            <div className="sub-heading">Optimize your feelings, thoughts and behaviors.</div>
                        </div>
                        <div className="action" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}>Become Mentally Strong</div>
                    </div>
                    <div className="service-card">
                        <img src="/img/mindcare index.svg" />
                        <div className="card-headings">
                            <div className="heading">MINDCARE INDEX</div>
                            <div className="sub-heading">Find what makes you, you. </div>
                        </div>
                        <div className="action" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}>Measure your Mental Strength</div>
                    </div>
                    <div className="service-card">
                        <img src="/img/therapist matching.svg" />
                        <div className="card-headings">
                            <div className="heading">THERAPIST MATCHING</div>
                            <div className="sub-heading">Get the right help. Talk to someone who truly gets you.</div>
                        </div>
                        <div className="action" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/get-matched`)}`), "_blank")}>Meet Your Therapist</div>
                    </div>
                </Slider>
            </div>
        </div >
    )
}

export default MindpeersServices

// 