


import React from 'react'

const StarRating = ({ stars: { number, decimal } }) => {
    return (
        <ul className="list-inline mb-0">
            {[...Array(number)].map((i) => <li key={i} className="list-inline-item">
                <i className="fas fa-star"></i>
            </li>
            )}
            {decimal && <li className="list-inline-item">
                <i className="fas fa-star-half" ></i>
            </li>}

        </ul>
    )
}

export default StarRating
