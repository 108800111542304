import React from "react";
import { useSelector } from "react-redux";
import LoadingComp from "../../common/LoadingComp";
import GiftPlans from "./GiftPlans";

const GiftPackageComp = () => {
  const loadedGiftPlans = useSelector(
    ({ fetchTherapists: { loadedGiftPlans } }) => loadedGiftPlans
  );
  return (
    <section id="home">
      <div className="container">
        <div className="row min-vh-100 align-items-center py-10 overflow-hidden">
          {
            loadedGiftPlans ? <GiftPlans /> : <LoadingComp text="Loading..." />
          }
        </div>
      </div>
    </section>
  );
};

export default GiftPackageComp;
