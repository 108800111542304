import React from 'react'
import './WordSlider.css'

export default function WordSlider() {
    return (
        
        <div className ="content" >
            <div className="slider-wrapper">   
            Are you
                <div className="animated-text">
                <div className="line line-1">Feeling low?</div>
                <div className="line line-2">Burnt out?</div>
                <div className="line line-3">Overthinking?</div>
                <div className="line line-4">Mentally strong?</div>
                </div>
            </div>
        </div>  
    )
}
