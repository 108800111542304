import React from 'react';
import { withRouter } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const OurBlog = (props) => {
    const location = useLocation();
    //this componenets helps us in community page in OurBlog section
    return (
        <>
            <section className="our-blog-section our-blog-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="sub-lead">Our Blog</p>
                            <h3 className="sub-lead-title mb-50">Stories to inspire and best practices to become better. Motivation when you need it the most. Enjoy our reads.</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="blog-teaser-wrapper" onClick={() => props.history.push({
                                    pathname: '/community/blog/id',
                                    search: `${location.search}`
                                })}>
                                <div className="blog-teaser-img"><img src="https://picsum.photos/317/300" alt="" title="" /></div>
                                <div className="blog-teaser-heading">We’re here for you and your needs whether it</div>
                                <div className="blog-teaser-content">We’re here for you and your needs whether it is finding out or acting upon whether it is finding out or . We’re here for you and your needs whether it is finding out or acting upon whether it is finding out or .</div>
                                <div className="post-meta">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item read-more">Read More</li>
                                        <li className="list-inline-item read-time">4 min read</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="sub-blog-teaser-wrapper mb-10 " onClick={() => props.history.push({
                                    pathname: '/community/blog/id',
                                    search: `${location.search}`
                                })}>
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <div className="sub-blog-heading">We’re here for you and your needs whether it</div>
                                        <div className="sub-blog-content">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</div>
                                        <div className="post-meta">
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item read-more">Read More</li>
                                                <li className="list-inline-item read-time">4 min read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <div className="sub-blog-img"><img src="https://picsum.photos/317/300" alt="" title="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="sub-blog-teaser-wrapper mb-2" onClick={() => props.history.push({
                                    pathname: '/community/blog/id',
                                    search: `${location.search}`
                                })}>
                                <div className="row">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <div className="sub-blog-heading">We’re here for you and your needs whether it</div>
                                        <div className="sub-blog-content">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero</div>
                                        <div className="post-meta">
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item read-more">Read More</li>
                                                <li className="list-inline-item read-time">4 min read</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <div className="sub-blog-img"><img src="https://picsum.photos/317/300" alt="" title="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default withRouter(OurBlog);
