import React from 'react';
// import ReportFooter from '../components/sub_components/ReportFooter';

const CommunityGuidelines = () => {
  return (
    <main>
      <div className="container">
        <article id="termsOfUse">
          <h1 className="hr-below">Community Guidelines</h1>
          <p>Hey there,</p>
          <p>
            This is a safe space for people to share what’s on their mind. It’s
            open for everyone to vent their worries, share their stress, talk
            about things that matter to them or simply celebrate small wins with
            our community.
          </p>
          <p>
            We at Mindpeers believe that community support goes a long way in
            helping people cope with the bad times in their lives.While we put
            no restrictions on what you share, we’d like you to keep in mind a
            few community guidelines here: .
          </p>
          <ol className="abc">
            <li>
              Refrain from diagnosing mental health issues or giving
              professional / medical advice.
            </li>
            <li>
              Do not share your personal details like phone number, email ID,
              address or social media identities etc.
            </li>
            <li>
              Kindly refrain from posting any religious, political, sexist,
              ethnically or racially offensive, or obscene vents or replies.
            </li>
            <li>
              This is a no bullying zone. Do not harass, abuse, intimidate or
              spam people who post or reply to comments here.
            </li>
            <li>
              Any statements of intent to commit violence against any person,
              group of people or place (city or smaller) are prohibited and can
              and may be reported to law enforcement. In addition, we do not
              allow people to use the MindPeers platform to incite violence or
              to encourage, co-ordinate or direct others to commit violence.
            </li>
            <li>
              Please add a ‘trigger warning' toggle whenever you’re posting
              something that you think might trigger someone reading it.
            </li>
          </ol>
          <p>
            In case any of the above guidelines are violated, MindPeers reserves
            the right to hide or delete a post, or ban an account without prior
            notification to the user. MindPeers may also add a trigger warning
            to a post if we feel that a person might get triggered by reading
            it.
          </p>
          <p>That’s it! Happy venting. :)</p>
          <p>Love,</p>
          <p>Team MindPeers</p>
        </article>
      </div>
      {/* <ReportFooter /> */}
    </main>
  );
};

export default CommunityGuidelines;
