import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PublicRoute from './routers/PublicRoute';
import CommonAction from './store/actions/commonAction';
// import VersionModal from './components/common/modalfolder/VersionModal';

function App() {

  const location = useLocation();
  const dispatch = useDispatch();
  const { phonePrefixList } = useSelector((state) => state.commonState);

  let [appVersion, setVersion] = useState(
    window.localStorage.getItem("version")
      ? Number(window.localStorage.getItem("version"))
      : 0
  );

  // const [modalHidden, setModalBool] = useState(true);

  const updateVersion = (version) => {
    window.localStorage.setItem("version", version + "");
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      caches.keys().then(function(names) {
        for (let name of names){
          caches.delete(name)
        }
      });
    }
    window.location.reload(true);
  }

  const getVersionJSON = mounted => {
    fetch("/app-version.json")
      .then(response => {
        if (!response.ok) {
          console.error("Error while getting app-version.json");
        }
        return response.json();
      })
      .then(json => {
        if (mounted) {
          updateVersion(json.version);
        };
        if (Number(window.localStorage.getItem("version")) < json.version) {
          // setModalBool(false);
          updateVersion(json.version);
        }
        setVersion(json.version);
      })
      .catch(err => {
        console.error("Error while getting app-version.json");
      });
  };
  const checkVersion = () => {
    if(!Number(window.localStorage.getItem("version"))) getVersionJSON(true);
    else getVersionJSON();
    setInterval(() => {
      getVersionJSON();
    }, 1800000);
    // interval set to a hour
  };

  
  useEffect(() => {
    checkVersion();
  }, [location.pathname]);

  // country code api call
  useEffect(()=>{
    if(phonePrefixList?.length === 0){
      dispatch(CommonAction.getAllCountryCode())
    }
  },[phonePrefixList])

  return (
    <Fragment>
      <PublicRoute />
      {/* <VersionModal show={modalHidden} setShow={setModalBool} appVersion={appVersion}/> */}
    </Fragment>
  );
}

export default App;
