
import React from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar';
import MeetOurExpertComponent from '../../components/MeetOurExpert';
import NavHeader from '../../components/sub_components/therapy/meetourexperts/NavHeader';


const MeetOurExpert = () => {
    const buttonState = true;
    return (
        <main>
            {/* <NavHeader /> */}
            <TopBar />
            <MeetOurExpertComponent />
        </main>
    )
}

export default MeetOurExpert;
