import React from 'react'
import { useState} from 'react'

const MultipleChoice = (props) => {

    const [multiCheckBox, setMultiCheckBox] = useState(props.value || [])

    const handleChange = (e) => {
        
        let newMultiCheck = multiCheckBox
        console.log("dataa",e);
        if(newMultiCheck.includes(e.target.value)){
            let index = newMultiCheck.findIndex(el => el === e.target.value)
            newMultiCheck.splice(index,1)
        }else{
            newMultiCheck.push(e.target.value)
        }
        setMultiCheckBox(newMultiCheck)
        props.handleChange(newMultiCheck,props.name)
        // console.log("dataa",multiCheckBox);
    }

    
    return (
        <div className="row mb-3">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3 className="page-inner-title">{props.ques}{props.error && <span className="text-danger error">* Required</span>}</h3>
                <div className="select-gender-option">
                    <ul className="list-unstyled">
                        {
                            props.options && props.options.length > 0 &&
                            props.options.map((option,i)=>(
                                <li key={i}>
                                    <div className="select-custom-checkbox">
                                        <input type="checkbox" value={option} checked={props.value.includes(option)?true:false} id={option} onChange={(e)=>handleChange(e)} name={props.name} />
                                        <label htmlFor={option}>{option}</label>
                                    </div>
                                </li>        
                            ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MultipleChoice
          