import React from "react";
import "./FoundersNote.css";

export default function FoundersNote() {
  return (
    <div className="py-10 container founders-note-wrapper">
      <div className="founders-note-container">
        <div className="founders-note-title">Note from Founder's Desk</div>
        <div className="founders-note-text">
          <span>Hello :)</span>
          <span>
            If you are hearing from me for the first time, I am Kanika
            (cofounder of MindPeers). I am writing to you with a big update and
            I request your few mins.
          </span>
          <span>
            MindPeers started in 2020, from my own struggle with panic attacks
            and inability to find that 'right therapist' in one go. It was
            frustrating but when I finally started my therapy journey it was
            nothing short of self discovery, aha moments and peace. My therapist
            has been my life anchor and I still continue to see her.
          </span>
          <span>
            I am telling you all this because what I experienced, I didnt wish
            it for anyone and with MindPeers, it was our attempt to make quality
            therapists accessible. You are getting this email, it means in 2022
            you trusted us to take care of your mental health. If you continued
            your journey, somewhere it meant that our therapist panel created a
            positive difference in your life.
          </span>
          <span>
            I hope they made you believe in the power of their profession and
            how 60mins can change so much in our life.
          </span>
          <span>
            We now want to improve. Bring a revolution through all our mistakes
            and learnings that we have gathered in the therapy space. As a
            result, effective from 14/1/2023, following upgrade will be seen on
            MindPeers therapy :
          </span>
          <span>
            1. A new therapist category called Superstars is being introduced.
            They are more specialized, experienced and they are one of their
            kind. As a result their fees will be what they truly deserve
          </span>
          <span>
            2. Standard prices for all therapies in general are being changed to
            Rs 1000 with 50% discount i.e. Rs 500 This upgrade is to maintain
            the quality we want to keep giving and to introduce you to new
            resources that you wouldn't have experienced anywhere in therapy
          </span>
          <span>3. We have removed the credit plan system</span>
          <span>
            We want to honour the importance of quality mental health and the
            expertise and years of training that our therapists bring with them.
          </span>
          <span>
            BUT, having said that, I also want to thank you for believing in us
            early on. As our token of appreciation, this is only for you ,we are
            giving a two month transition period wherein you can -
          </span>
          <span>
            1. Book therapy at old rates, this will be applicable until
            15/03/2023 time for you, post which new prices will be applicable
            for you
          </span>
          <span>2. Utilize your remaining credits</span>
          <span>
            Changes are tough. But they are indispensable for a better life. I
            seek your patience in this transition. I and my team promise to
            serve you with extraordinary care and quality.
          </span>
          <span>
            If you have any queries on the details of your therapy sessions,
            current or future, feel free to reply here or reach us through
            Whatsapp chat on +91 9810066307.
          </span>
          <span>Take care,</span>
          <span style={{ fontFamily: "AvenirNext-Bold" }}>
            Kanika on behalf of Team MindPeers
          </span>
        </div>
      </div>
    </div>
  );
}
