import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { TopBar } from "../../components/common/Topbar/TopBar"
import GiftPackages from "../../components/sub_components/GiftTherapy/GiftPackages"
import fetchTherapist from "../../store/actions/fetchTherapist.action"
const GiftTherapy = () => {
    const dispatch = useDispatch()
    const loadedGiftPlans = useSelector(({ fetchTherapists: { loadedGiftPlans } }) => loadedGiftPlans)
    useEffect(() => {
        if (!loadedGiftPlans)
            dispatch(fetchTherapist.setTherapyGiftPlans())
    }, [dispatch])
    return (
        <main>
            <TopBar />
            <GiftPackages />
        </main>
    )
}

export default GiftTherapy
