import React from 'react';
import ReportFooter from '../components/sub_components/ReportFooter';

const Disclaimer = () => {
  return (
    <main className="disclaimer-main">
      <div className="container">
        <article id="disclaimer">
          <h1 className="hr-below">
            Disclaimer
          </h1>
          <p>
            Disclaimer and Limitation of Liability: This test report is not for
            public distribution and has been kept confidential between the user
            and the tester. Users are assured that the information provided will
            not be reproduced or redistributed to others. Note that, users
            cannot use this report as a base for any claim, demand or cause of
            action and, also none is responsible for any loss incurred based
            upon. The report is based on the answers provided by the user in
            their test. Opinion expressed is the current opinion as of the date
            appearing on the material only. While every effort is made to ensure
            the accuracy and completeness of report provided, the company takes
            no responsibility and assumes no liability for any error/ omission
            or accuracy of the information. Recipients of this material should
            rely on their own judgments and conclusions from relevant sources
            before making any conclusions.
          </p>
        </article>
      </div>
      <ReportFooter />
    </main>
  );
};

export default Disclaimer;
