import React from 'react'
import SectionHeader from '../HeadingSection'
import TherapyHeader from './TherapyHeader'
import { getRedirectionUrl } from '../../../utils/_helper'
const SectionOne = () => {
    //this componenets helps us in Therapy page in header section
    //passing custom props to section header
    return (
        <section id="home">

            <SectionHeader
                heading="Therapy By MindPeers"
                firstClassName="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 py-8"
                secondClassName="col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12 text-right"
                description={`Find your safe space. Share what’s on your mind. Talk to a MindCoach`}
                imgPath={`img/illu.svg`}
                btnText="Book A session today!"
                executeBtnClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/get-matched`)}`), "_blank")}
            />
            <TherapyHeader />
        </section>
    )
}

export default SectionOne
