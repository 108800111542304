import React from 'react'
import { getRedirectionUrl } from '../../../utils/_helper'
const MindCareIndex = props => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12">
                    <p className="sub-lead">Have You Ever Got A Wellness Report For Your Mind</p>
                    <h3 className="sub-lead-title mb_65">Mindfully curated with accurate clinical parameters, here is a unique tool for you to gauge your mental well-being and its implications on your daily life.</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-5 col-12 d-md-flex align-items-md-center mb-sm-3">
                    <button className="btn btn-access-tool mb-5 mx-auto m-md-0 small" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}>calculate your mindcare index</button>
                </div>
                <div className="col-md-7 col-12">
                    <img src="img/mindcareindex.svg" className="img-fluid" alt="" title="" />
                </div>
            </div>
        </div>
    )
}

export default MindCareIndex
