import React from 'react';
import SearchBar from '../../SearchBar';
import ViewAllTherapists from './ViewAllTherapists';

const SectionOne = () => {
    //this componenets helps us in Therapy page in Header section
    return (
        <>
            <section id="home" className="therists-container">
                <div className="container">
                    <div className="row align-items-center meet-therapist ">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-8">
                            <h1 className="main-title">Meet Our Therapists</h1>
                            <p className="therpist-subhead">
                                Select your therapist from amongst some of the country's highest qualified professionals. Start your journey with therapy here.
                            </p>
                        </div>
                    </div>
                    <SearchBar />
                    <ViewAllTherapists />
                </div>
            </section>
        </>
    );
}

export default SectionOne;
