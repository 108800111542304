import React, { useEffect, useState } from 'react';
import LeftBar from './LeftBar';
import { Link } from 'react-router-dom';
import BlogComment from './BlogComment';
import BookMarkBlog from './BookMarkBlog';

const BlogContent = () => {
    //this component provides information about blog content section and comes under community/blog page
    const [isCommentClick, setIsCommentClick] = useState(false)

    return (
        <>
            <div id="blog-wrapper">
                <section className="blog-wrapper-bg"></section>
                <section className="blog-content">
                    <div className="container">
                        <div className="row blog-widget">
                            <LeftBar handleCommentClick={setIsCommentClick} isCommentClick={isCommentClick} />
                            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                                <div className="blog-inner-content">
                                    <div className="blog-title">Updated Best-in-Class Automated Content Moderation Model</div>
                                    <div className="blog-sub-title">Improved content moderation suite with additional subclasses; now performs better than human moderators</div>
                                    <div className="d-flex justify-content-between align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                                        <div>
                                            <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-nowrap">
                                                <div className="blog-user-img"></div>
                                                <div>
                                                    <h4 className="blog-user-name">By Kevin Guo</h4>
                                                    <p className="blog-post-date">March 2, 2020</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="blog-share-option">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-detail">
                                        <p>We were selective in our construction of the training dataset, and strategically added the most impactful training examples. For instance, we utilized active learning to select training images where the existing model results were the most uncertain. Deep learning models produce a confidence score on input images which ranges from 0 (very confident the image is not in the class) to 1.0 (very confident the image is in the class). By focusing our labeling efforts on those images in the middle range (0.4 - 0.6), we were able to improve model performance specifically on edge cases.</p>
                                        <p>As part of this release, we also focused on lessening ambiguity in our ‘suggestive’ class in the NSFW model. We conducted a large manual inspection of images where either Hive annotators tended to disagree, or even more crucially, when our model results disagreed with consented Hive annotations. When examining images in certain ground truth sets, we noticed that up to 25% of disagreements between model prediction and human labels were due to erroneous labels, with the model prediction being accurate. Fixing these ground truth images was critical for improving model accuracy. For instance, in the NSFW model, we discovered that moderators disagreed on niche cases, such as which class leggings, contextually implied intercourse, or sheer clothing fell into. By carefully defining boundaries and relabeling data accordingly, we were able to teach the model the distinction in these classes, improving accuracy by as much as 20%.</p>
                                        <p>Now classified as clean:</p>
                                        <div className="blog-detail-img">
                                            <div className="d-flex justify-content-between flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-nowrap">
                                                <div><img src="https://picsum.photos/seed/picsum/320/213" alt="" title="" /></div>
                                                <div><img src="https://picsum.photos/seed/picsum/320/213" alt="" title="" /></div>
                                                <div><img src="https://picsum.photos/seed/picsum/320/213" alt="" title="" /></div>
                                            </div>
                                        </div>
                                        <p className="fig-caption">Figure 1.1 Updated examples of images classified as clean - Source: Pexels, Unsplash</p>
                                        <p>Now classified as suggestive:</p>
                                        <div className="classified-wrapper">
                                            <div className="d-flex justify-content-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-nowrap">
                                                <div><img src="https://picsum.photos/seed/picsum/278/216" alt="" title="" /></div>
                                                <div><img src="https://picsum.photos/seed/picsum/278/216" alt="" title="" /></div>
                                            </div>
                                        </div>
                                        <p>We were selective in our construction of the training dataset, and strategically added the most impactful training examples. For instance, we utilized active learning to select training images where the existing model results were the most uncertain. Deep learning models produce a confidence score on input images which ranges from 0 (very confident the image is not in the class) to 1.0 (very confident the image is in the class). By focusing our labeling efforts on those images in the middle range (0.4 - 0.6), we were able to improve model performance specifically on edge cases.</p>
                                        <p>As part of this release, we also focused on lessening ambiguity in our ‘suggestive’ class in the NSFW model. We conducted a large manual inspection of images where either Hive annotators tended to disagree, or even more crucially, when our model results disagreed with consented Hive annotations. When examining images in certain ground truth sets, we noticed that up to 25% of disagreements between model prediction and human labels were due to erroneous labels, with the model prediction being accurate. Fixing these ground truth images was critical for improving model accuracy. For instance, in the NSFW model, we discovered that moderators disagreed on niche cases, such as which class leggings, contextually implied intercourse, or sheer clothing fell into. By carefully defining boundaries and relabeling data accordingly, we were able to teach the model the distinction in these classes, improving accuracy by as much as 20%.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="blog-comment-wrapper">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div>
                                            <div className="total-comment-wrapper">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="#"><img src="/img/clapping-active.svg" />5.7K claps</a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="cursor-pointer" onClick={() => setIsCommentClick(!isCommentClick)}><img src="/img/speech-bubble-active.svg" /> 100 comments</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="blog-share-option">
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            isCommentClick ? <BlogComment /> : ''
                        }
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <hr className="blog-hr"></hr>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    );
}

export default BlogContent;
