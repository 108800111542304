import React from 'react'
import { useHistory, useLocation } from "react-router-dom"

const TherapistLogin = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <section id="home">
            <div className="container">
                <div className="row min-vh-100 align-items-center">
                    <div className="col">
                        <div className="doctor-login-box">
                            <div className="upper-section">
                                <img src="/img/logowoText.png" alt="" />
                                <div className="login-cred">
                                    <i class="fas fa-unlock-alt"></i>
                                    <div className="text">
                                        <div className="heading">
                                            Login
                                     </div>
                                        <div className="sub-heading">
                                            Please enter your credentials to login.
                                     </div>
                                    </div>
                                </div>

                            </div>
                            <div className="lower-section">
                                <div className="email-section">
                                    <h6>Email</h6>
                                    <input type="text" placeholder="example@gmail.com" />
                                </div>
                                <div className="password-section">
                                    <h6>Password</h6>
                                    <input type="password" placeholder="******" />
                                </div>
                                <div className="action-section">
                                    <a href="">Forgot Password?</a>
                                    <div className="d-flex mt-2">
                                        <button type="button" className="btn login-btn">Login</button>
                                        <button type="button" className="btn register-btn" onClick={() => history.push({
            pathname: "/therapist-sign-up",
            search: `${location.search}`
        })}>Register</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TherapistLogin
