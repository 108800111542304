import React from 'react';
import OurMission from './OurMission';

const HeaderSection = () => {
    //this HeaderSection components helps in about-us page
    return (
        <>
        <section id="home">
            <div className="container">
                <div className="row min-vh-100 align-items-center py-10">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="about-us-vision text-center">
                            <h2>Our vision is to lorem ipsum do tu lorem ipsum</h2>
                            <p>Our mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to lorem ipsum do tu lorem ipsumOur mission is to</p>
                        </div>
                    </div>
                </div>
                <OurMission />
            </div>
        </section>
        </>
    );
}

export default HeaderSection;
