import TherapyService from "../api/services/TherapyService"
import { validateContact } from "./_helper"
const notAllowedEmail = [
    'gmail.com',
    'yahoo.com',
    'yahoo.co.in',
    'yahoo.in',
    'hotmail.com',
    'example.com',
    'mail.com',
    'ymail.com',
    'email.com',
    'abc.com',
    'xyz.com',
    'oalsp.com',
    'binka.me',
    'doanart.com',
    'p33.org',
    'bestvpn.top',
    '10vpn.info',
    'mailgov.info',
    'janproz.com',
    'pcmylife.com',
    'vpstraffic.com',
    'garage46.com',
    'buy003.com',
    'uscaves.com',
    'vektik.com',
    'amail.club',
    'cmail.club',
    'wmail.club',
    'banit.me',
    'nada.ltd',
    'duck2.club',
    'cars2.club',
    'nada.email',
    'sharklasers.com',
    'guerrillamail.info',
    'grr.la',
    'guerrillamail.biz',
    'guerrillamail.com',
    'guerrillamail.de',
    'guerrillamail.net',
    'guerrillamail.org',
    'guerrillamailblock.com',
    'pokemail.net',
    'spam4.me',
    'ibsats.com',
    'qiq.us',
    'u.0u.ro',
    'vlwomhm.xyz',
    '0815.ru',
    '0wnd.net ',
    '0wnd.org ',
    '10minutemail.co.za ',
    '10minutemail.com ',
    '123-m.com ',
    '1fsdfdsfsdf.tk ',
    '1pad.de ',
    '20minutemail.com ',
    '21cn.com ',
    '2fdgdfgdfgdf.tk ',
    '2prong.com ',
    '30minutemail.com ',
    '33mail.com ',
    '3trtretgfrfe.tk ',
    '4gfdsgfdgfd.tk ',
    '4warding.com ',
    '5ghgfhfghfgh.tk ',
    '6hjgjhgkilkj.tk ',
    '6paq.com ',
    '7tags.com ',
    '9ox.net ',
    'test.com',
    'test.in',
    'test.org',
    'test.co',
    'a-bc.net ',
    'agedmail.com ',
    'ama-trade.de ',
    'amilegit.com ',
    'amiri.net ',
    'amiriindustries.com ',
    'anonmails.de ',
    'anonymbox.com ',
    'antichef.com ',
    'antichef.net ',
    'antireg.ru ',
    'antispam.de ',
    'antispammail.de ',
    'armyspy.com ',
    'artman-conception.com ',
    'azmeil.tk ',
    'baxomale.ht.cx ',
    'beefmilk.com ',
    'bigstring.com ',
    'binkmail.com ',
    'bio-muesli.net ',
    'bobmail.info ',
    'bodhi.lawlita.com ',
    'bofthew.com ',
    'bootybay.de ',
    'boun.cr ',
    'bouncr.com ',
    'breakthru.com ',
    'brefmail.com ',
    'bsnow.net ',
    'bspamfree.org ',
    'bugmenot.com ',
    'bund.us ',
    'burstmail.info ',
    'buymoreplays.com ',
    'byom.de ',
    'c2.hu ',
    'card.zp.ua ',
    'casualdx.com ',
    'cek.pm ',
    'centermail.com ',
    'centermail.net ',
    'chammy.info ',
    'childsavetrust.org ',
    'chogmail.com ',
    'choicemail1.com ',
    'clixser.com ',
    'cmail.net ',
    'cmail.org ',
    'coldemail.info ',
    'cool.fr.nf ',
    'courriel.fr.nf ',
    'courrieltemporaire.com ',
    'crapmail.org ',
    'cust.in ',
    'cuvox.de ',
    'd3p.dk ',
    'dacoolest.com ',
    'dandikmail.com ',
    'dayrep.com ',
    'dcemail.com ',
    'deadaddress.com ',
    'deadspam.com ',
    'delikkt.de ',
    'despam.it ',
    'despammed.com ',
    'devnullmail.com ',
    'dfgh.net ',
    'digitalsanctuary.com ',
    'dingbone.com ',
    'disposableaddress.com ',
    'disposableemailaddresses.com ',
    'disposableinbox.com ',
    'dispose.it ',
    'dispostable.com ',
    'dodgeit.com ',
    'dodgit.com ',
    'donemail.ru ',
    'dontreg.com ',
    'dontsendmespam.de ',
    'drdrb.net ',
    'dump-email.info ',
    'dumpandjunk.com ',
    'dumpyemail.com ',
    'e-mail.com ',
    'e-mail.org ',
    'e4ward.com ',
    'easytrashmail.com ',
    'einmalmail.de ',
    'einrot.com ',
    'eintagsmail.de ',
    'emailgo.de ',
    'emailias.com ',
    'emaillime.com ',
    'emailsensei.com ',
    'emailtemporanea.com ',
    'emailtemporanea.net ',
    'emailtemporar.ro ',
    'emailtemporario.com.br ',
    'emailthe.net ',
    'emailtmp.com ',
    'emailwarden.com ',
    'emailx.at.hm ',
    'emailxfer.com ',
    'emeil.in ',
    'emeil.ir ',
    'emz.net ',
    'ero-tube.org ',
    'evopo.com ',
    'explodemail.com ',
    'express.net.ua ',
    'eyepaste.com ',
    'fakeinbox.com ',
    'fakeinformation.com ',
    'fansworldwide.de ',
    'fantasymail.de ',
    'fightallspam.com ',
    'filzmail.com ',
    'fivemail.de ',
    'fleckens.hu ',
    'frapmail.com ',
    'friendlymail.co.uk ',
    'fuckingduh.com ',
    'fudgerub.com ',
    'fyii.de ',
    'garliclife.com ',
    'gehensiemirnichtaufdensack.de ',
    'get2mail.fr ',
    'getairmail.com ',
    'getmails.eu ',
    'getonemail.com ',
    'giantmail.de ',
    'girlsundertheinfluence.com ',
    'gishpuppy.com ',
    'gmial.com ',
    'goemailgo.com ',
    'gotmail.net ',
    'gotmail.org ',
    'gotti.otherinbox.com ',
    'great-host.in ',
    'greensloth.com ',
    'grr.la ',
    'gsrv.co.uk ',
    'guerillamail.biz ',
    'guerillamail.com ',
    'guerrillamail.biz ',
    'guerrillamail.com ',
    'guerrillamail.de ',
    'guerrillamail.info ',
    'guerrillamail.net ',
    'guerrillamail.org ',
    'guerrillamailblock.com ',
    'gustr.com ',
    'harakirimail.com ',
    'hat-geld.de ',
    'hatespam.org ',
    'herp.in ',
    'hidemail.de ',
    'hidzz.com ',
    'hmamail.com ',
    'hopemail.biz ',
    'ieh-mail.de ',
    'ikbenspamvrij.nl ',
    'imails.info ',
    'inbax.tk ',
    'inbox.si ',
    'inboxalias.com ',
    'inboxclean.com ',
    'inboxclean.org ',
    'infocom.zp.ua ',
    'instant-mail.de ',
    'ip6.li ',
    'irish2me.com ',
    'iwi.net ',
    'jetable.com ',
    'jetable.fr.nf ',
    'jetable.net ',
    'jetable.org ',
    'jnxjn.com ',
    'jourrapide.com ',
    'jsrsolutions.com ',
    'kasmail.com ',
    'kaspop.com ',
    'killmail.com ',
    'killmail.net ',
    'klassmaster.com ',
    'klzlk.com ',
    'koszmail.pl ',
    'kurzepost.de ',
    'lawlita.com ',
    'letthemeatspam.com ',
    'lhsdv.com ',
    'lifebyfood.com ',
    'link2mail.net ',
    'litedrop.com ',
    'lol.ovpn.to ',
    'lolfreak.net ',
    'lookugly.com ',
    'lortemail.dk ',
    'lr78.com ',
    'lroid.com ',
    'lukop.dk ',
    'm21.cc ',
    'mail-filter.com ',
    'mail-temporaire.fr ',
    'mail.by ',
    'mail.mezimages.net ',
    'mail.zp.ua ',
    'mail1a.de ',
    'mail21.cc ',
    'mail2rss.org ',
    'mail333.com ',
    'mailbidon.com ',
    'mailbiz.biz ',
    'mailblocks.com ',
    'mailbucket.org ',
    'mailcat.biz ',
    'mailcatch.com ',
    'mailde.de ',
    'mailde.info ',
    'maildrop.cc ',
    'maileimer.de ',
    'mailexpire.com ',
    'mailfa.tk ',
    'mailforspam.com ',
    'mailfreeonline.com ',
    'mailguard.me ',
    'mailin8r.com ',
    'mailinater.com ',
    'mailinator.com ',
    'mailinator.net ',
    'mailinator.org ',
    'mailinator2.com ',
    'mailincubator.com ',
    'mailismagic.com ',
    'mailme.lv ',
    'mailme24.com ',
    'mailmetrash.com ',
    'mailmoat.com ',
    'mailms.com ',
    'mailnesia.com ',
    'mailnull.com ',
    'mailorg.org ',
    'mailpick.biz ',
    'mailrock.biz ',
    'mailscrap.com ',
    'mailshell.com ',
    'mailsiphon.com ',
    'mailtemp.info ',
    'mailtome.de ',
    'mailtothis.com ',
    'mailtrash.net ',
    'mailtv.net ',
    'mailtv.tv ',
    'mailzilla.com ',
    'makemetheking.com ',
    'manybrain.com ',
    'mbx.cc ',
    'mega.zik.dj ',
    'meinspamschutz.de ',
    'meltmail.com ',
    'messagebeamer.de ',
    'mezimages.net ',
    'ministry-of-silly-walks.de ',
    'mintemail.com ',
    'misterpinball.de ',
    'moncourrier.fr.nf ',
    'monemail.fr.nf ',
    'monmail.fr.nf ',
    'monumentmail.com ',
    'mt2009.com ',
    'mt2014.com ',
    'mycard.net.ua ',
    'mycleaninbox.net ',
    'mymail-in.net ',
    'mypacks.net ',
    'mypartyclip.de ',
    'myphantomemail.com ',
    'mysamp.de ',
    'mytempemail.com ',
    'mytempmail.com ',
    'mytrashmail.com ',
    'nabuma.com ',
    'neomailbox.com ',
    'nepwk.com ',
    'nervmich.net ',
    'nervtmich.net ',
    'netmails.com ',
    'netmails.net ',
    'neverbox.com ',
    'nice-4u.com ',
    'nincsmail.hu ',
    'nnh.com ',
    'no-spam.ws ',
    'noblepioneer.com ',
    'nomail.pw ',
    'nomail.xl.cx ',
    'nomail2me.com ',
    'nomorespamemails.com ',
    'nospam.ze.tc ',
    'nospam4.us ',
    'nospamfor.us ',
    'nospammail.net ',
    'notmailinator.com ',
    'nowhere.org ',
    'nowmymail.com ',
    'nurfuerspam.de ',
    'nus.edu.sg ',
    'objectmail.com ',
    'obobbo.com ',
    'odnorazovoe.ru ',
    'oneoffemail.com ',
    'onewaymail.com ',
    'onlatedotcom.info ',
    'online.ms ',
    'opayq.com ',
    'ordinaryamerican.net ',
    'otherinbox.com ',
    'ovpn.to ',
    'owlpic.com ',
    'pancakemail.com ',
    'pcusers.otherinbox.com ',
    'pjjkp.com ',
    'plexolan.de ',
    'poczta.onet.pl ',
    'politikerclub.de ',
    'poofy.org ',
    'pookmail.com ',
    'privacy.net ',
    'privatdemail.net ',
    'proxymail.eu ',
    'prtnx.com ',
    'putthisinyourspamdatabase.com ',
    'putthisinyourspamdatabase.com ',
    'qq.com ',
    'quickinbox.com ',
    'rcpt.at ',
    'reallymymail.com ',
    'realtyalerts.ca ',
    'recode.me ',
    'recursor.net ',
    'reliable-mail.com ',
    'rhyta.com ',
    'rmqkr.net ',
    'royal.net ',
    'rtrtr.com ',
    's0ny.net ',
    'safe-mail.net ',
    'safersignup.de ',
    'safetymail.info ',
    'safetypost.de ',
    'saynotospams.com ',
    'schafmail.de ',
    'schrott-email.de ',
    'secretemail.de ',
    'secure-mail.biz ',
    'senseless-entertainment.com ',
    'services391.com ',
    'sharklasers.com ',
    'shieldemail.com ',
    'shiftmail.com ',
    'shitmail.me ',
    'shitware.nl ',
    'shmeriously.com ',
    'shortmail.net ',
    'sibmail.com ',
    'sinnlos-mail.de ',
    'slapsfromlastnight.com ',
    'slaskpost.se ',
    'smashmail.de ',
    'smellfear.com ',
    'snakemail.com ',
    'sneakemail.com ',
    'sneakmail.de ',
    'snkmail.com ',
    'sofimail.com ',
    'solvemail.info ',
    'sogetthis.com ',
    'soodonims.com ',
    'spam4.me ',
    'spamail.de ',
    'spamarrest.com ',
    'spambob.net ',
    'spambog.ru ',
    'spambox.us ',
    'spamcannon.com ',
    'spamcannon.net ',
    'spamcon.org ',
    'spamcorptastic.com ',
    'spamcowboy.com ',
    'spamcowboy.net ',
    'spamcowboy.org ',
    'spamday.com ',
    'spamex.com ',
    'spamfree.eu ',
    'spamfree24.com ',
    'spamfree24.de ',
    'spamfree24.org ',
    'spamgoes.in ',
    'spamgourmet.com ',
    'spamgourmet.net ',
    'spamgourmet.org ',
    'spamherelots.com ',
    'spamherelots.com ',
    'spamhereplease.com ',
    'spamhereplease.com ',
    'spamhole.com ',
    'spamify.com ',
    'spaml.de ',
    'spammotel.com ',
    'spamobox.com ',
    'spamslicer.com ',
    'spamspot.com ',
    'spamthis.co.uk ',
    'spamtroll.net ',
    'speed.1s.fr ',
    'spoofmail.de ',
    'stuffmail.de ',
    'super-auswahl.de ',
    'supergreatmail.com ',
    'supermailer.jp ',
    'superrito.com ',
    'superstachel.de ',
    'suremail.info ',
    'talkinator.com ',
    'teewars.org ',
    'teleworm.com ',
    'teleworm.us ',
    'temp-mail.org ',
    'temp-mail.ru ',
    'tempe-mail.com ',
    'tempemail.co.za ',
    'tempemail.com ',
    'tempemail.net ',
    'tempemail.net ',
    'tempinbox.co.uk ',
    'tempinbox.com ',
    'tempmail.eu ',
    'tempmaildemo.com ',
    'tempmailer.com ',
    'tempmailer.de ',
    'tempomail.fr ',
    'temporaryemail.net ',
    'temporaryforwarding.com ',
    'temporaryinbox.com ',
    'temporarymailaddress.com ',
    'tempthe.net ',
    'thankyou2010.com ',
    'thc.st ',
    'thelimestones.com ',
    'thisisnotmyrealemail.com ',
    'thismail.net ',
    'throwawayemailaddress.com ',
    'tilien.com ',
    'tittbit.in ',
    'tizi.com ',
    'tmailinator.com ',
    'toomail.biz ',
    'topranklist.de ',
    'tradermail.info ',
    'trash-mail.at ',
    'trash-mail.com ',
    'trash-mail.de ',
    'trash2009.com ',
    'trashdevil.com ',
    'trashemail.de ',
    'trashmail.at ',
    'trashmail.com ',
    'trashmail.de ',
    'trashmail.me ',
    'trashmail.net ',
    'trashmail.org ',
    'trashymail.com ',
    'trialmail.de ',
    'trillianpro.com ',
    'twinmail.de ',
    'tyldd.com ',
    'uggsrock.com ',
    'umail.net ',
    'uroid.com ',
    'us.af ',
    'venompen.com ',
    'veryrealemail.com ',
    'viditag.com ',
    'viralplays.com ',
    'vpn.st ',
    'vsimcard.com ',
    'vubby.com ',
    'wasteland.rfc822.org ',
    'webemail.me ',
    'weg-werf-email.de ',
    'wegwerf-emails.de ',
    'wegwerfadresse.de ',
    'wegwerfemail.com ',
    'wegwerfemail.de ',
    'wegwerfmail.de ',
    'wegwerfmail.info ',
    'wegwerfmail.net ',
    'wegwerfmail.org ',
    'wh4f.org ',
    'whyspam.me ',
    'willhackforfood.biz ',
    'willselfdestruct.com ',
    'winemaven.info ',
    'wronghead.com ',
    'www.e4ward.com ',
    'www.mailinator.com ',
    'wwwnew.eu ',
    'x.ip6.li ',
    'xagloo.com ',
    'xemaps.com ',
    'xents.com ',
    'xmaily.com ',
    'xoxy.net ',
    'yep.it ',
    'yogamaven.com ',
    'yopmail.com ',
    'yopmail.fr ',
    'yopmail.net ',
    'yourdomain.com ',
    'yuurok.com ',
    'z1p.biz ',
    'za.com ',
    'zehnminuten.de ',
    'zehnminutenmail.de ',
    'zippymail.info ',
    'zoemail.net ',
    'zomg.info'
]

const formValidator = (type, name, value, isRequired = false) => {
    let reg = '^([0-9a-zA-Z]([-\.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$'

    let regex = new RegExp(reg)

    if (isRequired) {
        switch (type) {
            case 'text':
                if (!!value) {
                    return `${name} field is Required.`
                }
                break;
            case 'number':
                if (!!value) {
                    return `${name} field is Required.`
                } else if (name === 'Phone' && value.length < 10) {
                    return `${name} number should not be less than or greater than 10 digits`
                }
                break;
            case 'email':
                if (!!value) {
                    return `${name} field is Required.`
                } else if (!regex.test(value)) {
                    return `Enter valid ${name}.`
                }
                break;
            default:
                break;
        }
    }
}
const validate = (values, condition = {}) => {
    let errors = {};
    // const phonePrefixArray = condition.mobileCode === '+91' ? ['6','7','8','9'] : ['8','9']
    
    if (!values.email) {

        errors.email = 'Email address is required *';
    } else if (/\S+@\S+\.\S+/.test(values.email)) {
        let emailpostfix = values.email.split('@')[1].toLowerCase()
        if (notAllowedEmail.includes(emailpostfix))
            errors.email = 'Only work emails are allowed';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!values.name) {

        errors.name = 'Name is required *';
    }
    // if (!Object.keys(condition).length > 0 && !values.lName) {
    //     errors.lName = 'Last Name is required *';
    // }
    if (isNaN(+values.phone)) {
        errors.phone = 'Number is required *';
    }else if(!validateContact(condition.mobileCode,values.phone)){
        errors.phone = 'Please enter a valid phone number';
    }
    //  else if (condition.mobileCode === '+91' && ((values.phone + '').length < 10 || (values.phone + '').length > 10 || !phonePrefixArray.includes(values.phone[0]))) {
    //     errors.phone = 'Please enter a valid phone number';
    // } else if (condition.mobileCode === '+65' && ((values.phone + '').length < 8 || (values.phone + '').length > 8 || !phonePrefixArray.includes(values.phone[0]))) {
    //     errors.phone = 'Please enter a valid phone number';
    // }
    if (!values.designation) {
        errors.designation = 'Designation is required *';
    }
    if (!values.company) {
        errors.company = 'Company is required *';
    }
    if (!condition.fromPopup && !values.message) {
        errors.message = 'Message is required *';
    }

    return errors;
};

const calcvalidate = (values) => {
    let errors = {};

    if (!values.email) {

        errors.email = 'Email address is required *';
    } else if (/\S+@\S+\.\S+/.test(values.email)) {
        let emailpostfix = values.email.split('@')[1].toLowerCase()
        if (notAllowedEmail.includes(emailpostfix))
            errors.email = 'Only work emails are allowed';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!values.name) {

        errors.name = 'Full Name is required *';
    }
    if (!values.designation) {

        errors.designation = 'Designation is required *';
    }

    if (!values.organization) {

        errors.organization = 'Organisation is required *';
    }


    return errors;
};
const signupformvalidate = (values, condition = {}) => {
    let errors = {};

    if (!values.email) {

        errors.email = 'Email address is required *';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!condition.showSignInContent && !values.name) {

        errors.name = 'Full Name is required *';
    }
    if (!condition.showSignInContent && condition.isHr && !condition.isIndividual && !values.designation) {

        errors.designation = 'Designation is required *';
    }
    if (!values.password) {

        errors.password = 'Password is required *';
    }
    if (!condition.showSignInContent && !values.confirmPassword) {

        errors.confirmPassword = 'Confirm Password is required *';
    }
    if (condition.showSignInContent && !condition.isIndividual && !values.accesscode) {

        errors.accesscode = 'Access Code is required *';
    }
    return errors;
};
const step1Form = async (values) => {
    let errors = {};
    // const phonePrefixArray = values.mobileCode === '+91' ? ['6','7','8','9'] : ['8','9']

    var minNumberofChars = 6;
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*_]{6,50}$/;
    if (values.password.length < minNumberofChars) {
        errors.passwordFormat = 'Atleast 6 characters, one symbol, one uppercase *';
    }
    if (!regularExpression.test(values.password)) {
        errors.passwordFormat = 'Atleast 6 characters, one symbol, one uppercase *';
    }
    if (!values.email) {
        errors.email = 'Email address is required *';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!values.fName) {

        errors.fName = 'First Name is required *';
    }
    if (!values.lName) {
        errors.lName = 'Last Name is required *';
    }
    if (!values.dob) {
        errors.dob = 'Date Of Birth is Required *'
    }
    if (!values.password) {

        errors.password = 'Password is required *';
    }
    if (!values.number) {
        errors.number = 'Number is required *';
    } else if(!validateContact(values.mobileCode,values.number)){
        errors.number = 'Please enter a valid phone number';
    }
    // else if (values.mobileCode === '+91' && ((values.number + '').length < 10 || (values.number + '').length > 10 || !phonePrefixArray.includes(values.number[0]))) {
    //     errors.number = 'Please enter a valid phone number';
    // } else if (values.mobileCode === '+65' && ((values.number + '').length < 8 || (values.number + '').length > 8 || !phonePrefixArray.includes(values.number[0]))) {
    //     errors.number = 'Please enter a valid phone number';
    // }
    if (!values.language) {

        errors.language = 'Language is required *';
    }
    // if ( !values.state) {

    //     errors.state = 'State  is required *';
    // }
    if (values.email && values.number) {
        let res = await TherapyService.checkDuplicateUser(values.email, values.mobileCode, values.number)
        if (res.checkUser)
            errors.duplicateUser = 'User Already Exists , try changing Phone No. and Email';
    }
    if (!values.city) {

        errors.city = 'City is required *';
    }
    if (!values.picture) {

        errors.picture = 'Picture is required *';
    }
    if (!values.othergender && values.gender === 'others') {

        errors.othergender = 'Other Gender is required *';
    }
    return errors;
};
const step2Form = (values, condition = {}) => {
    let errors = {};

    if (!values.degree) {

        errors.degree = 'Degree is required *';
    }
    if (!values.specialization) {

        errors.specialization = 'Specialization is required *';
    }
    // if ( !values.workexperience) {

    //     errors.workexperience = 'Work Experience is required *';
    // }
    if (!values.resume) {

        errors.resume = 'Resume is required *';
    }
    if (!values.certificates || (values.certificates && values.certificates.length === 0)) {

        errors.certificates = 'Certificates is required *';
    }
    if (condition.isRciNo && !values.rcino) {
        errors.rcino = 'Rci No is required *';
    }
    return errors;
};
const step3Form = (values, condition = {}) => {
    let errors = {};

    if (!values.myself) {

        errors.myself = 'Write about yourself is required *';
    }
    if (!values.address && values.radio_own_space === 'yes') {

        errors.address = 'Your own space Address is required *';
    }

    return errors;
};
const basicInfoformScreen1 = (values, condition = {}) => {
    let errors = {};

    if (values.language.length < 2) {

        errors.language = 'Atleast 2 Language is required *';
    }
    if (!values.dob) {

        errors.dob = 'Your Date of Birth is required *';
    }

    return errors;
};
const basicInfoformScreen2 = (values, condition = {}) => {
    let errors = {};

    if (!values.gender) {

        errors.gender = 'Gender is required *';
    }
    if (!values.othergender && values.gender === 'others') {

        errors.othergender = 'Other Gender is required *';
    }
    if (!values.preferPronoun) {

        errors.preferPronoun = 'Pronoun is required *';
    }

    return errors;
};
const preferencesScreenOne = (values, condition = {}) => {
    let errors = {};

    if (!values.therapistGender) {

        errors.therapistGender = 'Therapist Gender is required *';
    }
    if (!values.othertherapistGender && values.therapistGender === 'others') {

        errors.othertherapistGender = 'Other Gender is required *';
    }
    if (!values.therapistAge || values.therapistAge.length === 0) {

        errors.therapistAge = 'Therapist Age is required *';
    }

    return errors;
};
const preferencesScreenTwo = (values, condition = {}) => {
    let errors = {};

    if (!values.isLGBTQ) {

        errors.isLGBTQ = 'LGBTQ  is required *';
    }
    if (!values.interest) {

        errors.interest = 'Interest is required *';
    }

    return errors;
};
const logisticScreenOne = (values, condition = {}) => {
    let errors = {};

    if (!values.weekday_group) {

        errors.weekday_group = '  WeekDay is required *';
    }
    if (!values.weekday_group_timing) {

        errors.weekday_group_timing = 'Week Timings is required *';
    }
    if (!values.isMedication) {

        errors.isMedication = 'Medication is required *';
    }

    return errors;
};
const specialityScreen = (values, condition = {}) => {
    let errors = {};

    return errors;
};

const contactUs = (values) => {
    let errors = {};
    // const phonePrefixArray = values.mobileCode === '+91' ? ['6','7','8','9'] : ['8','9']
    
    if (!values.email) {

        errors.email = 'Email address is required *';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!values.name) {

        errors.name = 'Name is required *';
    }
    if (isNaN(+values.phone)) {
        errors.phone = 'Number is required *';
    }else if(!validateContact(values.mobileCode,values.phone)){
        errors.phone = 'Please enter a valid phone number';
    }
    // else if (values.mobileCode === '+91' && ((values.phone + '').length < 10 || (values.phone + '').length > 10 || !phonePrefixArray.includes(values.phone[0]))) {
    //     errors.phone = 'Please enter a valid phone number *';
    // } else if (values.mobileCode === '+65' && ((values.phone + '').length < 8 || (values.phone + '').length > 8 || !phonePrefixArray.includes(values.phone[0]))) {
    //     errors.phone = 'Please enter a valid phone number *';
    // }

    return errors;
}

const selfcareForm = (values) => {
    const errors = {};
    if (!values.email) {
        errors.email = 'Email address is required *';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid *';
    }
    if (!values.name) {
        errors.name = 'Name is required *';
    }
    if (!values.message) {
        errors.message = 'Message is required *';
    }
    return errors;
}
export default {
    formValidator,
    calcvalidate,
    signupformvalidate,
    step1Form,
    step2Form,
    step3Form,
    basicInfoformScreen1,
    basicInfoformScreen2,
    preferencesScreenOne,
    preferencesScreenTwo,
    logisticScreenOne,
    specialityScreen,
    validate,
    contactUs,
    selfcareForm
}