import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RenderTooltip = (text, props) => (
    //this custom tooltip componenet 

    <Tooltip id="button-tooltip" {...props}>
        {text}
    </Tooltip>
);

export const CustomTooltip = (props) => {
    return (
        <OverlayTrigger
            placement={props.placement}
            delay={{ show: 250, hide: 400 }}
            overlay={RenderTooltip(props.text)}
        >
            <a href={props.to} dangerouslySetInnerHTML={{ __html: props.htmlElement }}></a>
        </OverlayTrigger>
    )
}
