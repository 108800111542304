import React, { useState } from 'react'
import CustomTooltipTwo from '../Tooltip/CustomTooltipTwo'

const CustomRadioButtonField = (props) => {

    const tooltipContent = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd."
    const tooltipQues = "What does this mean?"

    return (
        <div className="row mb-3">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <h3 className="page-inner-title">{props.ques} {(props.error || props.errorother )  && <span className="text-danger error">* Required</span>} 
                    <CustomTooltipTwo content={tooltipContent} ques={tooltipQues} />
                    

                    </h3>
                    <div className="select-gender-option">
                        <ul className="list-unstyled">
                            {
                                props.options && props.options.length > 0 &&
                                props.options.map((option,i) => (
                                    option === "Others" ?
                                    <li key={i}>
                                        <div className="gender-custom-input" style={{display:"flex"}}>
                                            <input type="radio" id={option} name={props.name} value="others" checked={ !!(props.value === "others") ? true :false}   onChange={props.handleChange}/>
                                            <label htmlFor={option}></label>
                                            <div className="w-25">
                                                <input className="form-control gender-other-custom-field" type="text" id=""   onChange={props.handleChange} name={"other" +props.name} value={ !!(props.value === "others" ) ? props.valueOther :""} readOnly={!!(props.value === "others" ) ? false :true}   placeholder="Other" />
                                            </div>
                                        </div>
                                    </li>
                                    :
                                    <li key={i}>
                                        <div className="gender-custom-input">
                                            <input type="radio" id={option} value={option || ""}  name={props.name} checked={ !!(props.value === option) ? true :false}  onChange={props.handleChange}/>
                                            <label htmlFor={option}>{option}</label>
                                        </div> 
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
    )
}

export default CustomRadioButtonField
