import React from 'react'
import Wheel from './wheel/Wheel';

const SpinWheelSection = () => {
    //this componenets helps us in SelfCare page in SpinWheelSection section
    //passing custom wheelData to wheel.jsx 
    const wheelData = ['Guilt', 'Anger', 'Fear', 'Grief', 'I Don’t Know', 'Restless'];
    return (
        <div>
            <section id="emotion">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="sub-lead">Manage Your Emotions</p>
                            <h2 className="emotion-title">Get a quick fix for whatever you are feeling.</h2>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <Wheel items={wheelData} />
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="emotion-wheel position-relative">

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default SpinWheelSection
