const iniatialState = {
    messages: {},
    ventOutLoaded: false,
    paginationRow: [],
    maxPages: 0,
    pageNo: 0,
};
export default (state = iniatialState, action) => {
    switch (action.type) {
        case 'GET_PARENT_MESSAGES':
            return {
                ...state,
                messages: action.payload,
            }
        case "GET_CHILD_MESSAGES":
            return {
                ...state,
                messages: state.messages.map((message) =>
                    message.id === action.parentId ? { ...message, childMessages: action.payload } : message
                )
            }
        case "VENT_OUT_LOADED":
            return {
                ...state,
                ventOutLoaded: true
            }
        case "RELOAD_VENT_OUT":
            return {
                ...state,
                ventOutLoaded: false
            }
        case "SET_PAGIGANTION_ROW":
            return {
                ...state,
                paginationRow: action.payload
            }
        case "CHANGE_PAGE_NO":
            return {
                ...state,
                pageNo: action.payload
            }
        case "GET_MAXIMUM_PAGES":
            return {
                ...state,
                maxPages: action.payload
            }
        default:
            return state;
    }
}