import CommonService from "../../api/services/CommonService";

export default {
  getFilter: (type, enumType) => async (dispatch) => {
    const response = await CommonService.enumApi(enumType);
    if (response.success) {
      dispatch({ type: type, payload: response.data });
      dispatch({ type: "RESET_ERROR" });
    } else {
      dispatch({ type: "SET_ERROR", payload: response.data });
    }
  },
  getAllCountryCode:() =>
  async (dispatch) => {
    const response = await CommonService.getAllCountryCodes();
    if (response.success) {
      dispatch({ type: "SET_COUNTRY_CODE_LIST", payload: response.data });
      dispatch({ type: "RESET_ERROR" });
    } else {
      dispatch({ type: "SET_ERROR", payload: response.data });
    }
  },
}