
export const anchorRedirection = {
    tag: (anchorId) => {
        let a = document.createElement('a');
        a.href = `#${anchorId}`;
        a.click();
    }

}
export const format = {
    fileName: (name) => {
        console.log(name);
        const dots = "...";
        const limit = 10;
        let fileNameFormat;
        if (name.length > limit) {
            const type = name.split('.').pop();
            fileNameFormat = name.substring(0, limit) + dots + type;

        }
        else {
            return name;
        }
        return fileNameFormat;
    }
}

export const facebookHelper = {
    leadOperation: () => {
        return window.fbq('track', 'Lead')
    }
}


export const getRedirectionUrl = (redirectPath, additionalPrams=null) =>{
  let urlParams = null;
  const paramIndex = window.location.href.indexOf('?');
  const urlRegexMatch = paramIndex > -1 ? `${window.location.href.substring(paramIndex + 1)}` : '';
  if (urlRegexMatch) urlParams = urlRegexMatch;
  if(additionalPrams){
    if(urlParams) return `${redirectPath}?${additionalPrams}&${urlParams}`
    return `${redirectPath}?${additionalPrams}`
  }
  else{
    if(urlParams) return `${redirectPath}?${urlParams}`
    return `${redirectPath}`
  }
}

export const validateContact = (code, phoneNumber) => {
    let regex = null;
    switch(code){
      case '+60':
        regex = /^1\d{6,7}$/;
        break;
      case '+62':
        regex = /^8[1-9]\d{7,9}$/;
        break;
      case '+65':
        regex = /^[8-9]\d{7}$/;
        break;
      case '+91':
        regex = /^[6-9]\d{9}$/;
        break;
      default:
        regex=null;
    }
    if(regex == null || !(regex.test(phoneNumber))) return false;
    else return true
  }

  export const platform = {
    WEB: "WEB",
    ANDROID: "ANDROID",
    IOS: "IOS",
    WINDOW: "WINDOW"
}

  export const platformType = () => {
    const agent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(agent)) {
      return platform.WINDOW
    }
    if (/android/i.test(agent)) {
      return platform.ANDROID
    }
    if (/iPad|iPhone|iPod/.test(agent)) {
      return platform.IOS
    }
    return platform.WEB
  }