export const changeArrayKeys = (arr) => {
    const modifiedArr = arr.map((val) => {
        return {
            value: val.id, label: val.code.toUpperCase()
        }
    });
    return modifiedArr
}

export const sortLanguages = (arr) => {
    const languages = changeArrayKeys(arr)
    languages.sort((a, b) => {
        return a.label.localeCompare(b.label)
    })
    return languages
}

export const makeExpKeys = (expEnums) => {
    const experienceKeys = {};
    expEnums.forEach((exp) => experienceKeys[exp.value] = exp.key)
    return experienceKeys
}

export const expValues = (expArr) => {
    return expArr.map((exp) => exp.value)
}

export const writeTherapistProfileString = (profiles) => {
    let profileString = ""
    for (let i = 0; i < profiles.length; i++) {
        if (i == 0)
            profileString += profiles[i]["name"] + " "
        else if (i == profiles.length - 1)
            profileString += "and " + profiles[i]["name"]
        else
            profileString += "," + profiles[i]["name"] + " "
    }
    return profileString
}

export const convertRating = (rating) => {
    return {
        number: Math.floor(rating),
        decimal: rating % 1 != 0
    }
}

export const convertIsoDate = (date) => {
    return formatDate(date.split('T')[0])
}

const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
];
export const formatDate = (date) => {
    const changeDate = new Date(date);
    return monthNames[changeDate.getMonth()] + ' ' + changeDate.getDate() + ', ' + changeDate.getFullYear();
}



export const formatVentOutDate = (ventOutTimeStamp) => {

    const ventDate = parseInt(ventOutTimeStamp)
    let hours = (new Date() - new Date(ventDate)) / 36e5
    if (hours < 24) {
        let agoTime;
        if (hours < 1) {
            if (Math.floor(hours * 60) <= 0)
                agoTime = "Just Now"
            else if (Math.floor(hours * 60) == 1)
                agoTime = "1 minute ago"
            else
                agoTime = Math.floor(hours * 60) + " minutes ago"
        }
        else {
            if (Math.floor(hours) === 1)
                agoTime = "an hour ago"
            else
                agoTime = Math.floor(hours) + " hours ago"
        }
        return agoTime
    }
    else {
        return formatDate(ventDate)
    }

}

export const formatgiftPlans = (giftPlans) => {
    return giftPlans.filter((plan) => {
        return plan.standard === false;
    })
}

export const showTherapistSpeciality = (therapistSpecialisations) => {
    let speciality = '';
    const specialisations = []

    if (therapistSpecialisations.length > 0) {
        therapistSpecialisations.map(_sp => {
            specialisations.push((_sp.name).toLowerCase());
        })
        if (specialisations.includes('clinical psychologist')) {
            speciality = "Clinical Psychologist"
        } else if (specialisations.includes('counselling psychologist')) {
            speciality = "Counselling Psychologist"
        } else if (specialisations.includes('psychotherapist')) {
            speciality = "Psychotherapist"
        } else {
            speciality = therapistSpecialisations[0].name
        }
    }

    return speciality;
}