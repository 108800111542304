import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import CustomRadioButtonField from '../../../common/Buttons/CustomRadioButtonField'
import useGetMatchForm from '../../../../utils/useGetMatchForm';
import formValidation from "../../../../utils/formValidation";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __logisticScreenOne } from '../../../../store/actions/matchTherapy.action'

const LogisticScreenOne = (props) => {
    
    const options1 = ["Yes", "No"]
    
    const formValues = () => {
        //call back function after the form submit
        console.log(values);
        props.setPage(1)
        props.setStepName("Specialties")
        props.__logisticScreenOne(values);
      };
    const { values, errors, handleChange, handleSubmit } = useGetMatchForm(
        //sends/recieve our values and errors to formvalidation.js and useform custom hooks
        formValues,
        formValidation.logisticScreenOne,
        props.logisticScreenOne,
        {}
      );
    return (
        <>
            <form className="step-form" onSubmit={handleSubmit} noValidate>
                <div className="row mb-3">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h3 className="page-inner-title">Timings of Sessions
                         {(errors.weekday_group || errors.weekday_group_timing)  && <span className="text-danger error">* Required</span>} 
                        </h3>
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="select-gender-option">
                                    <ul className="list-unstyled">
                                        <li>
                                            <div className="gender-custom-input">
                                                <input type="radio" id="weekday" name="weekday_group"  value="Weekday" checked={values.weekday_group === 'Weekday'}  onChange={handleChange}/>
                                                <label htmlFor="weekday">Weekday</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="gender-custom-input">
                                                <input type="radio" id="weekend" name="weekday_group" value="Weekend" checked={values.weekday_group === 'Weekend'} onChange={handleChange}/>
                                                <label htmlFor="weekend">Weekend</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <div className="select-gender-option select-session">
                                    <ul className="list-unstyled">
                                        <li>
                                            <div className="gender-custom-input">
                                                <input type="radio" id="weekday-1" name="weekday_group_timing" value="10 AM - 12 PM" checked={values.weekday_group_timing === '10 AM - 12 PM'} onChange={handleChange}/>
                                                <label htmlFor="weekday-1">10 AM - 12 PM</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="gender-custom-input">
                                                <input type="radio" id="weekend-2" name="weekday_group_timing" value="12 PM - 5 PM" checked={values.weekday_group_timing === '12 PM - 5 PM'} onChange={handleChange}/>
                                                <label htmlFor="weekend-2">12 PM - 5 PM</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="gender-custom-input">
                                                <input type="radio" id="weekend-3" name="weekday_group_timing" value="5 PM - 7 PM" checked={values.weekday_group_timing === '5 PM - 7 PM'} onChange={handleChange}/>
                                                <label htmlFor="weekend-3">5 PM - 7 PM</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CustomRadioButtonField options={options1} name="isMedication" ques={"Are you currently on medication?"} error={errors.isMedication} handleChange={handleChange} value={values.isMedication}  /> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="start-now text-right">
                                <button
                                    type="submit"
                                    className="btn btn-start-now"
                                    // onClick={() => handleNext()}
                                >
                                    Next
                                </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({ matchTherapyFormData }) => ({
    ...matchTherapyFormData
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        __logisticScreenOne
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LogisticScreenOne));