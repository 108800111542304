import React, { useState } from 'react'
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { anchorRedirection } from '../../utils/_helper';
import FormPageModel from '../common/modalfolder/FormPageModal';

const PriceAndPlanSection = () => {
    // this is  PriceAndPlanSection section in organisation page

    // const redirect = () => {
    //     //helps to naviagate to getAccess form component in organisation page
    //     anchorRedirection.tag('get-access', 'Plansection')
    //     // anchorRedirection.tag('form-page')
    // }
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel
    const [plan, changePlan] = useState('');
    const plansFeatures = {
        infinite: false,
        slidesToShow: 3,
        initialSlide: 1,
        speed: 500,
        className: "centerSlider",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    centerMode: true
                }
            }
        ]
    }
    return (
        <section id="price-feature">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="sub-lead">Explore Our Different Plans</p>
                        <h3 className="sub-lead-title">Choose a plan that’s ideal for your organisation</h3>
                    </div>
                </div>
                <div className="price-plane-wrapper mt-n5">
                    <div className="row overflow-hidden">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 pl-0 px-lg-0 px-lg-0 px-md-0 px-sm-3 px-3">
                            <Slider {...plansFeatures}>

                                <div className="price-plan-box mt-t5">
                                    <div className="price-box">
                                        <h3>READY</h3>
                                        <p>You are indeed <i>Ready</i> for the first step towards sensitizing your workplace on mind well-being</p>
                                        <h4>Features:</h4>
                                        <ul className="list-unstyled">
                                            <li>Workshops</li>
                                            <li>MindPeers Boats: Group sessions facilitated by a psychologist.</li>
                                        </ul>
                                        <Link onClick={() => { changemodelValue(!modelValue); setShowModel(true); changePlan('Ready') }} ><button type="button" className="btn btn-get-started">Get Started</button></Link>
                                    </div>
                                </div>
                                <div className="price-plan-box price-plan-center">
                                    <div className="price-box reco">
                                        <span className="recomended position-absolute text-center">Recommended</span>
                                        <h3 className="mb-25">SET</h3>
                                        <p>You are all <i>Set</i> to be a modern employer focusing on employee experience</p>
                                        <h4>Features:</h4>
                                        <ul className="list-unstyled">
                                            <li>MindCare Index Audit</li>
                                            <li>TeamCare Employee Dashboard with <br />
                                                <b>a.</b> Digital Programs on Sleep, Productivity and many more <br />
                                                <b>b.</b> Games to unwind <br />
                                                <b>c.</b> Mindfulness routines
                                            </li>
                                            <li>MindCoaching including Therapy, Life Coaching and Performance Coaching</li>
                                            <li>HRCare Data</li>
                                        </ul>
                                        <Link onClick={() => { changemodelValue(!modelValue); setShowModel(true); changePlan('Set') }} ><button type="button" className="btn btn-get-started">Get Started</button></Link>
                                    </div>
                                </div>
                                <div className="price-plan-box mt-t5">
                                    <div className="price-box">
                                        <h3 >PRO</h3>
                                        <p>Combining the best worlds of mind well-being and organisational
                                            psychology, you know behavioural healthcare is the way to go</p>
                                        <h4>Features:</h4>
                                        <ul className="list-unstyled">
                                            <li>Everything in SET Plan</li>
                                            <li>HR Tools on right appraisals, hiring</li>
                                            <li>Learning and Development Tools to build resilience</li>
                                        </ul>
                                        <Link onClick={() => { changemodelValue(!modelValue); setShowModel(true); changePlan('Pro') }}  ><button type="button" className="btn btn-get-started">Get Started</button></Link>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            <FormPageModel modelValue={modelValue} showModel={showModel} plan={plan} clickedFrom="Price And Plan" />
        </section>

    )
}

export default PriceAndPlanSection
