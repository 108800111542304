import VentOutService from "../../api/services/VentOutService"
export default {
    getVentOutParents: () => async (dispatch, state) => {
        const { ventOut: { pageNo, paginationRow } } = state()
        const response = await VentOutService.getVentOutParents(process.env.REACT_APP_MESSAGES_ON_PAGE, pageNo * process.env.REACT_APP_MESSAGES_ON_PAGE)

        dispatch({
            type: "GET_PARENT_MESSAGES",
            payload: response.listWallMessages
        })

        dispatch({
            type: "GET_MAXIMUM_PAGES",
            payload: Math.ceil(response.TotalVents / process.env.REACT_APP_MESSAGES_ON_PAGE)
        })
        const { ventOut: { maxPages } } = state()
        if (paginationRow.length === 0) {
            const defaultPaginationRow = [];
            const pagRowSize = maxPages < process.env.REACT_APP_PAGINATION_ROW_SIZE ? maxPages : process.env.REACT_APP_PAGINATION_ROW_SIZE
            for (let i = 0; i < pagRowSize; i++)
                defaultPaginationRow.push(i + 1)
            dispatch({
                type: "SET_PAGIGANTION_ROW",
                payload: defaultPaginationRow
            })
        }

        let childResponses;
        const { ventOut: { messages } } = state()
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].replies > 0) {
                childResponses = await VentOutService.getVentOutChildren(messages[i].id)
                dispatch({
                    type: "GET_CHILD_MESSAGES",
                    payload: childResponses,
                    parentId: messages[i].id
                })
            }
        }
        dispatch({
            type: "VENT_OUT_LOADED"
        })
    },
    addVentOutChild: (childReply, id) => async (dispatch) => {
        await VentOutService.postWallMessage(childReply, JSON.stringify(id))
        dispatch({
            type: "RELOAD_VENT_OUT"
        })
    },
    changePageNo: (pageNo) => (dispatch, state) => {
        dispatch({
            type: "CHANGE_PAGE_NO",
            payload: pageNo
        })

        const { ventOut: { paginationRow, maxPages } } = state()

        const size = paginationRow.length
        let newPagRow = []
        if (pageNo == 0) {
            newPagRow[0] = pageNo + 1
            for (let i = 1; i < size; i++)
                newPagRow[i] = newPagRow[i - 1] + 1

        } else if (pageNo + 1 === maxPages) {
            newPagRow[size - 1] = pageNo + 1
            for (let i = size - 2; i >= 0; i--)
                newPagRow[i] = newPagRow[i + 1] - 1;
        } else {
            const center = Math.floor(size / 2)
            newPagRow[center] = pageNo + 1
            for (let i = center - 1; i >= 0; i--)
                newPagRow[i] = newPagRow[i + 1] - 1;
            for (let i = center + 1; i < size; i++)
                newPagRow[i] = newPagRow[i - 1] + 1;
        }
        dispatch({
            type: "SET_PAGIGANTION_ROW",
            payload: newPagRow
        })
        dispatch({
            type: "RELOAD_VENT_OUT"
        })
    },
    // increaseVentOutRow: () => (dispatch, state) => {
    //     const { ventOut: { paginationRow } } = state()
    //     let newPagRow = paginationRow
    //     newPagRow.push(newPagRow[newPagRow.length - 1] + 1)
    //     newPagRow.splice(0, 1);
    //     dispatch({
    //         type: "SET_PAGIGANTION_ROW",
    //         payload: newPagRow
    //     })
    // },
    // decreaseVentOutRow: () => (dispatch, state) => {
    //     const { ventOut: { paginationRow } } = state()
    //     let newPagRow = paginationRow
    //     newPagRow.unshift(newPagRow[0] - 1);
    //     newPagRow.splice(newPagRow.length - 1, 1);
    //     dispatch({
    //         type: "SET_PAGIGANTION_ROW",
    //         payload: newPagRow
    //     })
    // }
}