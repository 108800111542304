import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';
import { ToastConstant } from '../default_config/toastConstant';
import BoatAction from '../store/actions/boatAction';
import BackButton from './common/Buttons/BackButton';
import CustomToast from './common/Toast/CustomToast';
import BoatDescription from './sub_components/community/BoatDetail/BoatDescription';
import { getRedirectionUrl } from '../utils/_helper';
const BoatDetailComponent = (props) => {
    const location = useLocation();
    const history = useHistory();
    const handleBack = () => { history.replace({ pathname: '/community' , search: `${location.search}`}) }
    const params = useParams()
    const dispatch = useDispatch()
    const boatById = useSelector(state => state.boatData.boatByID) || {}
    const { error } = useSelector(state => state.commonState) || {}
    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })

    useEffect(() => {
        if (params.id) {
            dispatch(BoatAction.getBoatById(params.id))
        }
    }, [params.id])

    const handleRoute = () => {
        let a = document.createElement('a');
        a.href = getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`boat/${boatById.slug}/?landing=1`)}`)
        a.target = "_blank";
        a.click();
    }

    useEffect(() => {
        if (error && error.code) {
            setOpen(true)
            if(error.code === 'BOAT_EXPIRED'){
                setToastData(ToastConstant.BOAT_EXPIRED)
            }else{
                setToastData({severity:"error" , message : error.code , time : 2000})
            }
            setTimeout(()=>{
                history.replace({pathname : '/community', search: `${location.search}`})
            },2000)
        }
    }, [error])

    return (
        <div>
            <section id="home">
                <div className="container">
                    <div className="row align-items-center py-10 pb-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="row mb-5">
                                <BackButton handleBack={() => handleBack()} />
                            </div>
                            <BoatDescription
                                boatById={boatById || {}}
                                type={props.type || ''}
                            />
                        </div>
                    </div>
                </div>

            </section>
            <section className="boat-footer">
                <div className="container">
                    <div className="row">
                        <div class="col-md-9 offset-md-2">
                            <div class="d-flex 
                        flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap
                        justify-content-center 
                        align-items-center boat-footer-content">
                                <div className="text-xl-left text-lg-left text-md-center text-sm-center text-center mr-xl-3 mr-lg-3 mr-md-0 mr-sm-3 mr-0">
                                    <h3>Join The Boat Now!</h3>
                                </div>
                                <div>
                                    <button onClick={handleRoute} type="button" className="btn btn-register-footer mt-xl-0 mt-lg-0 mt-md-3 mt-sm-0 mt-3">SIGN UP TO JOIN @ ₹{boatById.price}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {open && toastData && toastData.severity && toastData.time && (
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            )}
        </div>
    )
}

export default BoatDetailComponent
