import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useHistory , useLocation} from 'react-router-dom'
import dayjs from 'dayjs'
import { ToastConstant } from '../../../default_config/toastConstant'
import CustomToast from '../../common/Toast/CustomToast'
import BoatService from '../../../api/services/BoatService'
import { getRedirectionUrl, getQueryParams } from '../../../utils/_helper'

const BoatLongCard = ({ boatData = {}, isUpcoming = false }) => {

    const [loader, setLoader] = useState(false)
    const history = useHistory();
    const [open, setOpen] = useState(false)
    const [toastData, setToastData] = useState({ time: 0, severity: "", message: "" })
    const location = useLocation();

    const handleViewMoreDetail = async (slugId) => {
        const resp = await BoatService.getBoatAvailability(slugId);
        if (resp.success && resp.data) {
            history.push({
                pathname: `/community/${slugId}`,
                search: `${location.search}`
              })
            // ({ pathname: `/community/${slugId}${window.location.search}`})
        } else { 
            setOpen(true)
            setToastData(ToastConstant.BOAT_EXPIRED)
            setTimeout(()=>{
                window.location.reload();
            },2000)
        }
    }

    return (
        <>
            <div className="single-boat box-shadow new-padd position-relative overflow-hidden">
                {/* <div className="card-wave-long position-absolute"></div> */}
                <div style={{ '--long-boat-img': `url(${boatData.image || '../img/covid.png'})` }} className="boat-user-img test"></div>
                {/* <div style={{ '--boat-img': `url(${boatData.image ? boatData.image : '/img/covid.png'})` }} className="boat-user-img covid"></div> */}
                {/* {
                boatData.categories && boatData.categories.length > 0 &&
                <div className="d-xl-none d-lg-none d-md-block d-sm-block d-block">
                    <div className="margin-top-12rem position-relative z-index-3 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start">
                        <div>
                            <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                                {
                                    boatData.categories.map((tag, index) => (
                                        <div key={index} className="filter blue-light-bg selected mr-xl-3 mr-lg-0 mr-md-0 mr-sm-0 mr-0">{tag}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            } */}
                <div className="margin-top-12rem position-relative z-index-3 d-flex">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 pl-0 pr-sm-0 pr-0">
                        <div className="single-boat-title">
                            <h2 className="long-boat-heading-overflow" title={boatData.title || ''}>{(boatData.title || '').length > 65 ? (boatData.title || '').slice(0, 65) + '...' : (boatData.title || '')}</h2>
                            <span className="boat-desc-timing no-bg"><strong>{dayjs(boatData.start_at).format("MMMM D, YYYY")}</strong> FROM {dayjs(boatData.start_at).format("h:mm A")} TO {dayjs(boatData.end_at).format("h:mm A")}</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative z-index-3 d-flex flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap align-items-center justify-content-xl-start justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-start mb-3">
                    <div className="flex-xl-grow-0 flex-lg-grow-0 flex-md-grow-0 flex-sm-grow-1 flex-grow-1 mr-xl-3 mr-lg-3 mr-md-3 mr-sm-2 mr-2">
                        <Link onClick={()=>handleViewMoreDetail(boatData.slug)} to={/*`/community/${boatData.slug}`*/'#'} className="boat-btn view-boat-detail no-border">View More Details</Link>
                    </div>
                    <a href={getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`boat/${boatData.slug}/?landing=1`)}`)/*`${process.env.REACT_APP_DASHBOARD_URL}?redirect=boat/${boatData.slug}`*/} target="_blank" className="boat-btn register-boat" >SIGN UP TO JOIN @ ₹{boatData.price}</a>
                </div>
                {/* {
                boatData.categories && boatData.categories.length > 0 &&
                <div className="d-xl-block d-lg-block d-md-none d-sm-none d-none">
                    <div className="margin-top-12rem position-relative z-index-3 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start">
                        <div>
                            <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                                {
                                    boatData.categories.map((tag, index) => (
                                        <div key={index} className="filter blue-light-bg selected mr-xl-3 mr-lg-0 mr-md-0 mr-sm-0 mr-0">{tag}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            } */}
            </div>
            {
                open && toastData.severity && toastData.time &&
                <CustomToast
                    time={toastData.time}
                    severity={toastData.severity}
                    open={open}
                    setOpen={setOpen}
                    message={toastData.message}
                />
            }
        </>
    )
}

BoatLongCard.propTypes = {
    boatData: PropTypes.object.isRequired,
    isUpcoming: PropTypes.bool
}

export default BoatLongCard
