import React from 'react'
//this componenets helps us in Organisation page in Teams that Already Love Us section
// using img_data we are creating multiple logos
const img_data = [
    "nestle.jpg",
    "avon.jpg",
    "marico.png",
    "unacademy.jpg",
    "mckinsey.jpg",
    "asianpaints.png",
    "amagi.jpg",
    "bira.jpg",
    "euronics.jpg",
    "myntra.jpg",
    "redcarpet.jpg",
    "sdmc.jpg",
    "sportzvillage.jpg",
    "unilever.jpg",
    "vahdam.jpg",
    "walchand.jpg",
    "watconsult.jpg",
    "wieden.jpg"
]

const CompanyLogoSection = () => {
    return (
        <section id="company-love">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="sub-lead pb_50">Teams That Already Love Us</p>
                        {/* <h3 className="sub-lead-title">+1234 companies love us!</h3> */}
                    </div>
                </div>
                <div className="logo-prices">
                    {
                        img_data.slice(0, 6).map((_img, i) => (
                            <div key={i} >
                                <div className="company-logo">
                                    <img src={`img/company_logos/${_img}`} className="w-100" alt="" title="" />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <p className="logo-description">+ 20 more companies...</p>
            </div>
        </section>
    )
}

export default CompanyLogoSection
