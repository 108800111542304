import React from 'react'
import Services from "./MindpeersServices"
import OrganisationEmp from "./OrganisationEmp"
const SectionTwo = () => {
    return (
        <>
            <Services />
            <OrganisationEmp />
        </>
    )
}

export default SectionTwo
