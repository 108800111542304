import React from 'react';
import { Link } from 'react-router-dom';
import {getRedirectionUrl} from "../../../utils/_helper";
const TherapyGift = () => {
    //this componenets helps us in Therapy page in Gift Therapy section
    return (
        <>
            <section className="gift-therapy" id="gift-therapy">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="sub-lead">Gift Therapy</p>
                            <h3 className="sub-lead-title">The best gift you can give someone you care for, is the gift of peace, happiness and a life they love. This is the chance. 
                            {/* <br /><br />
                            Use the code <strong>RAKHI100</strong> to get flat <strong>Rs.100 off</strong> on any of our Gift Therapy Plans.
                            <br />
                            <small className="gift-small-text">(coupon valid till <strong>22nd August 2021, 11:59 PM</strong>)</small> */}
                            </h3>
                            {/* <h3 className="sub-lead-title">Use the code RAKHI100 to get flat Rs.100 off any of our Gift Therapy Plans.</h3> */}
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="gift-therapy-img">
                                <img src="img/gift-therapy.svg" className="img-fluid" alt="" title="" />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="gift-therapy-title">
                                <h3>Gift A Therapy Session Today</h3>
                                <Link to={getRedirectionUrl("/therapy/gift-packages")} className="see-package" >See Packages</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TherapyGift;
