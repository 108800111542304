import React from 'react';
import { Link } from 'react-router-dom';
import { getRedirectionUrl } from '../../../utils/_helper';
const TherapyMode = () => {
    //this componenets helps us in Therapy page in  Modes of Therapy section
    return (
        <>
            <section id="therapy-mode" className="tm-section">
                <div className="container">
                    {/* <div className="therapy-areas-heading"><b>Popular Areas</b></div> */}
                    <p className="sub-lead">Popular Areas</p>
                    <div className="row mb-5 mt-5">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="tmp-box text-center">
                                <img src="img/kids.svg" className="img-fluid" alt="" title="" />
                                <h4>CHILD COUNSELLING</h4>
                                <p>Childhood experiences shape our life. Get the best help now.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="tmp-box text-center">
                                <img src="img/burnoutstress.svg" className="img-fluid" alt="" title="" />
                                <h4>BURNOUT & STRESS</h4>
                                <p>It's common, but not normal. Find practical ways to de-stress and live a happy life</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="tmp-box text-center">
                                <img src="img/relationships-card.svg" className="img-fluid" alt="" title="" />
                                <h4>RELATIONSHIPS</h4>
                                <p>Navigate loneliness, communication issues, etc. with us for a flourishing relationship.</p>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12">
                            <div className="tmp-box text-center">
                                <img src="img/career.svg" className="img-fluid" alt="" title="" />
                                <h4>PERFORMANCE COACHING</h4>
                                <p>Find your purpose and tap into your full potential in doing what you love.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row pb_30">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="text-center">
                                {/* <Link className="book-session" to="/get-matched">Book A Session</Link> */}
                                <Link className="book-session" onClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_DASHBOARD_URL,`redirect=${window.encodeURIComponent(`therapy/get-matched`)}`), "_blank")}>Book A Session</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-100">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="sub-lead">Modes of Therapy</p>
                            <h3 className="sub-lead-title mb-3">Therapy works best when you’re in a safe space, both mentally and physically. With MindPeers, you have all the modes to pick from</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="tmp-list text-xl-center text-lg-center text-md-left text-sm-left text-left">
                                <ul className="list-inline">
                                    <li className="list-inline-item">
                                        <div className="d-flex align-items-center">
                                            <div className="mr-3">
                                                <div>
                                                    <img src="img/live-video.svg" className="img-fluid" alt="" title="" />
                                                </div>
                                            </div>
                                            <div><h4 className="tmp-title mb-0">Live Video</h4></div>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="d-flex align-items-center">
                                            <div className="mr-3">
                                                <div>
                                                    <img src="img/messaging.svg" className="img-fluid" alt="" title="" />
                                                </div>
                                            </div>
                                            <div><h4 className="tmp-title mb-0">MESSAGING</h4></div>
                                        </div>
                                    </li>
                                    <li className="list-inline-item">
                                        <div className="d-flex align-items-center">
                                            <div className="mr-3">
                                                <div>
                                                    <img src="img/phone.svg" className="img-fluid" alt="" title="" />
                                                </div>
                                            </div>
                                            <div><h4 className="tmp-title mb-0">PHONE</h4></div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}

export default TherapyMode;
