import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import VentOutMessages from "./VentOutMessages";
import ventout from "../../../../store/actions/ventout.action";
import VentPaginationRow from "./VentPaginationRow";
import LoadingComp from "../../../common/LoadingComp";
const VentOutWall = () => {
  //this componenets helps us in community page in VentOutWall section
  const ventOutLoaded = useSelector(
    ({ ventOut: { ventOutLoaded } }) => ventOutLoaded
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ventOutLoaded) dispatch(ventout.getVentOutParents());
  }, [ventOutLoaded]);
  return (
    <>
      <section className="vent-out-wall vent-out-bg">
        <div className="container">
          {
            ventOutLoaded ? (
              <>
                <VentOutMessages />
                <VentPaginationRow />
              </>
            ) : (
              <LoadingComp text="Loading..." />
            )
          }
        </div>
      </section>
    </>
  );
};

export default VentOutWall;
