import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import CustomRadioButtonField from '../../../common/Buttons/CustomRadioButtonField'
import useGetMatchForm from '../../../../utils/useGetMatchForm';
import formValidation from "../../../../utils/formValidation";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __preferencesScreenTwo } from '../../../../store/actions/matchTherapy.action'

const PrefrenceScreenTwo = (props) => {

    const options1 = ["Yes", "No"]
    const options2 = [
        "Individual Therapy for Seniors (>55 years old)", 
        "Individual Therapy for Adults (32-55 years old)", 
        "Individual Therapy for Young Adults (18-32 years old)", 
        "Individual Therapy for Kids (0-18 years old)",
        "Couples Therapy"
    ]

    const handleNext = () => {
        props.setPage(1)
        props.setStepName("Logistics")
    }
    const formValues = () => {
        //call back function after the form submit

        console.log(values);
        props.setPage(1)
        props.setStepName("Logistics")
        props.__preferencesScreenTwo(values);
      };
    const { values, errors, handleChange, handleSubmit } = useGetMatchForm(
        //sends/recieve our values and errors to formvalidation.js and useform custom hooks
        formValues,
        formValidation.preferencesScreenTwo,
        props.preferencesScreenTwo,
        {}
      );
    return (
        <>
            <form className="step-form" onSubmit={handleSubmit} noValidate>
                <CustomRadioButtonField options={options1} name="isLGBTQ" ques={"Is it important for your therapist to be LGBTQ+ informed?"} error={errors.isLGBTQ} handleChange={handleChange} value={values.isLGBTQ}/> 
                <CustomRadioButtonField options={options2} name="interest" ques={"What kind of care are you interested in?"} error={errors.interest} handleChange={handleChange} value={values.interest} /> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="start-now text-right">
                            <button
                                type="submit"
                                className="btn btn-start-now"
                                // onClick={() => handleNext()}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = ({ matchTherapyFormData }) => ({
    ...matchTherapyFormData
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        __preferencesScreenTwo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrefrenceScreenTwo));