
import React from 'react'
import { useSelector } from "react-redux"
import TherapisitSignUpComponent from '../../components/TherapisitSignUp'
import { TopBar } from '../../components/common/Topbar/TopBar'
import { Header } from '../../components/sub_components/therapisitSignup/Header'
const TherapisitSignUp = () => {
    const openTopBar = useSelector(({ TopBarDisplay: { openTopBar } }) => openTopBar)
    return (
        <main>
            {/* <TopBar/> */}
            {openTopBar ? <TopBar /> : (
                <>
                    <TopBar />
                    <TherapisitSignUpComponent />
                </>
            )}
        </main>
    )
}

export default TherapisitSignUp;
