import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import CustomRadioButtonField from '../../../common/Buttons/CustomRadioButtonField';
import MultipleChoice from '../../../common/Buttons/MultipleChoice';
import useGetMatchForm from '../../../../utils/useGetMatchForm';
import formValidation from "../../../../utils/formValidation";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __preferencesScreenOne } from '../../../../store/actions/matchTherapy.action'

const PrefrenceScreenOne = (props) => {

    const options1 = ["Male", "Female", "Does not matter", "Others"]
    const options2 = ["24-29", "30-40", "41-50", "51 Above"]



    const formValues = () => {
        //call back function after the form submit

        console.log(values);
        props.setPage(2)
        props.setStepName("Prefrences")
        props.__preferencesScreenOne(values);
      };
    const { values, errors, handleChange, handleSubmit } = useGetMatchForm(
        //sends/recieve our values and errors to formvalidation.js and useform custom hooks
        formValues,
        formValidation.preferencesScreenOne,
        props.preferencesScreenOne,
        {}
      );
    return (
        <>
            <form className="step-form" onSubmit={handleSubmit} noValidate>
                <CustomRadioButtonField options={options1} name="therapistGender" ques={"What should your therapist identify as?"} errorother={errors.othertherapistGender} error={errors.therapistGender} handleChange={handleChange} value={values.therapistGender} valueOther={values.othertherapistGender}/> 
                <MultipleChoice options={options2} name="therapistAge" ques={"What is your age preference for your therapist?"} error={errors.therapistAge} handleChange={handleChange} value={values.therapistAge} /> 
                <div className="row">
                    <div className="col-md-12">
                        <div className="start-now text-right">
                        <button
                            type="submit"
                            className="btn btn-start-now"
                            // onClick={() => handleNext()}
                        >
                            Next
                        </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}


const mapStateToProps = ({ matchTherapyFormData }) => ({
    ...matchTherapyFormData
  });
  
  const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        __preferencesScreenOne
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrefrenceScreenOne));
