import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { CustomTooltip } from "../../common/Tooltip/CustomTooltip";
import {
  writeTherapistProfileString,
  showTherapistSpeciality,
} from "../../../utils/formatData";
import fetchTherapist from "../../../store/actions/fetchTherapist.action";
import { getRedirectionUrl } from "../../../utils/_helper";
import "./MeetTherapistSection.css";
import LoadingComp from "../../common/LoadingComp";

const MeetTherapistsSection = (props) => {
  const dispatch = useDispatch();
  //this componenets helps us in Therapy page in Meet Therpists  slider section
  const {
    loadedHomeTherapists,
    homeTherapists,
    loadedSimilarTherapist,
    therapist,
  } = useSelector(
    ({
      fetchTherapists: {
        loadedHomeTherapists,
        homeTherapists,
        loadedSimilarTherapist,
        therapist,
      },
    }) => ({
      loadedHomeTherapists,
      homeTherapists,
      loadedSimilarTherapist,
      therapist,
    })
  );
  const therapistlist = props.toggle
    ? therapist.similarTherapists
    : homeTherapists;
  const loaded = props.toggle ? loadedSimilarTherapist : loadedHomeTherapists;
  const settings = {
    //image slider carousel
    infinite: false,
    arrows: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <img className="slick-prev" src="/img/left_arrow.svg" />,
    nextArrow: <img className=" next slick-next" src="/img/right_arrow.svg" />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          infinite: false,
          rtl: true,
          //   ltl: true
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          dots: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <section id="our-therapiest" className="ot-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              {props.toggle ? (
                <div className="title-profile">Similar Therapist</div>
              ) : (
                <div className="ot-section-title text-center">
                  <h2>Meet Our Therapists</h2>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="overflow-hidden therapist-slick">
          {loaded ? (
            <div className="mb-5">
              <Slider {...settings} className="SlickAfterChange">
                {therapistlist.map(
                  (therapist) => (
                    <div className="ot-box text-center">
                      <div class="container">
                        <div class="row">
                          <div class="col-4"></div>
                          <div class="col-4">
                            <div className="ot-img-i-box">
                              <div className="ot-img-content">
                                <div className="image-div">
                                  <img
                                    src={
                                      therapist.user.profile ||
                                      "/img/card-img.svg"
                                    }
                                    className="meet-img"
                                    alt=""
                                    title=""
                                  />
                                </div>
                                <div className="therapist-info-block">
                                  <div className="ot-heading">
                                    <h4>
                                      {therapist.user.name.split(" ").length > 1
                                        ? therapist.user.name.split(" ")[0] +
                                          " " +
                                          therapist.user.name.split(" ")[1][0]
                                        : therapist.user.name}
                                    </h4>
                                  </div>
                                  <p className="ot-gender">
                                    ({therapist.pronouns})
                                  </p>
                                  <p className="ot-sub-heading">
                                    <b>
                                      {showTherapistSpeciality(
                                        therapist.specialisations
                                      )}
                                    </b>
                                    <p>
                                      Qualification :{" "}
                                      {writeTherapistProfileString(
                                        therapist.degrees
                                      )}
                                    </p>
                                  </p>
                                </div>
                                <div className="ot-book">
                                  <Link
                                    onClick={() =>
                                      window.open(
                                        getRedirectionUrl(
                                          process.env.REACT_APP_DASHBOARD_URL,
                                          `redirect=${window.encodeURIComponent(
                                            `therapy/book-therapist/${therapist.user.id}`
                                          )}`
                                        ),
                                        "_blank"
                                      )
                                    }
                                  >
                                    Book Now
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 ot-tooltip">
                            <a href="javascript:void(0)">
                              <CustomTooltip
                                text="Know more"
                                to={`/therapy/meet-our-experts/profile/${therapist.user.id}`}
                                htmlElement={`<img src="/img/know-more-tooltip.svg" alt="" title="" />`}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  //     <a href="javascript:void(0)" className="ot-tooltip">
                  //     <CustomTooltip
                  //         placement="top"
                  //         text="Know more"
                  //         to={`/therapy/meet-our-experts/profile/${therapist.user.id}`}
                  //         htmlElement={`<img src="/img/know-more-tooltip.svg" className="img-fluid" alt="" title="" />`}
                  //     />
                  // </a>
                )}
              </Slider>
            </div>
          ) : (
            <LoadingComp text="Loading..." />
          )}
          {props.toggle ? (
            ""
          ) : (
            <div className="row mt-5">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="text-center">
                  <Link
                    className="view-all-therapist"
                    to={getRedirectionUrl("/therapy/meet-our-experts")}
                  >
                    view all therapists
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default MeetTherapistsSection;
