import React from 'react';

const BlogFooter = () => {
    //this component provides information about Blog Footer section and comes under community/blog page
    const blogDataArray = [1,2,3,4]

    return (
        <>
            <section className="related-post-section">
                <div className="container">
                    <div className="row">
                        {
                            blogDataArray.map((item, i) => (
                                <div key={i} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 cursor-pointer">
                                    <div className="post-related-box">
                                        <div className="d-flex justify-content-between flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-nowrap">
                                            <div>
                                                <div className="sub-blog-heading">We’re here for you and your needs whether it</div>
                                                <div className="sub-blog-content">We’re here for you and your needs whether it is finding out or acting upon whether it is finding</div>
                                                <div className="post-meta">
                                                    <ul className="list-inline mb-0">
                                                        <li className="list-inline-item read-more">Jan 7 2021
                                                </li>
                                                        <li className="list-inline-item read-time">4 min read</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="related-post-img">
                                                    <img src="https://picsum.photos/seed/picsum/185/213" alt="" title="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    );
}

export default BlogFooter;
