import React, { Fragment } from 'react'
import {Link} from 'react-router-dom'

const BlogComment = () => {
    // this component is comment section in blog page
    return (
        <Fragment>
            <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <hr className="blog-hr"></hr>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="vent-wrapper-bg blog-vent-wrapper">

                            <div className="vent-out-feeling-wrapper blog-vent-out-feeling-wrapper position-absolute">
                                <div className="vent-out-heading">Comment</div>
                                <div className="blog-vent-out-wrapper-form">
                                    <div className="row form-group align-items-center">
                                        <label className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">Name:</label>
                                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" placeholder="Full Name"/>
                                        </div>
                                    </div>
                                    <div className="row form-group align-items-center">
                                        <label className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">Email:</label>
                                        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                                            <input type="text" className="form-control" placeholder="Enter Full Email Address.."/>
                                        </div>
                                    </div>
                                </div>
                                <div className="vent-out-textarea">
                                    <textarea placeholder="Your thoughts on the blog"></textarea>
                                </div>
                                <div className="d-flex justify-content-end align-items-center flex-wrap">
                                    <div className="submit-user">
                                        <button type="button" className="btn submit-user-btn"><i className="fas fa-paper-plane mr-1"></i> Comment</button>
                                    </div>
                                </div>
                            </div>


                                <div className="vent-inner-wrapper">
                                    <div className="user-reply-wrapper">
                                        <div className="d-flex">
                                            <div className="v-user-img"></div>
                                            <div className="vent-user-reply-content flex-grow-1">
                                                <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="vent-user-meta">
                                                        <div className="post-meta">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">5 mins ago</li>
                                                                <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mr-2">
                                                            <Link className="like liked">
                                                                <i className="fas fa-heart"></i>
                                                                <span className="like-count">17</span>
                                                            </Link>
                                                        </span> 
                                                        <span className="user-msg-reply"><Link>Reply</Link></span>
                                                    </div>
                                                </div>
                                                <div className="view-all-msg">
                                                    <Link>View all 3 replies</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-reply-wrapper">
                                        <div className="d-flex">
                                            <div className="v-user-img"></div>
                                            <div className="vent-user-reply-content flex-grow-1">
                                                <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="vent-user-meta">
                                                        <div className="post-meta">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">5 mins ago</li>
                                                                <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mr-2">
                                                            <Link className="like">
                                                                <i className="far fa-heart"></i>
                                                            </Link>
                                                        </span> 
                                                        <span className="user-msg-reply"><Link>Reply</Link></span>
                                                    </div>
                                                </div>
                                                <div className="view-all-msg">
                                                    <Link>View all 3 replies</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-reply-wrapper">
                                        <div className="d-flex">
                                            <div className="v-user-img"></div>
                                            <div className="vent-user-reply-content flex-grow-1">
                                                <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="vent-user-meta">
                                                        <div className="post-meta">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">5 mins ago</li>
                                                                <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mr-2">
                                                            <Link className="like">
                                                                <i className="far fa-heart"></i>
                                                            </Link>
                                                        </span> 
                                                        <span className="user-msg-reply"><Link>Reply</Link></span>
                                                    </div>
                                                </div>
                                                <div className="view-all-msg">
                                                    <Link>View all 3 replies</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-reply-wrapper">
                                        <div className="d-flex">
                                            <div className="v-user-img"></div>
                                            <div className="vent-user-reply-content flex-grow-1">
                                                <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="vent-user-meta">
                                                        <div className="post-meta">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item">5 mins ago</li>
                                                                <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="mr-2">
                                                            <Link className="like">
                                                                <i className="far fa-heart"></i>
                                                            </Link>
                                                        </span> 
                                                        <span className="user-msg-reply"><Link>Reply</Link></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="show-all-replies-wrapper">
                                        <div className="user-reply-wrapper">
                                            <div className="d-flex">
                                                <div className="v-user-img"></div>
                                                <div className="vent-user-reply-content flex-grow-1">
                                                    <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="vent-user-meta">
                                                            <div className="post-meta">
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item">5 mins ago</li>
                                                                    <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="mr-2">
                                                                <Link className="like">
                                                                    <i className="far fa-heart"></i>
                                                                </Link>
                                                            </span> 
                                                            <span className="user-msg-reply"><Link>Reply</Link></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-reply-wrapper">
                                            <div className="d-flex">
                                                <div className="v-user-img"></div>
                                                <div className="vent-user-reply-content flex-grow-1">
                                                    <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="vent-user-meta">
                                                            <div className="post-meta">
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item">5 mins ago</li>
                                                                    <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="mr-2">
                                                                <Link className="like">
                                                                    <i className="far fa-heart"></i>
                                                                </Link>
                                                            </span> 
                                                            <span className="user-msg-reply"><Link>Reply</Link></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="user-reply-wrapper">
                                            <div className="d-flex">
                                                <div className="v-user-img"></div>
                                                <div className="vent-user-reply-content flex-grow-1">
                                                    <p><span className="user">Anonymous</span> We’re here for you and your needs whether it is finding out or acting upon. Or sit amet, consectetur adipiscing elit, sed do eiusmod</p>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="vent-user-meta">
                                                            <div className="post-meta">
                                                                <ul className="list-inline mb-0">
                                                                    <li className="list-inline-item">5 mins ago</li>
                                                                    <li className="list-inline-item post-time">Jan 03, 2021</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span className="mr-2">
                                                                <Link className="like">
                                                                    <i className="far fa-heart"></i>
                                                                </Link>
                                                            </span> 
                                                            <span className="user-msg-reply"><Link>Reply</Link></span>
                                                        </div>
                                                    </div>
                                                    <div className="view-all-msg">
                                                    <Link>View Less</Link>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
        </Fragment>
    )
}

export default BlogComment
