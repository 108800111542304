import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CommonAction from '../../../store/actions/commonAction'

const FilterByTag = props => {

    const dispatch = useDispatch()

    const { filters, selectedFilter } = useSelector(state => state.boatData) || []

    useEffect(() => {
        dispatch(CommonAction.getFilter('SET_BOAT_FILTER', 'EnumBoatFilters'))
    }, [dispatch])

    const handleFilter = (filter) => {
        let newFilterArray = [...selectedFilter]
        if (newFilterArray.includes(filter)) {
            let index = newFilterArray.findIndex(el => el === filter)
            newFilterArray.splice(index, 1)
        } else {
            newFilterArray.push(filter)
        }
        dispatch({ type: 'SET_BOAT_SELECTED_FILTER', payload: newFilterArray })
    }

    return (
        <div className="filter-by mb-5">
            <div className="d-flex align-items-center flex-xl-nowrap flex-lg-nowrap flex-md-wrap flex-sm-wrap flex-wrap">
                <div className="filter-title">Filter By Tags:</div>
                {
                    filters.length > 0 && filters.map((tag, i) => {
                        return (
                            <div key={i} onClick={() => handleFilter(tag.key)} className={`filter filterTagCSS ${selectedFilter.includes(tag.key) ? 'selected' : ''}`}>{tag.value}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default FilterByTag
