import React, { useState, useEffect } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { useHistory, useLocation } from "react-router-dom"
import StepLeftBar from './StepLeftBar';
import TherapyService from "../../../api/services/TherapyService"
import { changeArrayKeys, makeExpKeys, expValues, sortLanguages } from "../../../utils/formatData"
const TherapisitSignUpForm = (props) => {

    const history = useHistory()
    // this form helps to load the step 1,2,3 in sequence using props based on form submit from each steps
    const [languages, changeLanguages] = useState([{ "label": "Loading..." }])
    const [degrees, changeDegrees] = useState([{ "label": "Loading..." }])
    const [specialisations, changeSpecialisations] = useState([{ "label": "Loading..." }])
    const [experienceValues, changeExperienceValues] = useState([])
    const [experienceEnums, changeExperienceEnums] = useState({})
    const location = useLocation();
    useEffect(() => {
        TherapyService.listAll().then((res) => {
            if (res) {
                changeLanguages(sortLanguages(res.listLanguages));
                changeDegrees(changeArrayKeys(res.listDegrees));
                changeSpecialisations(changeArrayKeys(res.listSpecialisations))
                changeExperienceEnums(makeExpKeys(res.enums))
                changeExperienceValues(expValues(res.enums))
            }
        });
    }, [])

    return (
        <>
            <section id="SignUpTherapist">
                <div className="signup-therapist-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col mb-0"><div className="back-button mb-5" onClick={() => history.push({
            pathname: "/",
            search: `${location.search}`
        })}>
                                <i className="fas fa-chevron-left"></i> <span> Back</span>
                            </div></div>
                        </div>
                        <div className="row no-gutters">

                            <StepLeftBar setStep={props.setStep} step={props.step} />
                            <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <div className="signup-thearpist-content">
                                    {
                                        props.step === 1 && <Step1 setStep={props.setStep} languagesList={languages} />
                                    }
                                    {
                                        props.step === 2 && <Step2 setStep={props.setStep} degreeList={degrees} specialisationsList={specialisations} experienceValues={experienceValues} />
                                    }
                                    {
                                        props.step === 3 && <Step3 setStep={props.setStep} experienceEnums={experienceEnums} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TherapisitSignUpForm;
