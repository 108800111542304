import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { getRedirectionUrl } from '../../../utils/_helper';

const SignUpModal = props => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    props.history.go(-1);
  };

  const [showSignInContent, setShowSignInContent] = useState(false);

  const handleSignUp = () => {
    window.open(
      getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL),
      '_top'
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={
          showSignInContent ? 'modal-signin-width' : 'modal-custom-width'
        }
        centered
      >
        <Modal.Body>
          <div className="sign-up-close-btn">
            <button onClick={handleClose}>
              <img src="img/close-btn.svg" alt="close" />
            </button>
          </div>
          <div className="user-sign-up">
            <div className="user-sign-up-img">
              <img
                src="https://cdn.mindpeers.co/site-icons/mind_illus.svg"
                alt="Mind_Illusion"
              />
            </div>
            <div className="user-sign-up-text">
              <h1>Looking to imporve your mental strength?</h1>
            </div>
            <div className="sing-up-btn">
              <button onClick={handleSignUp}>SIGN UP</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(SignUpModal);
