import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import default_dropdown_list from "../../../default_config/default_dropdown_list";
import "react-responsive-select/dist/react-responsive-select.css";
import formValidation from "../../../utils/formValidation";
import useStepSignUpForm from "../../../utils/useStepSignUpForm";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { __setStep1 } from '../../../store/actions/therapistSignUp.action'
import { format } from "../../../utils/_helper";
import MultiSelectDropDown from "../../common/SelectDropDown/MultiSelectDropDown";
import CustomSingleSelect from "../../common/SelectDropDown/CustomSingleSelect";
import TagsInput from "../../common/TagsInput"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/dayjs';
import { ThemeProvider } from "@material-ui/core";
import dayjs from 'dayjs';
import CommonService from "../../../api/services/CommonService"
import { uploadFileToS3 } from "../../../api/sendRequests"
const Step1 = (props) => {
  // this is therpaisisit signup page step 1 form component this component uses redux to handle data
  // this form has separate form-validation hook called useStepSignUpForm
  // we used custom single select dropdown 
  const placeholdercolor = "form-control span-error is-danger";
  const formControl = "form-control";
  const [radioBtn, setRadioBtn] = useState('')
  const [selectedFileType, setSelectedFileType] = useState('');
  const [selectedFileIndex, setSelectedFileIndex] = useState('');

  const formValues = async () => {
    //call back function after the form submit
    let resSignedUrl, resUploadS3;
    values['picture_S3'] = ''
    resSignedUrl = await CommonService.getSignedUrl("USERS_PROFILE", values.picture.name);
    // let formData = new FormData()
    // formData.append("key", values.picture)
    if (resSignedUrl)
      resUploadS3 = await uploadFileToS3(resSignedUrl.getSignedUrl.uri, values.picture, resSignedUrl.getSignedUrl.type)
    if (resUploadS3)
      values['picture_S3'] = resSignedUrl.getSignedUrl.url
    values['state'] = radioBtn
    props.__setStep1(values)
    props.setStep(2)
  };
  const removeFile = (type, index) => {
    // this function helps to delete the file from values
    setSelectedFileType(type);
    setSelectedFileIndex(index);
  };
  useEffect(() => {
    if (selectedFileType === 'picture') {
      values['picture'] = null;
      setSelectedFileType('')
    }
  }, [selectedFileType])
  const { values, errors, handleChange, handleSubmit } = useStepSignUpForm(
    //sends/recieve our values and errors to formvalidation.js and useform custom hooks
    formValues,
    formValidation.step1Form,
    props.step1,
    {}
  );

  // useEffect(() => {
  //   fetch('https://api.ipify.org?format=json').then(response => {
  //     return response.json();
  //   }).then((res) => {
  //     console.log("ip..", res.ip)
  //   }).catch((err) => console.error('Problem fetching my IP', err))
  // }, [])

  useEffect(() => {
    setRadioBtn(props.step1.state)
  }, [props.step1.state])

  const handleDOBChange = (date) => {
    if (date && date.$d) {
      let newDate = dayjs(date.$d).format('YYYY-MM-DD');
      handleChange(newDate, "dob");
    } else {
      return
    }
  }
  return (
    <>
      <div id="Step-1">
        <h3 className="step-heading">Step 1: Enter Personal Details</h3>
        <div className="step-form">
          <div className="form-group row align-items-center">
            <label
              htmlFor="name"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Name:
            </label>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
              <input
                type="text"
                onChange={handleChange}
                className={errors.fName ? placeholdercolor : formControl}
                name="fName"
                id=""
                placeholder={errors.fName || "First Name"}
                value={values.fName || ""}
                required
              />
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
              <input
                type="text"
                onChange={handleChange}
                className={errors.lName ? placeholdercolor : formControl}
                name="lName"
                id=""
                placeholder={errors.lName || "Last Name"}
                value={values.lName || ""}
                required
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label
              htmlFor="email"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Email:
            </label>
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
              <input
                type="email"
                className={errors.email ? placeholdercolor : formControl}
                onChange={handleChange}
                id=""
                name="email"
                value={values.email || ""}
                required
                id="email"
                placeholder={errors.email || "@email.com"}
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label
              htmlFor="password"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Password:
            </label>
            <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
              <input
                type="password"
                className={errors.password ? placeholdercolor : formControl}
                onChange={handleChange}
                id=""
                name="password"
                placeholder={
                  errors.password ||
                  "Atleast 6 characters, one symbol, one uppercase"
                }
                value={values.password || ""}
                required
                id="password"
              />
              {errors && errors.passwordFormat && (
                <span className="text-danger">{errors.passwordFormat}</span>
              )}
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Phone:
            </label>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-3 text-center">
              <select onChange={handleChange} name="mobileCode" className="form-control" value={values.mobileCode || "+91"} >
                {/* <option value="+91">+91</option>
                <option value="+65">+65</option> */}
                {
                  props.phonePrefixList?.map((prefix , i)=>(
                      <option key={i} value={prefix}>{prefix}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-10 col-sm-9 col-9">
              <input
                type="text"
                type="tel"
                onChange={handleChange}
                className={errors.number ? placeholdercolor : formControl}
                id=""
                name="number"
                placeholder={errors.number || "Max 10 Digits"}
                value={values.number || ""}
                required
                id="phone"
              />
            </div>
          </div>

          <div className="form-group row align-items-center">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              DOB:
            </label>
            <div className="col-xl-8 col-lg-8 col-md-10 col-sm-9 col-9">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  format="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  variant="inline"
                  disableFuture={true}
                  name="dob"
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  className="dob-input"
                  inputVariant="outlined"
                  value={values.dob}
                  onChange={(e) => handleDOBChange(e)}
                />
              </MuiPickersUtilsProvider>
              <br />
              {errors.dob &&
                <span className="text-danger">{errors.dob}</span>
              }
            </div>

          </div>

          <div className="form-group row">
            <label
              htmlFor="gender"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Gender:
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="select-gender">
                <ul className="list-inline mb-0">
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      id="male"
                      name="gender"
                      value="male"
                      checked={values.gender === "male"}
                      onChange={handleChange}
                    />
                    <label htmlFor="male">Male</label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      id="female"
                      name="gender"
                      value="female"
                      checked={values.gender === "female"}
                      onChange={handleChange}
                    />
                    <label htmlFor="female">Female</label>
                  </li>
                  <li className="list-inline-item">
                    <input
                      type="radio"
                      id="other"
                      name="gender"
                      value="others"
                      checked={values.gender === "others"}
                      onChange={handleChange}
                    />
                    <label htmlFor="other">Others</label>
                  </li>
                </ul>
              </div>
            </div>
            {values && values.gender === "others" && (
              <div className={"col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"}>
                <input
                  type="text"
                  placeholder="Other:"
                  onChange={handleChange}
                  className={
                    errors.othergender ? placeholdercolor : formControl
                  }
                  id=""
                  name="othergender"
                  placeholder={errors.othergender || "Others:"}
                  value={values.othergender || ""}
                  name="othergender"
                />
              </div>
            )}
          </div>
          <div className="form-group row">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Pronouns:
            </label>
            <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
              <select onChange={handleChange} name="pronoun" className="form-control" value={values.pronoun} >
                <option value="he/him">He/Him</option>
                <option value="she/her">She/Her</option>
                <option value="they/them">They/Them</option>
              </select>
            </div>
          </div>
          <div className="form-group row ">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Language:
            </label>
            <div className="col-xl-6 col-lg-6 pr-lg-1 col-md-12 col-sm-12 col-12">
              <MultiSelectDropDown
                value={values.language}
                options={props.languagesList}
                errorLanguage={errors.language}
                handleChange={handleChange}
                name={"language"}
                placeholder={"languages can you conduct therapy in"}
              />
              {errors.language && (
                <span className="text-danger">{errors.language}</span>
              )}
            </div>
            <div className="col-xl-4 col-lg-4 pl-lg-0 col-md-12 col-sm-12 col-12">
              {/* <input
                type="text"
                className="form-control"
                id="phone"
                value={values.otherlanguage || ""}
                name="otherlanguage"
                placeholder="Other:"
                onChange={handleChange}
              /> */}
              <TagsInput value={values.otherLanguages} onChange={(newTags) => handleChange(newTags, "otherLanguages")} placeholder={'Enter Other Langagues'} />
              <small className="text-muted">Press Enter to Add Languages</small>
            </div>
          </div>
          <div className="form-group row ">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Location:
            </label>
            <div className="col-xl-6 col-lg-6 pr-lg-1 col-md-12 col-sm-12 col-12">
              <CustomSingleSelect
                className="Custom-Ant-Select"
                list={default_dropdown_list.states}
                radioBtn={radioBtn}
                placeholder="State"
                setRadioBtn={setRadioBtn}
              />
              {Object.keys(errors).length > 0 && !radioBtn && (
                <span className="text-danger error">State is required*</span>
              )}
            </div>
            <div className="col-xl-4 col-lg-4 pl-lg-0 col-md-12 col-sm-12 col-12">
              <input
                type="text"
                type="text"
                onChange={handleChange}
                className={errors.city ? placeholdercolor : formControl}
                name="city"
                id=""
                placeholder={errors.city || "City"}
                value={values.city || ""}
                required
                id="phone"
              />
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="phone"
              className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 col-form-label"
            >
              Picture:
            </label>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="custom-input-box">
                <div className="d-flex align-items-center">
                  <div className="custom-picture-choose">
                    <input
                      type="file"
                      id="for-image"
                      name="picture"
                      accept=".jpeg ,.png"
                      onChange={handleChange}
                    />
                    <label htmlFor="for-image">Choose File</label>
                  </div>
                  <div>
                    <span className="file-exe">.jpg or.png</span>
                  </div>
                </div>
              </div>
              {values.picture && (
                <div className="d-flex">
                  <div>
                    <span className="badge badge-secondary m-0 px-2 mr-2">
                      <i className="fas fa-file mr-2"></i>
                      {format.fileName(values.picture.name)}
                      <i
                        className="fas fa-times ml-2"
                        onClick={() => removeFile("picture", 0)}
                      ></i>
                    </span>
                  </div>
                </div>
              )}
              {errors.picture && (
                <span className="text-danger error">{errors.picture}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="text-danger text-center">
                {errors.duplicateUser && (
                  <span className="text-danger">{errors.duplicateUser}</span>
                )}
              </div>
            </div>
          </div>



          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="move-btn">
                <div className="d-flex align-items-center justify-content-xl-end justify-content-lg-end justify-content-md-center justify-content-sm-center justify-content-center">
                  <a
                    href="javascript:void(0)"
                    onClick={() => props.history.goBack(-1)}
                    className="disabled-step"
                  >
                    Cancel
                  </a>
                  <button
                    type="submit"
                    className="btn btn-next"
                    onClick={handleSubmit}
                  // props.setStep(2)
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ therapistSignUp , commonState: {phonePrefixList } }) => ({
  ...therapistSignUp,
  phonePrefixList
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    __setStep1
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Step1));
