import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import FormPageModel from "../modalfolder/FormPageModal"
import { connect } from 'react-redux'
import { showTopBar, showOtherComponent } from "../../../store/actions/topBarDisplay.actions"
import { getRedirectionUrl } from '../../../utils/_helper'

const MobileTopBar = (props) => {
    const [modelValue, changemodelValue] = useState(false); // for constantly changing modelValue 
    const [showModel, setShowModel] = useState(false); // showModel to open FormPageModel

    const changeTopBarView = () => {
        if (props.TopBarDisplay.openTopBar === false)
            props.showTopBar()
        else
            props.showOtherComponent()
    }

    const clickTopBarBtn = () => {
        if (props.teamCare) {
            changemodelValue(!modelValue); setShowModel(true);
        } else {
            window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")
        }
    }
    return (
        <div className="mobile-navbar">
            <div className="upper-topbar">
                <Link to={getRedirectionUrl("/")} onClick={() => props.showOtherComponent()}>
                    <img src="/img/mplogo.png" className="mobile-logo" alt="" title="" />
                </Link>
                <div onClick={changeTopBarView}>
                    <img src="/img/menu.svg" className="menu-logo" alt="" title="" />
                </div>
            </div>
            {props.TopBarDisplay.openTopBar &&
                <div className="lower-topbar">
                    <button className={props.hideButton ? "d-none" : "btn btn-get-mobile-access"} type="submit" onClick={clickTopBarBtn}>
                        {props.teamCare ? "GET ACCESS" : <>
                            < img src="/img/topbarIcon.svg" />
                            {"   "} SIGN UP/ SIGN IN
                                </>}
                    </button>
                    <ul>
                        <li>
                            <Link to={getRedirectionUrl("/selfcare")} onClick={() => props.showOtherComponent()}>SelfCare</Link>
                        </li>
                        <li>
                            <Link to={getRedirectionUrl("/therapy")} onClick={() => props.showOtherComponent()}>Therapy</Link>
                        </li>
                        <li>
                            <Link to={getRedirectionUrl("/organisation")} onClick={() => props.showOtherComponent()}>TeamCare</Link>
                        </li>
                        <li>
                            <Link to={getRedirectionUrl("/community")} onClick={() => props.showOtherComponent()}>Boats</Link>
                        </li>
                        <li>
                            <Link to={getRedirectionUrl("/vent-out")} onClick={() => props.showOtherComponent()}>Vent Out</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" activeClassName="nav-link active" target="_blank" rel="noreferrer" href={getRedirectionUrl("https://blog.mindpeers.co","utm_source=website")}>Blogs</a>
                        </li>
                        <li>
                            <Link to={getRedirectionUrl("/therapist-sign-up")} onClick={() => props.showOtherComponent()}>
                            JOIN AS A MINDCOACH
                            </Link>
                        </li>
                    </ul>
                </div>}
            <FormPageModel modelValue={modelValue} showModel={showModel} clickedFrom="Get Access" />
        </div>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

const mapDispatchToProps = (dispatch) => ({
    showTopBar: () => dispatch(showTopBar()),
    showOtherComponent: () => dispatch(showOtherComponent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileTopBar);
