import defaultState from '../../default_config/default_intial_state'

const signupState = {
    step1 : defaultState.therapisitSignUpDefaultState.step1,
    step2 : defaultState.therapisitSignUpDefaultState.step2,
    step3 : defaultState.therapisitSignUpDefaultState.step3
}

export default (state = signupState , action) => {
  switch (action.type) {
    case 'FILL_STEP_ONE_DATA':
        return {
            ...state,
            step1 : action.payload
        }
    case 'FILL_STEP_TWO_DATA':
        return {
            ...state,
            step2 : action.payload
        }
    case 'FILL_STEP_THREE_DATA':
        return {
            ...state,
            step3 : action.payload
        }
    case 'RESET_DATA':
        return {
            ...state,
            ...action.payload
        }
    default:
      return {
          ...signupState
      };
  }
};