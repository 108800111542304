import React from 'react'
import WordSlider from "./WordSlider"
import SectionHeader from "../HeadingSection"
import WhyMindpeers from "./WhyMindpeers"
import { getRedirectionUrl } from '../../../utils/_helper'
import './SectionOne.css'
const SectionOne = () => {
    return (
        <section id="homepage">
            <SectionHeader
                heading={<WordSlider/>}
                firstClassName=" col-lg-7  col-12"
                secondClassName="col-lg-5 col-12 text-right"
                // description="Your On-Demand Behavioral Healthcare Platform"
                btnText="YES, I WANT TO BE STRONGER"
                imgPath="/img/main illus.svg"
                executeBtnClick={() => window.open(getRedirectionUrl(process.env.REACT_APP_APP_DOWNLOAD_URL), "_top")}
                home={true}
            />
            <WhyMindpeers />
        </section>
    )
}

export default SectionOne
