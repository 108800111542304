import { useState, useEffect } from 'react';
const TagsInput = (props) => {
    const [tags, setTags] = useState(props.value);
    const addTags = event => {
        if (event.key === "Enter" && event.target.value !== "") {
            setTags([...tags, event.target.value]);
            event.target.value = "";
        }
    }

    const removeTag = index => {
        setTags([...tags.filter(tag => tags.indexOf(tag) != index)])
    }

    useEffect(() => {
        props.onChange(tags)
    }, [tags])
    return (
        <>
            <div className="tags-input">
                <ul id="tags">
                    {tags.map((tag, index) => <li key={index} className="tag">
                        <span>{tag}</span>
                        <span onClick={() => removeTag(index)} className="cross">&times;</span>
                    </li>
                    )}
                </ul>
                <input className="form-control" onKeyUp={(event) => addTags(event)} type="text" placeholder={props.placeholder} />

            </div>
        </>
    )
}

export default TagsInput