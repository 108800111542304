export const ToastConstant = {
  SUBMITTED_SUCCESSFULLY: {
    time: 5000,
    severity: "success",
    message: "Submitted Successfully!"
  },
  BAD_TOKEN: {
    time: 5000,
    severity: "error",
    message: "Invalid Token!",
  },
  LOGIN_SUCCESS: {
    time: 3000,
    severity: "success",
    message: "Logged In Successfully!",
  },
  LINK_SEND_SUCCESS: {
    time: 3000,
    severity: "success",
    message: "Link Sent Successfully!",
  },
  TOKEN_EXPIRED: {
    time: 8000,
    severity: "error",
    message: "Token Expired!",
  },
  INVALID_CREDS: {
    time: 8000,
    severity: "error",
    message: "Invalid Credentials!",
  },
  PASSWORD_CHANGE_SUCCESS: {
    time: 5000,
    severity: "success",
    message: "Password Changed Successfully!",
  },
  FIRST_PAGE: {
    time: 5000,
    severity: "error",
    message: "First Page !!"
  },
  LAST_PAGE: {
    time: 5000,
    severity: "error",
    message: "Last Page !!"
  },
  EMPTY_VENT: {
    time: 5000,
    severity: "error",
    message: "Please Type Your Vent"
  },
  API_ERROR: {
    time: 5000,
    severity: "error",
    message: "Try Filling Form again"
  },
  ALREAY_EXIST: {
    time: 8000,
    severity: "error",
    message: "Account Already Exists!",
  },
  LOGOUT_MSG: {
    time: 3000,
    severity: "success",
    message: "Logout Successfully!",
  },
  NOT_FOUND: {
    time: 8000,
    severity: "error",
    message: "DATA NOT FOUND!",
  },
  INVALID_ID: {
    time: 8000,
    severity: "error",
    message: "Invalid Id!",
  },
  SLOT_UNAVAILABLE: {
    time: 6000,
    severity: "error",
    message: "Slot Not Available!",
  },
  AUTO_SAVE: {
    time: 3000,
    severity: "success",
    message: "Auto Saved!",
  },
  FEEDBACK_SUCCESS: {
    time: 5000,
    severity: "success",
    message: "Feedback Submitted Successfully!",
  },
  FEEDBACK_ALREADY_SUBMITED: {
    time: 5000,
    severity: "error",
    message: "Feedback Already Submitted!",
  },
  FEEDBACK_FAIL: {
    time: 5000,
    severity: "error",
    message: "Feedback Not Submitted!",
  },
  "BOAT_EXPIRED" : {
    time: 2000,
    severity: "error",
    message: "Boat not available!",
  },
};