import React from 'react'
import { TopBar } from '../../components/common/Topbar/TopBar'
import HomePage from '../../components/HomePage'
import { connect } from 'react-redux';

const Selfcare = ({ TopBarDisplay: { openTopBar } }) => {
    return (
        <main>
            {openTopBar ? <TopBar btnText={'TRY FOR FREE'} />
                : (<>
                    <TopBar />
                    <HomePage />
                </>
                )
            }
        </main>
    )
}

const mapStateToProps = ({ TopBarDisplay }) => ({
    TopBarDisplay
})

export default connect(mapStateToProps)(Selfcare);