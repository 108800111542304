import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const CommonModal = (props) => {
  //this modal works on Calculate Your Workplace Index section in organisation page

  //setshow helps to toggle hide/show modal based on form validation errors 
  const [show, setShow] = useState(false);

  //this closes the modal of popup
  const handleClose = () => setShow(false);

  useEffect(() => {
    //after getting form validation errors we can show modal popup because before validation errors we should not open the modal popup
    if (Object.keys(props.errorMessage).length === 0 && props.showModel) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [props.errorMessage, props.modelValue])

  // useEffect(() => {
  //   if (props.showModel) // to ensure when button is clicked model is opened
  //     setShow(true);
  // }, [props.modelValue]);


  return (
    <>
      {/* <button type="submit" className="btn btn-calculate-now">Calculate Now</button> */}

      <Modal
        show={show === true ? true : false}
        onHide={handleClose}
        dialogClassName={"ty-wrapper"}
      // backdrop="static"
      // keyboard={false}
      >
        <Modal.Body>
          {/* <div className="container">
            Thankyou for submitting. We'll get in touch with you soon.
            </div> */}
          <div className="get-access-form">
            <span className={`position-absolute right-0 top-0 cursor-pointer text-secondary'`} onClick={() => setShow(false)} ><i className="fa fa-times" ></i></span>

            <div className="thank-you-wrapper">
              <div className="d-flex align-items-center flex-xl-no-wrap flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap">
                <div className="ty-success-bg">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="green-check">
                        <i className="fas fa-check"></i>
                      </div>
                      <h3>Submitted successfully</h3>
                    </div>
                  </div>
                </div>
                <div className="ty-success-content flex-grow-1 text-xl-left text-lg-left text-md-left text-sm-center text-center">
                  <h2>Thank You!</h2>
                  <p>Access link with all information will be emailed to you shortly..</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CommonModal;
